import {useContext} from 'react';
import { CurrentFlow, CourseCode, CourseSections, PinnedClasses, IsMobile } from '../helpers/Contexts';
import { useAuthState } from "react-firebase-hooks/auth";
import { fireBaseAuth } from "../config/firebase";
import { useDarkMode } from "../helpers/DarkModeContext";

function sumBooleans(booleans) {
    return booleans.reduce((acc, currentValue) => acc + currentValue, 0);
}

function PinnedCourses() {
    const {pinnedClasses, setPinnedClasses} = useContext(PinnedClasses);
    const {courseSections, setCourseSections} = useContext(CourseSections);
    var {currentFlow, setCurrentFlow} = useContext(CurrentFlow);
    const {courseCode, setCourseCode} = useContext(CourseCode);
    const {isMobile, setIsMobile} = useContext(IsMobile);

    const [user, loading, error] = useAuthState(fireBaseAuth);
    const { isDarkMode, toggleDarkMode } = useDarkMode();

    var totalUnits = 0.0;
    var totalHours = 0.0;

    Object.keys(pinnedClasses).map((courseCode, index) => {
        // if (pinnedClasses[courseCode] === undefined) { return null; }
        if ((pinnedClasses[courseCode].disabled === undefined || !pinnedClasses[courseCode].disabled)
            && (pinnedClasses[courseCode]["show"] === undefined || (sumBooleans(pinnedClasses[courseCode]["show"]) !== 0)
        )) {
            totalUnits += pinnedClasses[courseCode].units;
            totalHours += pinnedClasses[courseCode].hours;
        }
    });

    return (
        <div className="px-3 pt-2 pb-2 flex justify-between mb-2 font-normal tracking-tight text-gray-900 dark:text-white">
            <h5>Pinned units: <span className=" font-bold">{totalUnits}</span></h5>
            <h5>Estimated weekly workload: <span className=" font-bold">{totalHours} hours</span></h5>
        </div>
    );
}

export default PinnedCourses;