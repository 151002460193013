import { APP_VERSION } from "../constants/Constants";
import {QUARTER_SHORTENED_LIST} from "../constants/Constants.js";

class CourseMeta {
    // constructor(quartersOffered, totalSections, title, year, description, code, number, units, unitsMin, unitsMax, gers, 
    //             objectID, subject, repeatable, grading, instructors, medianHours) {
    constructor(career, code, courseCode, department, description, finalExam, gers, grading, id, instructors, instructorIDs, maxTimesRepeated, 
                medianGrade, medianHours, meanHours, percentAs, qtrs, subject, title, unitsMin, unitsMax, pastReviews, syllabus, prereqs) {
        this.career = career;
        this.code = code;
        this.courseCode = courseCode;
        this.department = department;
        this.description = description;
        this.finalExam = finalExam;

        if (gers !== undefined) {
            this.gers = gers;
        } else {
            this.gers = [];
        }

        this.grading = grading;
        this.id = id;
        this.instructors = instructors;
        this.instructorIDs = instructorIDs;
        this.maxTimesRepeated = maxTimesRepeated;
        // this.meanHours = meanHours;
        this.medianGrade = medianGrade;
        this.medianHours = medianHours;
        this.meanHours = meanHours;
        this.percentAs = percentAs;
        this.qtrs = qtrs;
        this.subject = subject;
        this.title = title;
        this.unitsMin = unitsMin;
        this.unitsMax = unitsMax;

        if (unitsMin !== undefined && unitsMax !== undefined) {
            this.units = Array.from({ length: unitsMax - unitsMin + 1 }, (_, index) => index + unitsMin);
        } else {
            this.units = [];
        }

        if (qtrs !== undefined) {
            this.classOfferedThisYear = qtrs.includes(true);
        } else {
            qtrs = [false, false, false, false];
        }

        this.qtrDesc = this.classOfferedThisYear ? (
            QUARTER_SHORTENED_LIST
                .map((description, index) => qtrs[index] ? description : null)
                .filter(description => description !== null)
                .join(', ')
            ) 
            : '';

        this.pastReviews = pastReviews; 
        this.syllabus = syllabus;
        this.prereqs = prereqs;

        console.log(prereqs);

        // APP VERSION
        this.version = APP_VERSION;
    }

    // constructor which converts a JSON object into a CourseMeta object
    static fromJSON(json) {
        return new CourseMeta(
            json.career,
            json.code,
            json.courseCode,
            json.department,
            json.description,
            json.finalExam,
            json.gers,
            json.grading,
            json.id,
            json.instructors,
            json.instructorIDs,
            json.maxTimesRepeated,
            json.medianGrade,
            json.medianHours,
            json.meanHours,
            json.percentAs,
            json.qtrs,
            json.subject,
            json.title,
            json.unitsMin,
            json.unitsMax,
            json.pastReviews,
            json.syllabus,
            json.prereqs
        )
    }
}

export default CourseMeta;