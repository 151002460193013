import React, {useState, useEffect, useRef} from 'react'
import {DEPARTMENTS, DEPARTMENTS_TO_SUBJECTS} from './CatalogConstants'
import { Search } from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import {getFrequentlyUsed, saveFrequentLinks } from '../Cache/CatalogFrequentlyUsed'
import { useAuthState } from "react-firebase-hooks/auth";
import { fireBaseAuth } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import {db} from "../config/firebase";

function CatalogDepartments() {
    const [catalogSearchIsOpen, setCatalogSearchIsOpen] = useState(false);
    var [catalogsearchItems, setCatalogSearchItems] = useState([]);
    const catalogSearchBarRef = useRef(null);

    const preferencesKey = `CatalogDepartmentPrefs`;
    const [collapsedItems, setCollapsedItems] = useState(fetchPreferences());

    const frequentLinks = getFrequentlyUsed();

    const [recommendations, setRecommendations] = useState([]);
    const [user, loading, error] = useAuthState(fireBaseAuth);
    
    useEffect(() => {
        // do lookup

        const sectionDocRef = doc(db, "Recommendations", user.uid);
        getDoc(sectionDocRef)
            .then(docSnap => {
                if (!docSnap.exists()) {
                    setRecommendations(null);
                    return
                }
                const data = docSnap.data();

                if (data === undefined || data["courses"] === undefined || data["courses"].length === 0) {
                    setRecommendations(null);
                } else {
                    setRecommendations(data["courses"]);
                }
            })
            .catch(error => {
                console.log(error);
            });


    }, []);


    function fetchPreferences() {
        const prefString = localStorage.getItem(preferencesKey);
        if (prefString) {
            let catalog;
        
            try {
                catalog = JSON.parse(prefString);
            } catch (error) {
                return {};
            }

            return catalog;
        }
        return {};
    }

    useEffect(() => {
        const value = JSON.stringify(collapsedItems);
        localStorage.setItem(preferencesKey, value);
    }, [collapsedItems]);


    const catalogHandleChange = (event) => {
        const searchTerm = event.target.value;

        if (searchTerm === "") {
            setCatalogSearchItems([]);
            setCatalogSearchIsOpen(false);
            return;
        } else {
            if (!catalogSearchIsOpen) {
                setCatalogSearchIsOpen(true);
            }

            // const queries = processSearch(searchTerm);
            // Promise.all(queries.map(q => getDocsFromQuery(q)))
            //     .then(resultsArrays => {
            //         const combinedResults = [].concat(...resultsArrays);
            //         setSearchItems(combinedResults);
            //     })
            //     .catch(error => {
            //         console.error("Error getting documents: ", error);
            //     });
        }
    };

    const catalogHandleKeyDown = (event) => {
        if (event.key === 'Enter') {
            var inputCode = event.target.value;

            if (inputCode !== "") {
                setCatalogSearchIsOpen(false);

                // add parameter query=inputCode
                window.location.href = "./catalog?query=" + encodeURIComponent(JSON.stringify(inputCode));
            }
        }
    };

    useEffect(() => {
        // Function to handle the click event
        function handleClickOutside(event) {
            const searchBar = document.getElementById('catalog-search-navbar');

            if (catalogSearchBarRef.current && !catalogSearchBarRef.current.contains(event.target) && event.target !== searchBar) {
                setCatalogSearchIsOpen(false);
            }
        }

        // Attach the click event listener
        document.addEventListener("click", handleClickOutside);
        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [catalogSearchBarRef]);

    const departmentRow = (department, subjects, collapsed) => {
        return (
        <div className={`w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 cursor-default transition-all transition-duration-300 ${collapsed ? 'collapsed' : ''}`}>
            <div className="px-5 py-3.5">
                <div className="flex justify-between items-center"
                        onClick={() => {
                        setCollapsedItems({ ...collapsedItems, [department]: !collapsed });
                        }}>
                    <h4 className="text-left text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                        {department}
                    </h4>
                    <button
                        className='text-gray-800 dark:text-slate-400 text-2xl h-full flex items-center'
                    >
                        {collapsed ? (
                        <svg
                            className="w-6 h-6 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 8"
                        >
                            <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                            />
                        </svg>
                        ) : (
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 8"
                        >
                            <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
                            />
                        </svg>
                        )}
                    </button>
                </div>

                {!collapsed && (
                    <div className="grid grid-cols-3 gap-4 px-4 pt-3">
                    {
                        subjects.map((subjectInformation) => {
                            const subjectCode = subjectInformation[0];
                            const subject = subjectInformation[1];

                            return (
                            <Link to={`/catalog?subject=${encodeURIComponent(JSON.stringify(subjectCode))}`} 
                                href={`./catalog?subject=${encodeURIComponent(JSON.stringify(subjectCode))}`} 
                                onClick={() => { saveFrequentLinks(subject, subjectCode) }}
                                className="text-left w-fit font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                {subject} ({subjectCode})
                            </Link>
                            );
                        })
                    }
                    </div>
                )}
            </div>
        </div>
        );
    }

  const [numRecs, setNumRecs] = useState(4);

  const recomendationRow = (collapsed) => {
    return (
        <div className={`w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 cursor-default transition-all transition-duration-300`}>
            <div className="px-5 py-3.5">
                <div className="flex justify-between items-center"
                        onClick={() => {
                        setCollapsedItems({ ...collapsedItems, ["Recommended Classes"]: !collapsed });
                        }}>
                    <h4 className="text-left text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                        {"Recommended Classes"}
                    </h4>

                    <button
                        className='text-gray-800 dark:text-slate-400 text-2xl h-full flex items-center'
                    >
                        {!collapsedItems["Recommended Classes"] ? (
                        <svg
                            className="w-6 h-6 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 8"
                        >
                            <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                            />
                        </svg>
                        ) : (
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 8"
                        >
                            <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
                            />
                        </svg>
                        )}
                    </button>
                </div>
                
                {
                    !collapsed ?
                        <div>
                            {
                                recommendations === null ?
                                    <div className='mt-2'>
                                        You did not have enough classes pinned at the time our recommendation system was last updated. Please check back later.
                                    </div>
                                : 
                                <div className="grid grid-cols-5 gap-4 px-4 pt-3">
                                    {
                                    recommendations.slice(0, numRecs).map((item) => {
                                        const courseCode = item["code"];
                                        const courseTitle = item["title"];
                                        const description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dolor magna eget est lorem ipsum dolor sit amet consectetur. Magna fringilla urna porttitor rhoncus. Elementum nibh tellus molestie nunc non blandit. Vitae purus faucibus ornare suspendisse sed. Enim diam vulputate ut pharetra sit amet aliquam id. Consequat ac felis donec et odio. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Amet consectetur adipiscing elit duis. Netus et malesuada fames ac turpis egestas sed tempus urna.";

                                        return (
                                                <div>
                                                <Link 
                                                    to={`/catalog?query=${encodeURIComponent(JSON.stringify(courseCode))}`} 
                                                    href={`./catalog?query=${encodeURIComponent(JSON.stringify(courseCode))}`}
                                                    className=" ">
                                                        <div className="flex justify-center items-center bg-purple-200/40 transition-all ease-in duration-300 hover:bg-purple-200 dark:bg-gray-900/75 hover:dark:bg-gray-700 rounded-lg w-full h-full p-2">
                                                            <p className=" self-center text-center w-fit text-purple-600 dark:text-purple-500">{courseTitle} ({courseCode})</p>
                                                            {/* <p className="text-left w-fit font-medium">{description}</p> */}
                                                        </div>
                                                </Link>
                                                </div>
                                        );
                                    })
                                    }
                                    {
                                        (numRecs < recommendations.length) && 
                                        <div className="w-full h-full">
                                            <button className="relative p-0.5 w-full h-full inline-flex items-center justify-center overflow-hidden text-sm font-medium text-gray-900 rounded-lg group
                                            bg-gradient-to-r from-[#1499f7] via-[#8A2BE2] to-[#FF69B4]
                                            hover:text-white dark:text-white">
                                                <span className="w-full h-full justify-center group flex items-center text-lg relative px-5 py-2.5 space-x-1 transition-all ease-in duration-300 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
                                                    onClick={
                                                        () => {
                                                            if (numRecs + 5 === recommendations.length - 1) {
                                                                setNumRecs(recommendations.length);
                                                            } else {
                                                                setNumRecs(numRecs + 5);
                                                            }
                                                        }
                                                    }>
                                                    <svg className="w-6 h-6" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"></path>
                                                    </svg>
                                                    <span>
                                                        Generate
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                            
                        </div>
                    :   null
                }
                
                
            </div>
        </div>
    )
  }


  return (
    <div className='w-4/5 space-y-4'>
        <div className='flex'></div>
        <div className="relative hidden md:block">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
                <span className="sr-only">Search icon</span>
            </div>
            <div ref={catalogSearchBarRef} >
                <input type="text" id="catalog-search-navbar" data-dropdown-toggle="catalogDropdownSearch" 
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 
                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                placeholder="Search..." onChange={catalogHandleChange} onKeyDown={catalogHandleKeyDown} />

            {/* { catalogSearchIsOpen ?
                <div id="dropdownSearch"
                className={`absolute w-full mt-0 z-10 bg-white divide-gray-100 rounded-md shadow w-44 dark:bg-zinc-800`}>
                    {catalogsearchItems.map((item) => (
                        <div className="pl-3 py-3 text-sm text-left text-gray-900 dark:text-white cursor-pointer hover:dark:bg-zinc-600 rounded-md"
                        onClick={
                            () => {
                                // setSearchIsOpen(false);
                                // if (window.location.href.includes("/share/")) {
                                //     window.location.href = "../" + item.code;
                                // } else {
                                //     window.location.href = "./" + item.code;
                                // }
                            }
                        }>
                            <div className="truncate line-clamp-1 block">
                                <span className="font-semibold">{item.code}: </span>
                                {item.title}
                            </div>
                        </div>
                    ))}
                </div> : null
            } */}
            
            </div>
        </div>

        {
            // Recommendations
            (
                recomendationRow(collapsedItems["Recommended Classes"] === undefined ? false : collapsedItems["Recommended Classes"])
            )
        }
        

        {
            // Frequently Used Links
            frequentLinks && frequentLinks.length > 0 && (
                departmentRow("Recently Viewed", frequentLinks, collapsedItems["Recently Viewed"] === undefined ? false : collapsedItems["Recently Viewed"])
            )
        }

        {
            DEPARTMENTS.map((department) => {
                const subjects = DEPARTMENTS_TO_SUBJECTS[department];
                const collapsed = collapsedItems[department] === undefined ? false : collapsedItems[department];
                return departmentRow(department, subjects, collapsed);
            })
        }
    </div>
  );
}

export default CatalogDepartments