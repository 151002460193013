// import { APP_VERSION } from "../constants/Constants";
import { REVIEW_VERSION } from "../constants/Constants";

class Reviews {
    constructor(reviews) {
        this.reviews = reviews

        // APP VERSION
        this.version = REVIEW_VERSION;
    }


    static fromJSON(json) {
        return new Reviews(
            json.reviews
        );
    }
}

export default Reviews;