/* 
clear
node completion.js
*/

import { sub } from "date-fns"

// codesTaken = {
//     "CS106B": 5,
//     "CS103": 5,
//     "PHYSICS41" : 4,
//     "MATH51": 5,
//     "CS107": 5,
//     "CS109": 5,
//     "CS161": 5,
//     "CS221": 5,
//     "CS224N": 5,
//     "STATS200": 5,
//     "CS195": 4,
//     "MATH21": 5,
//     "CS129": 4,
//     "CS231N": 4
// }

// Accessing command line arguments
// const args = process.argv;

// if (args.length < 3) {
//     console.log('Please enter a program name');
//     process.exit(1);
// }

// const programName = args[2]; // first argument

// const fs = require('fs');
// const { json } = require('stream/consumers');

// function readData(filePath, callback) {
//     fs.readFile(filePath, 'utf8', (err, data) => {
//         if (err) {
//             callback(err, null);
//             return;
//         }

//         try {
//             const jsonData = JSON.parse(data);
//             callback(null, jsonData);
//         } catch (error) {
//             callback(error, null);
//         }
//     });
// }

// Path to the JSON file
// const filePath = '../bulletinScraping/working/reqsProcessed.json';
// const filePath = `../bulletinScraping/output/final/reqs-${programName}.json`;

// Call the readData function
// readData(filePath, (err, jsonData) => {
//     if (err) {
//         console.error('Error reading or parsing JSON data:', err);
//         return;
//     }
//     // console.log('JSON data:', jsonData);

//     // checkCore(jsonData["Core Program Requirements"]);
//     checkAll(jsonData);
// });

const ignoreRecursiveKeys = ["notes"]

function checkLogic(classItem, codesTaken) {
  const { codes, logic } = classItem
  var minimumCount = 0
  var codesCount = 0

  if (logic === "and") {
    minimumCount = codes.length
  } else if (logic === "or") {
    minimumCount = 1
  }

  const codesUsed = []

  for (const code of codes) {
    if (minimumCount <= codesCount) break

    if (code in codesTaken) {
      codesUsed.push(code)
      codesCount++
    }
  }

  // codesCount = Math.min(codesCount, minimumCount);
  return [codesCount, minimumCount, codesUsed]
}

function getMaxUnitsFrom(codesUsed, codesTaken) {
  // Initialize maxUnits to a very small number
  let maxUnits = 0
  let codeUsed = null

  // Iterate through the codesUsed
  for (const code of codesUsed) {
    // Lookup the number of units for the current code
    // console.log(code)
    const unitsForCode = codesTaken[code]

    // If unitsForCode is not undefined and greater than maxUnits, update maxUnits
    if (unitsForCode !== undefined && unitsForCode > maxUnits) {
      maxUnits = unitsForCode
      codeUsed = code
    }
  }

  // Return the maximum number of units found
  return [maxUnits, codeUsed]
}

// function implementCondition(subRule, condition) {
// passing in default null values as the subRules and rules are defined differently
function implementCondition(
  subRule,
  codesTaken,
  condition = null,
  units = null,
  restriction = null,
) {
  const classes = subRule["classes"]
  if (condition === null) condition = subRule["condition"]

  var satisfied = 0

  if (condition === "all") {
    restriction = classes.length
  } else if (condition === "any") {
    restriction = 1
  } else if (condition === "min") {
    // console.log(restriction)
    if (restriction === null) restriction = subRule["restriction"]
  } else if (condition === "minUnits") {
    // Units Requirement, Special Case
    const conditionData = {}
    // const unitCodesUsed = [];
    const minUnits = units
    // var totalUnits = 0;
    var unitCodesUsedToUnits = {}

    if (!subRule.hasOwnProperty("classesGeneral")) {
      for (let i = 0; i < classes.length; i++) {
        const [codesCount, minimumCount, codesUsed] = checkLogic(classes[i], codesTaken)

        const [maxUnitsForCodes, codeUsed] = getMaxUnitsFrom(codesUsed, codesTaken)
        // totalUnits += maxUnitsForCodes;
        if (codeUsed !== null) {
          // unitCodesUsed.push(codeUsed);
          unitCodesUsedToUnits[codeUsed] = maxUnitsForCodes
        }
      }
    } else {
      // general case
      const classesGeneral = subRule["classesGeneral"]
      const { prefix, minimum, maximum } = classesGeneral

      for (const codeTaken in codesTaken) {
        const codeTakenAlphaRemoved = codeTaken.replace(/[a-zA-Z]+$/, "")
        if (
          codeTakenAlphaRemoved.startsWith(prefix) &&
          (minimum === undefined ||
            (codeTakenAlphaRemoved.length >= minimum.length && codeTakenAlphaRemoved >= minimum)) && // prevent math19 from counting, for 100 series
          (maximum === undefined ||
            (codeTakenAlphaRemoved.length >= maximum.length && codeTakenAlphaRemoved >= maximum))
        ) {
          unitCodesUsedToUnits[codeTaken] = codesTaken[codeTaken]
        }
      }
    }

    conditionData["satisfiedUnits"] = Object.values(unitCodesUsedToUnits).reduce(
      (acc, currentValue) => acc + currentValue,
      0,
    ) //totalUnits;
    conditionData["minUnits"] = minUnits
    conditionData["codesUsed"] = Object.keys(unitCodesUsedToUnits)
    conditionData["completed"] = conditionData["satisfiedUnits"] >= minUnits

    return conditionData
  } else {
    restriction = 0
  }

  const conditionData = {
    items: [],
  }
  var codesUsedToUnits = {}

  for (let i = 0; i < classes.length; i++) {
    if (satisfied >= restriction) break
    const [codesCount, minimumCount, codesUsed] = checkLogic(classes[i], codesTaken)

    if (codesCount >= minimumCount) {
      satisfied++
    }

    if (codesCount > 0) {
      for (const code of codesUsed) {
        codesUsedToUnits[code] = codesTaken[code]
      }

      conditionData["items"].push({
        codesCount: codesCount,
        minCount: minimumCount,
        codesUsed: codesUsed,
        completed: codesCount >= minimumCount,
      })
    }
  }

  if (subRule.hasOwnProperty("classesGeneral")) {
    const classesGeneral = subRule["classesGeneral"]
    const { prefix, minimum, maximum } = classesGeneral

    for (const codeTaken in codesTaken) {
      const codeTakenAlphaRemoved = codeTaken.replace(/[a-zA-Z]+$/, "")
      if (
        codeTakenAlphaRemoved.startsWith(prefix) &&
        (minimum === undefined ||
          (codeTakenAlphaRemoved.length >= minimum.length && codeTakenAlphaRemoved >= minimum)) && // prevent math19 from counting, for 100 series
        (maximum === undefined ||
          (codeTakenAlphaRemoved.length >= maximum.length && codeTakenAlphaRemoved >= maximum))
      ) {
        satisfied++
        codesUsedToUnits[codeTaken] = codesTaken[codeTaken]
      }
    }
  }

  conditionData["satisfied"] = satisfied
  conditionData["restriction"] = restriction
  conditionData["codesUsed"] = Object.keys(codesUsedToUnits)
  conditionData["completed"] = satisfied >= restriction

  return conditionData

  // return [satisfied, restriction];
}

function handleSubRules(subRules, codesTaken) {
  const subRuleReqs = {}

  // loop over keys in dictionary
  for (let key in subRules) {
    const subRule = subRules[key]

    if ("classes" in subRule) {
      // const conditionData = implementCondition(subRule, codesTaken);
      const conditionData = implementCondition(
        subRule,
        codesTaken,
        "condition" in subRule ? subRule["condition"] : null,
        "units" in subRule ? subRule["units"] : null,
        "restriction" in subRule ? subRule["restriction"] : null,
      )
      subRuleReqs[key] = conditionData
    } else if ("subRules" in subRule) {
      const subSubRules = subRule["subRules"]
      var subRulesRec = handleSubRules(subSubRules, codesTaken)
      subRulesRec["recursive"] = true
      subRuleReqs[key] = subRulesRec
    }

    // if ("subRules" in subRule) {
    //     const subSubRules = subRule["subRules"];
    //     // console.log(subSubRules);
    //     handleSubRules(subSubRules);
    // }
    // break;
  }

  return subRuleReqs
}

function checkSection(sectionData, codesTaken) {
  const coreReqs = {}
  var sectionCodes = {}

  for (const key in sectionData) {
    if (ignoreRecursiveKeys.includes(key)) continue // stop recursing if at section level

    const course = sectionData[key]
    const condition = course["condition"]
    // var minCourseCount = 0; // TODO
    var codesUsedTotal = {}

    if (course.hasOwnProperty("subRules")) {
      const subRules = course["subRules"]
      const subRuleReqs = handleSubRules(subRules, codesTaken)
      const suffix = "rules" in course ? "-sub" : ""
      coreReqs[key + suffix] = subRuleReqs

      for (const subKey in subRuleReqs) {
        const subRulesCondition = subRules[subKey]["condition"]
        const subRule = subRuleReqs[subKey]
        var previousCodeCountBest = 0

        // console.log(subRuleReqs[key]["items"])
        if (subRule.hasOwnProperty("items")) {
          for (const item of subRule["items"]) {
            for (const code of item["codesUsed"]) {
              codesUsedTotal[code] = codesTaken[code]
            }
          }
        } else if (subRuleReqs[subKey].hasOwnProperty("recursive")) {
          for (const ruleKey in subRule) {
            if (ruleKey === "recursive") continue
            const ruleValue = subRule[ruleKey]

            if (
              ruleValue["completed"] ||
              (ruleValue["codesUsed"] !== undefined &&
                ruleValue["codesUsed"].length > previousCodeCountBest)
            ) {
              previousCodeCountBest = ruleValue["codesUsed"].length

              coreReqs[key + suffix][subKey]["codesUsed"] = ruleValue["codesUsed"]
              coreReqs[key + suffix][subKey]["completed"] = ruleValue["completed"]
              coreReqs[key + suffix][subKey]["satisfied"] = ruleValue["satisfied"]
              coreReqs[key + suffix][subKey]["restriction"] = ruleValue["restriction"]
              coreReqs[key + suffix][subKey]["satisfiedCount"] = ruleValue["satisfiedCount"]
              coreReqs[key + suffix][subKey]["minCount"] = ruleValue["minCount"]
              coreReqs[key + suffix][subKey]["items"] = ruleValue["items"]

              if (ruleValue["completed"]) break
            }
          }
        }
      }

      if (condition === "minUnits") {
        // handles suffix for subRules satisfied
        coreReqs[key]["suffix"] = "items"
      }
    }

    if (course.hasOwnProperty("rules")) {
      const rules = course["rules"]

      const conditionData = implementCondition(
        rules,
        codesTaken,
        condition,
        "units" in course ? course["units"] : null,
        "restriction" in course ? course["restriction"] : null,
      )
      coreReqs[key] = conditionData
      // codesUsedTotal = codesUsedTotal.concat(conditionData["codesUsed"]);

      if (conditionData["codesUsed"] === undefined) continue
      for (const code of conditionData["codesUsed"]) {
        codesUsedTotal[code] = codesTaken[code]
      }
    }

    // edge case for ME-MS
    if (!coreReqs.hasOwnProperty(key)) {
        coreReqs[key] = {}
    }

    if (condition === "minUnits") {
      const codesTotal = Object.values(codesUsedTotal).reduce(
        (acc, currentValue) => acc + currentValue,
        0,
      )
      coreReqs[key]["satisfiedUnits"] = codesTotal
      coreReqs[key]["minUnits"] = course["units"]
      coreReqs[key]["completed"] = codesTotal >= coreReqs[key]["minUnits"]
    } else if (condition === "count" || condition === "any") {
      var completedCount = 0

      for (const itemKey in coreReqs[key]) {
        const item = coreReqs[key][itemKey]
        if (item["completed"] !== undefined) {
          completedCount += item["completed"]
        }
      }

      if (coreReqs[key].hasOwnProperty("codesUsed") && coreReqs[key]["completed"]) {
        // if has rules, add one to completedCount
        completedCount += 1
      }

      coreReqs[key]["completedCount"] = completedCount
      if (condition === "count") {
        coreReqs[key]["minCount"] = course["number"]
      } else if (condition === "any") {
        coreReqs[key]["minCount"] = 1
      }
      coreReqs[key]["completed"] = completedCount >= coreReqs[key]["minCount"]
    }

    // merge the codesUsedTotal with the sectionCodes
    sectionCodes = { ...sectionCodes, ...codesUsedTotal }

    // break;
  }

  if (sectionData.hasOwnProperty("condition")) {
    // if the section has a condition
    if (sectionData["condition"] === "minUnits") {
      const sectionUnitTotal = Object.values(sectionCodes).reduce(
        (acc, currentValue) => acc + currentValue,
        0,
      )
      coreReqs["minUnits"] = sectionData["units"]
      coreReqs["satisfiedUnits"] = sectionUnitTotal
      coreReqs["completed"] = sectionUnitTotal >= sectionData["units"]
    }
  }

  return coreReqs
}

function customSorting(dict) {
  // Convert object to array of key-value pairs
  const entries = Object.entries(dict)

  // Sort the array
  entries.sort(([key1], [key2]) => {
    if (key1.startsWith("TRACK:") && !key2.startsWith("TRACK:")) {
      return 1 // key1 is TRACK:, so it should be after key2
    } else if (!key1.startsWith("TRACK:") && key2.startsWith("TRACK:")) {
      return -1 // key2 is TRACK:, so it should be after key1
    } else {
      return 0 // maintain the relative order
    }
  })

  // Convert the sorted array back to an object
  return Object.fromEntries(entries)
}

function checkAll(programJSON, codesTaken) {
  //}, subPlan="Artificial Intelligence") {
  const reqs = {}

  if (programJSON === null || programJSON === undefined) return [reqs, []]

  const sectionKeys = Object.keys(programJSON)
  var subplans = []

  for (var sectionKey of sectionKeys) {
    var sectionData = programJSON[sectionKey]

    if (sectionKey === "Subplans") {
      subplans = Object.keys(sectionData)

      // if (sectionData.hasOwnProperty(subPlan))  {
      for (const subPlan of subplans) {
        const trackData = sectionData[subPlan]
        sectionKey = "TRACK: " + subPlan

        const sectionReqs = checkSection(trackData, codesTaken)
        reqs[sectionKey] = sectionReqs
      }
      // }
    } else {
      const sectionReqs = checkSection(sectionData, codesTaken)
      reqs[sectionKey] = sectionReqs
    }
  }

  // console.log(reqs);
  // sort subplans

  return [customSorting(reqs), subplans.sort()]

  // dump to JSON
  // const data = JSON.stringify(reqs, null, 4);
  // fs.writeFileSync('coreReqs.json', data);
  // console.log("------------------")
  // console.log(coreData)
}

export { checkAll }
