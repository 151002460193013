
const PLANNER_META_KEY = `Planner_Meta`;
const PLANNER_TRACKS_KEY = `Planner_Tracks`;

function savePrograms(reqPrograms) {
    // console.log(reqPrograms)
    if (reqPrograms === null || reqPrograms === undefined) return;

    const data = {
        // version: PLANNER_VERSION,
        programs: reqPrograms
    }
    const value = JSON.stringify(data);
    localStorage.setItem(PLANNER_META_KEY, value);

    // upload to firebase
    // const planRef = doc(db, 'Planned', userID, `${currentFlow[0]}-${currentFlow[1]}`, courseCode);
    
    // setDoc(planRef, { 
    // });
}

function getPrograms() {
    const dataString = localStorage.getItem(PLANNER_META_KEY);

    if (dataString !== null && dataString !== undefined && dataString !== "" && dataString !== "undefined") {
        let data;
        
        try {
            data = JSON.parse(dataString);
        } catch (error) {
            return [];
        }

        return data.programs;
    }
    return [];
}


function saveTracks(tracksData) {
    if (tracksData === null || tracksData === undefined) return;

    const data = {
        // version: PLANNER_VERSION,
        tracks: tracksData
    }
    const value = JSON.stringify(data);
    localStorage.setItem(PLANNER_TRACKS_KEY, value);

    // upload to firebase
    // const planRef = doc(db, 'Planned', userID, `${currentFlow[0]}-${currentFlow[1]}`, courseCode);
    
    // setDoc(planRef, { 
    // });
}

function getTracks() {
    const dataString = localStorage.getItem(PLANNER_TRACKS_KEY);

    if (dataString !== null && dataString !== undefined && dataString !== "" && dataString !== "undefined") {
        let data;
        
        try {
            data = JSON.parse(dataString);
        } catch (error) {
            return {};
        }

        return data.tracks;
    }
    return {};
}

export { savePrograms, getPrograms, saveTracks, getTracks };