import React from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { colorToRgb } from '../Colors.js'
import { Opacity } from '@mui/icons-material';

function ReviewsSentimentBar(props) {
    const { sentiment } = props;
    if (!sentiment) return null;
    if (sentiment.length !== 3) return null;

    const total = sentiment.reduce((acc, cur) => acc + cur, 0);

    const normalised = sentiment.map((val) => Math.round(val/total * 100))

    const SHADE = 400;
    const OPACITY = 0.2;

    return (
        <div className='pr-2 w-full items-center'>
            <Tooltip id="reviewsSentimentTip" className='z-10'/>
            <div class="flex flex-row w-full items-center rounded-full">

                {
                    normalised[0] === 0 ? null :
                    <div class="" style={{width: `${normalised[0]}%`}} 
                        data-tooltip-id="reviewsSentimentTip" data-tooltip-content={`${sentiment[0]} Negative (${normalised[0]}%)`}>
                        {/* <div className={`w-full text-center text-red-600 dark:text-red-500 pt-1 ${normalised[0] < 4 && 'opacity-0'}`}>{`${normalised[0]}%`}</div> */}
                        <div className='p-0.5 text-sm text-center rounded-l-full' style={{
                            "backgroundColor": colorToRgb("rose", SHADE, OPACITY),
                            "color": colorToRgb("rose", 600, 1)
                        }}>{`${normalised[0]}%`}</div>

                        {/* <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style="width: 45%"> 45%</div> */}


                    </div>
                }

                {
                    normalised[1] === 0 ? null :
                    <div class="" style={{width: `${normalised[1]}%`}} 
                        data-tooltip-id="reviewsSentimentTip" data-tooltip-content={`${sentiment[1]} Neutral (${normalised[1]}%)`}>
                        {/* <div className={`w-full text-center text-gray-600 dark:text-gray-400 pt-1 ${normalised[1] < 4 && 'opacity-0'}`}>{`${normalised[1]}%`}</div>
                        <div className={`bg-gray-600 h-2.5 ${normalised[0] === 0 ? 'rounded-l-full': ''} dark:bg-gray-400`}></div> */}

                        <div className={`p-0.5 bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-gray-800 text-sm text-center ${normalised[0] === 0 ? 'rounded-l-full': ''} ${normalised[2] === 0 ? 'rounded-r-full': ''}`}
                        >{`${normalised[1]}%`}</div>
                    </div>
                }

                {
                    normalised[2] === 0 ? null :
                <div class="" style={{width: `${normalised[2]}%`}} 
                    data-tooltip-id="reviewsSentimentTip" data-tooltip-content={`${sentiment[2]} Positive (${normalised[2]}%)`}>
                    {/* <div className={`w-full text-center text-green-600 dark:text-green-500 pt-1 ${normalised[2] < 4 && 'opacity-0'}`}>{`${normalised[2]}%`}</div>
                    <div className='bg-green-600 h-2.5 rounded-r-full dark:bg-green-500'></div> */}

                    <div className='bg-green-600 p-0.5 text-sm text-center rounded-r-full dark:bg-green-500'
                    style={{
                        "backgroundColor": colorToRgb("green", SHADE, OPACITY),
                        "color": colorToRgb("green", 600, 1)
                    }}>{`${normalised[2]}%`}</div>
                </div>
                }
            </div>
        </div>
    )
}

export default ReviewsSentimentBar