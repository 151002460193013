import {GER_DESCRIPTIONS, gerBasicColor} from "../constants/Constants.js";
import { colorToRgb } from "../components/Colors.js";

function descriptionofGER(ger) {
    return (ger in GER_DESCRIPTIONS) ? GER_DESCRIPTIONS[ger] : "";
}

function styleForGER(ger) {
    if (ger.includes("WAY")) {
        return "bg-purple-500 hover:bg-purple-600 dark:bg-purple-600 dark:hover:bg-purple-700";
    } else if (ger.includes("Writing")) {
        return "bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700";
    } else if (ger.includes("College")) { // Language
        return "bg-yellow-700 hover:bg-yellow-800 dark:bg-yellow-600 dark:hover:bg-yellow-700";
    } else { // Language
        return "bg-red-700 hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700";
    }
}

function generateStyles(ger)  {
    ger = ger.replace(/ /g,"_");
    const styleSheet = document.createElement("style");
    styleSheet.textContent = `
        .${ger} {
            background-color: ${colorToRgb(gerBasicColor(ger), 400, 0.2)};
            border: 1.5px solid ${colorToRgb(gerBasicColor(ger), 400, 0.1)};
            color: ${colorToRgb(gerBasicColor(ger), 600, 1)};
        }

        .${ger}:hover {
            background-color: ${colorToRgb(gerBasicColor(ger), 400, 0.3)};
            border-color: ${colorToRgb(gerBasicColor(ger), 400, 0.2)};
        }
    `
    document.head.appendChild(styleSheet);
}

function processedGERs(gers) {
    // only allow gers where it contains WAY, Writing, or is equal to Lanuage
    return gers.filter(ger => ger.includes("WAY") || ger.includes("Writing") || ger === "Language" || ger === "College");
}


export { descriptionofGER, styleForGER, processedGERs, generateStyles };