import { React, useEffect, useState } from "react"
import PlannerNavbar from "../components/Navbars/PlannerNavbar"
import "../styles/404.css"

import { CODE_TO_GERINDEX } from "../constants/GerMappings"
import { QUARTER_LONG_LIST } from "../constants/Constants"
import { set } from "react-hook-form"
import { GER_LIST } from "../constants/Constants"

import { db } from "../config/firebase"
import { doc, setDoc } from "firebase/firestore"

import { useAuthState } from "react-firebase-hooks/auth"
import { fireBaseAuth } from "../config/firebase"

function Porting() {
  // useState hook to manage the state of the input
  const [inputValue, setInputValue] = useState("")
  const [importedData, setImportedData] = useState({})
  const [downloadingData, setDownloadingData] = useState(false)
  const [uploadingData, setUploadingData] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [user, loading, error] = useAuthState(fireBaseAuth)

  // Handler function to capture input value
  const handleInputChange = (event) => {
    const val = event.target.value.replace("Token ", "")
    setInputValue(val)
  }

  const convertSeasontoIndex = (season) => {
    return ["Autumn", "Winter", "Spring", "Summer"].indexOf(season)
  }

  const convertData = (data) => {
    const obj = JSON.parse(data)

    var quarterMappings = {}

    obj.forEach((item) => {
      const term = item.term
      const year = term.start_year
      const quarter = convertSeasontoIndex(term.season)

      const course = item.course
      const title = course.title
      const code = course.course_codes[course.course_codes.length - 1]
        .replace(" ", "")
        .toUpperCase()
      const average_hours_spent = parseInt(course.average_hours_spent)
      const max_units = course.max_units

      const updates = {
        title: title,
        units: max_units,
        code: code,
      }

      if (average_hours_spent) {
        updates.medianHours = average_hours_spent
      }

      if (code in CODE_TO_GERINDEX) {
        const gerIndexs = CODE_TO_GERINDEX[code]
        var gers = []

        for (const index of gerIndexs) {
          if (index < GER_LIST.length) {
            gers.push(GER_LIST[index])
          }
        }

        if (gers.length !== 0) {
          updates.gers = gers
        }
      }

      const key = `${year}-${quarter}`
      if (!(key in quarterMappings)) {
        quarterMappings[key] = {}
      }
      quarterMappings[key][code] = updates
    })

    setImportedData(quarterMappings)
    setCheckedRows(Object.keys(quarterMappings))
  }

  // Function to log the value when the button is clicked
  const portOver = () => {
    setDownloadingData(true)
    var myHeaders = new Headers()
    myHeaders.append("Authorization", "Token " + inputValue)

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch("https://carta-beta.stanford.edu/api/pins/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        convertData(result)
        setDownloadingData(false)
      })
      .catch((error) => {
        console.log("error", error)
        setDownloadingData(false)
        setCheckedRows([])
      })
  }

  const [checkedRows, setCheckedRows] = useState([])
  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setCheckedRows((prevState) => [...prevState, id])
    } else {
      setCheckedRows((prevState) => prevState.filter((rowId) => rowId !== id))
    }
  }

  async function updateMultipleDocs(dataArr) {
    // Create an array of promises for each set request
    const promises = dataArr.map(async (item) => {
      const key = item[0]
      const code = item[1]
      const data = item[2]

      const docRef = doc(db, "Planned", user.uid, key, code) // Assuming each item has an id, adjust as necessary
      return setDoc(docRef, data) // Assuming each item has a 'data' object for the document data
    })

    try {
      // Wait until all set requests are completed
      await Promise.all(promises)

      console.log("All documents successfully written!")

      setUploadingData(false)
      setUploadSuccess(true)
    } catch (error) {
      console.error("Error writing documents: ", error)
      alert("Error writing documents: ", error)

      setUploadingData(false)
      setUploadSuccess(false)
    }
  }

  const uploadData = () => {
    setUploadingData(true)

    // begin upload
    var dataToUpload = []

    for (const flowKey of checkedRows) {
      const coursesDict = importedData[flowKey]

      for (const courseCode of Object.keys(coursesDict)) {
        const course = coursesDict[courseCode]

        dataToUpload.push([flowKey, courseCode, course])
      }
    }
    // console.log(c)
    updateMultipleDocs(dataToUpload)
  }

  return (
    <div>
      <PlannerNavbar />

      <div className="w-screen h-screen flex justify-center pt-10 space-x-10">
        <div className="w-1/4 justify-start text-left">
          <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Instructions:</h2>
          <ol class="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
            <li>
              Open <a href="https://carta-beta.stanford.edu/">Carta</a> in <u>Chrome</u> and log in.
            </li>
            <li>
              Right Click and select <span className="font-bold">Inspect</span>.
            </li>
            <li>
              In the top right corner, click <span className="font-bold"> {">>"} </span> and select{" "}
              <span className="font-bold">Network</span>.
            </li>
            <li>Reload the page by pressing CMD & R (Mac) or CTRL & R (Windows).</li>
            <li>
              Click on the <span className="font-bold text-blue">user/</span> file.
            </li>
            <li>
              Scroll down the right console to <span className="font-bold">Request Headers</span>{" "}
              file. Go to the <span className="font-bold">Authorization</span> section and copy the
              token.
            </li>
            <li>Paste it in the box below and click the button.</li>
          </ol>

          <div className="flex items-center w-full">
            <div className="pt-10 w-full">
              <label
                for="first_name"
                class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
              >
                Token
              </label>

              <div className="flex items-center space-x-4">
                <input
                  type="text"
                  id="first_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                                focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Enter a token"
                  required
                />

                <button
                  className="text-gray-900 dark:text-white border-2 border-inherit font-bold py-3 px-3 rounded-full"
                  onClick={() => {
                    portOver()
                  }}
                >
                  <svg
                    class="w-[30px] h-[30px]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                    <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                  </svg>
                </button>
              </div>
            </div>

            {/* <button className="bg-gray-800 dark:bg-white text-white dark:text-gray-800 font-bold py-4 px-4 rounded-full mt-10" */}
          </div>
        </div>

        <div className="w-7/12">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
            {/* <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400"> */}
            <p class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              Courses Imported
              <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                This is the list of classes we have imported from Carta. Please enter your token
                above to import your classes and press the button.
              </p>
            </p>

            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label for="checkbox-all-search" class="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Year
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Quarter
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Courses
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(importedData)
                    .sort()
                    .map((key) => {
                      const quarter = importedData[key]

                      const year = key.split("-")[0]
                      const quarterIndex = key.split("-")[1]

                      return (
                        <tr
                          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                          key={key}
                        >
                          <td class="w-4 p-4">
                            <div class="flex items-center">
                              <input
                                id={`checkbox-table-search-${key}`}
                                type="checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(e) => handleCheckboxChange(key, e.target.checked)}
                                defaultChecked={checkedRows.includes(key)}
                              />
                              <label for="checkbox-table-search-1" class="sr-only">
                                checkbox
                              </label>
                            </div>
                          </td>
                          <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {year}
                          </th>
                          <td class="px-6 py-4">{QUARTER_LONG_LIST[quarterIndex]}</td>
                          <td class="px-6 py-4">
                            {
                              // Object.keys(quarter).map((course) => {
                              //     re
                              // })

                              // join the keys with a comma
                              Object.keys(quarter).join(", ")
                            }
                          </td>
                        </tr>
                      )
                    })}

                  {downloadingData ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
          {uploadSuccess && !uploadingData ? (
            <div
              class="flex w-full max-w-full items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"></path>
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">Success alert!</span> Change a few things up and try
                submitting again.
              </div>
            </div>
          ) : null}

          {Object.keys(importedData).length !== 0 ? (
            <div class="w-full flex justify-center items-center mt-5">
              {!uploadingData ? (
                <button
                  type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium 
                                rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none 
                                dark:focus:ring-blue-800"
                  onClick={() => {
                    uploadData()
                  }}
                >
                  Upload
                </button>
              ) : (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Porting
