export function initialCourseFlow(date) {
    
    // quarter dviide MM.DD
    // 0 -> Winter, 1 -> Spring, 2 -> Summer, 3 -> Fall
    // Dates :
    const YEAR_DIVISION = [7, 0]

    //      period             Win      Spr      Sum,                                                   Fall      Win
    const quarterDivides = [0,     2.20,    6.00,    parseFloat(`${YEAR_DIVISION[0]}.${YEAR_DIVISION[1]}`), 11.10];
    const quarterMappings= [    1,       2,       3,                                                     0]
    
    var year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const combined = parseFloat(`${month}.${day}`);

    // loop over pairs
    var quarterIndex = 1;
    for (var i = 0; i < quarterDivides.length-1; i++) {
        if (combined >= quarterDivides[i] && combined < quarterDivides[i+1]) {
            quarterIndex = quarterMappings[i];
            break;
        }
    }

    if (month < YEAR_DIVISION[0] || (month === YEAR_DIVISION[0] && day < YEAR_DIVISION[1])) {
        year -= 1;
    }

    return [year, quarterIndex];
}