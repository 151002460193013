// import React, { createContext, useContext, useEffect, useState } from 'react';

// // Create a context
// const DarkModeContext = createContext(false);

// // Export a hook that can be used to access the context
// export function useDarkMode() {
//   return useContext(DarkModeContext);
// }

// // Create a provider component
// export function DarkModeProvider({ children }) {
//   const [isDarkMode, setIsDarkMode] = useState(() => {
//     // Get from localStorage
//     // const savedPreference = localStorage.getItem('dark-mode-preference');
//     // if (savedPreference) {
//     //   return JSON.parse(savedPreference);
//     // }
//     return window.matchMedia('(prefers-color-scheme: dark)').matches;
//   });

//   // useEffect(() => {
//   //   // Save to localStorage
//   //   // localStorage.setItem('dark-mode-preference', JSON.stringify(isDarkMode));

//   //   // Update the <html> class
//   //   // if (isDarkMode) {
//   //   //   document.documentElement.classList.add('dark');
//   //   // } else {
//   //   //   document.documentElement.classList.remove('dark');
//   //   // }
//   // }, [isDarkMode]);

//   const toggleDarkMode = () => {
//     setIsDarkMode(prevMode => !prevMode);
//     // reload page
//     window.location.reload();
//   };

//   return (
//     <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
//       {children}
//     </DarkModeContext.Provider>
//   );
// }

import React, { createContext, useContext, useEffect, useState } from 'react';

// Create a context
const DarkModeContext = createContext(false);

// Export a hook that can be used to access the context
export function useDarkMode() {
  return useContext(DarkModeContext);
}

// Create a provider component
export function DarkModeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(mediaQuery.matches);

    const handler = (event) => setIsDarkMode(event.matches);
    mediaQuery.addEventListener('change', handler);

    return () => mediaQuery.removeEventListener('change', handler);
  }, []);

  return (
    <DarkModeContext.Provider value={isDarkMode}>
      {children}
    </DarkModeContext.Provider>
  );
}