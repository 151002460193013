import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, defer, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import { ProfileConsumer, ProfileProvider } from './helpers/ProfileContext';
import Login from './pages/Login';
import PrivateRoutes from './helpers/PrivateRoutes';
import AuthLayout from './layouts/AuthLayout';
import UnprotectedLayout from './layouts/UnprotectedLayout';
import ProtectedLayout from './layouts/ProtectedLayout';
import Planner from "./pages/Planner";
import NotFound from "./pages/NotFound";
import Share from "./pages/Share";
import Porting from "./pages/Porting";

import Catalog from "./pages/Catalog";
import CatalogSubject from "./pages/CatalogSubject";
import InstructorView from "./pages/InstructorView";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<AuthLayout/>}>
			<Route element={<UnprotectedLayout/>}>
				<Route path="/login" element={<Login/>} />
			</Route>
			<Route element = { <ProtectedLayout/> }>
				<Route path="/" element={ <Home/> }/>
				<Route path="/:CLASS_CODE" element ={ <Home/> }/>
        		<Route path="/plan" element={ <Planner /> }/>
        		<Route path="/share/:ID" element={ <Share /> }/>
				<Route path="/catalog" element={ <Catalog /> }/>
				<Route path="/catalog/:SUBJECT" element={ <CatalogSubject /> }/>
				<Route path="/instructor" element ={ <InstructorView/> }/>
				{/* <Route path="/recommend" element={ <Recommend /> }/> */}


				<Route path="/porting" element={ <Porting /> }/>
			</Route>

            {/* Add this line at the end to handle 404s */}
            <Route path="*" element={<NotFound />} />
		</Route>
	)
)

function App() {
  return (
      <div className="App">
          <RouterProvider router={router}/>
      </div>
  );
}

export default App;
