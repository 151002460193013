import React, { useState, useEffect, useRef } from "react"
import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'
import "../styles/GraphStyling.css"

cytoscape.use(dagre)

const PrerequisiteTree = ({ prereqs, rootId, setCourseCode }) => {
  const [showTree, setShowTree] = useState(false)
  const buttonRef = useRef(null)
  const popupRef = useRef(null)
  const cyRef = useRef(null)
  const [isReady, setIsReady] = useState(false)
  const isDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches

  useEffect(() => {
    if (!showTree || !cyRef.current) return

    const cy = cytoscape({
      container: cyRef.current,
      elements: [],
      style: [
        {
          selector: 'node',
          style: {
            'background-color': 'data(color)',
            'color': isDarkMode ? '#242424' : 'black',
            'label': 'data(id)',
            'text-valign': 'center',
            'text-halign': 'center',
            'width': 80,  // Increased from 60 to 80
            'height': 80, // Increased from 60 to 80
            // 'font-size': '20px', // Increased from 14px to 16px
            'font-weight': 'bold',
            'text-wrap': 'wrap',
            'text-max-width': '76px', // Increased from 56px to 76px
            'cursor': 'pointer',  // Add this line to change the cursor to a pointer
            'font-size': (ele) => {
                const label = ele.data('id');
                const maxSize = 20;
                const minSize = 10;
                const idealWidth = 76;
                const fontSize = Math.max(minSize, Math.min(maxSize, idealWidth / (label.length * 0.7)));
                return `${fontSize}px`;
              },
          }
        },
        {
          selector: 'edge',
          style: {
            'width': 3,
            'line-color': '#ccc',
            'target-arrow-color': '#ccc',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier'
          }
        },
        {
          selector: 'node[cycleNode]',
          style: {
            'border-width': 3,
            'border-color': 'red'
          }
        }
      ],
    })

    const getNodeColor = (depth) => {
      const hue = depth * 30
      const saturation = 70
      const lightness = 70
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`
    }

    // Create a graph structure
    const graph = {}
    prereqs.forEach(edge => {
      if (!graph[edge.s]) graph[edge.s] = { children: [], depth: 0 }
      if (!graph[edge.t]) graph[edge.t] = { children: [], depth: 0 }
      graph[edge.s].children.push(edge.t)
    })

    // Function to detect cycles and calculate depths
    const processNode = (nodeId, depth = 0, visited = new Set(), path = new Set()) => {
      if (path.has(nodeId)) {
        // Cycle detected
        return { cycleDetected: true, cycleNode: nodeId }
      }
      if (visited.has(nodeId)) {
        return { cycleDetected: false }
      }

      visited.add(nodeId)
      path.add(nodeId)

      graph[nodeId].depth = Math.max(graph[nodeId].depth, depth)

      for (const childId of graph[nodeId].children) {
        const result = processNode(childId, depth + 1, visited, path)
        if (result.cycleDetected) {
          if (result.cycleNode === nodeId) {
            // We've come full circle, mark this node as part of a cycle
            graph[nodeId].cycleNode = true
            return { cycleDetected: false } // Stop propagating the cycle detection
          }
          return result // Continue propagating the cycle detection
        }
      }

      path.delete(nodeId)
      return { cycleDetected: false }
    }

    processNode(rootId)

    // Create Cytoscape elements
    const elements = Object.entries(graph).flatMap(([id, node]) => [
      { 
        data: { 
          id, 
          color: getNodeColor(node.depth),
          depth: node.depth,
          cycleNode: node.cycleNode
        } 
      },
      ...node.children.map(childId => ({ 
        data: { source: id, target: childId } 
      }))
    ])

    cy.add(elements)

    // Apply layout
    const layout = cy.layout({
        name: 'dagre',
        rankDir: 'TB',
        rankSep: 40, // Further reduced from 80 to 40
        nodeSep: 60, // Reduced from 80 to 60
        animate: true,
        animationDuration: 500,
        fit: true,
        padding: 20 // Reduced padding from 50 to 20
      })
  
      layout.run()
  
      cy.on('tap', 'node', function(evt) {
        const node = evt.target
        setCourseCode(node.id().replace(" ", ""))
      })
  
      // Adjust the viewport to show more of the graph
      cy.fit(cy.elements(), 30) // Reduced padding from 50 to 30
  
      // Center on the root node
      cy.center(cy.$id(rootId))

      cy.on('mouseover', (event) => {
        if(event.cy.container()) {
          event.cy.container().style.cursor = 'pointer';
        }
      })
      
      cy.on('mouseout', (event) => {
        if(event.cy.container()) {
          event.cy.container().style.cursor = 'default';
        }
      })
  
      setIsReady(true)
  
      return () => {
        cy.destroy()
      }
  
  }, [showTree, prereqs, rootId, setCourseCode, isDarkMode])

  useEffect(() => {
    if (showTree && buttonRef.current && popupRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect()
      const topPosition = buttonRect.bottom + window.scrollY + 10
      popupRef.current.style.top = `${topPosition}px`
    }
  }, [showTree])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowTree(false)
      }
    }

    if (showTree) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showTree])

  return (
    <div className="relative inline-block">
      <button
        ref={buttonRef}
        type="button"
        className="pr-2 group transition-transform duration-200 ease-in-out relative"
        onClick={() => {
          setShowTree(!showTree)
        }}
        style={{ transform: showTree ? "scale(1.1)" : "scale(1)" }}
        title="View Prerequisites"
      >
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          viewBox="0 0 24 24"
          transform="rotate(180) scale(-1, 1)"
        >
          <path
            fillRule="evenodd"
            d="M11.5625 23.5312c-1.0031-0.2391-1.7344-1.1062-1.8047-2.1328c-0.0469-0.675 0.1875-1.3406 0.6422-1.7953l0.1781-0.1828l-1.2188-2.4422l-1.2187-2.4422l-0.525-0.0047c-0.5859 0-0.8812-0.0891-1.3266-0.3797c-1.2281-0.8109-1.4203-2.5734-0.3984-3.6375l0.1734-0.1781l-1.2281-2.4516l-1.2234-2.4515l-0.2531 0.0375c-0.5391 0.075-1.2-0.1032-1.6641-0.4453c-0.4359-0.3188-0.7266-0.7359-0.8766-1.2422c-0.0937-0.3234-0.0984-0.9328-0.0047-1.2937c0.3141-1.2047 1.5984-1.9594 2.8359-1.6641c0.9094 0.2156 1.6453 1.0219 1.7859 1.9687c0.0984 0.6422-0.1312 1.3875-0.5906 1.9079l-0.2156 0.2437l1.2141 2.4281l1.2187 2.4281l0.5484 0l0.5484 0.0047l1.2141-2.4281l1.2141-2.4281l-0.2203-0.2719c-0.8156-0.9984-0.7641-2.3109 0.1265-3.1969c0.7266-0.7312 1.7625-0.9 2.6953-0.4453c1.7297 0.8484 1.7391 3.3234 0.0141 4.1906c-0.4641 0.2297-0.9469 0.3188-1.3406 0.2484l-0.2484-0.0422l-1.2188 2.4281c-0.6656 1.3407-1.2141 2.4563-1.2141 2.4844c0 0.0281 0.0984 0.1594 0.2156 0.2906c0.7922 0.8813 0.7734 2.2547-0.0422 3.1547l-0.2109 0.2297l1.2234 2.4422l1.2188 2.4422l0.2391-0.0422c0.1312-0.0234 0.3703-0.0281 0.5297-0.0094l0.2906 0.0281l1.2188-2.4422l1.2234-2.4422l-0.2203-0.2719c-0.8156-0.9984-0.7641-2.3109 0.1265-3.1969c0.7266-0.7312 1.7625-0.9 2.6953-0.4453c1.7297 0.8484 1.7391 3.3234 0.0141 4.1906c-0.4641 0.2297-0.9469 0.3188-1.3406 0.2484l-0.2484-0.0422l-1.2375 2.4703l-1.2375 2.4703l0.1781 0.1734c0.3937 0.3844 0.6609 1.0359 0.6609 1.6219c0 1.5281-1.4437 2.6766-2.9156 2.3156z"
            clipRule="evenodd"
          />
        </svg>
        <span 
          className="absolute bottom-0 right-0 px-1 py-0.5 bg-red-600 text-white text-xs font-bold rounded-sm"
          style={{ fontSize: '0.6rem', lineHeight: '1', transform: 'translate(50%, 50%)' }}
        >
          BETA
        </span>
      </button>
      {showTree && (
        <div
          ref={popupRef}
          className="fixed z-10 bg-white dark:bg-zinc-800 p-4 rounded-lg shadow-lg"
          style={{
            width: "60vw",
            height: "65vh", // Changed from 80vh to 50vh
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >

          {/* Beta box */}
          <div 
            className="absolute top-2 left-2 px-2 py-1 bg-white dark:bg-zinc-800 text-red-500 font-bold text-sm border-2 border-red-500 rounded-lg"
            style={{ zIndex: 20 }}
          >
            BETA
          </div>
          <button
            onClick={() => setShowTree(false)}
            className="absolute top-1 right-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            style={{ transform: "scale(1.5)" }}
          >
            <svg
              className="w-6 h-6 mr-2 mt-2 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <h2 className="text-xl font-semibold mb-4 text-center">Prerequisite map for {rootId}</h2>

          <div ref={cyRef} style={{ width: "100%", height: "calc(100% - 30px)" }} />
        </div>
      )}
    </div>
  )
}

export default PrerequisiteTree