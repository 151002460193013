import React from 'react'

// const DIFFICULTY_COLORS = [
//     '#00eba0', // 0
//     '#14db9c', // 1
//     '#cfff40', // 2
//     '#f5ff40', // 3
//     '#ffe240', // 4
//     '#ffc240', // 5
//     '#f79131', // 6
//     '#f25f16', // 7
//     '#f71b1b', // 8
//     '#cf1b1b' // 9
// ]

function scoreToColor(score, min, max) {
    // Ensure score is within bounds
    score = Math.min(max, Math.max(min, score));

    // Scores above 4.8 are green
    if (score > 9) {
        return 'hsl(0, 70%, 50%)'; // green with lowered saturation
    } else if (score < 1) {
        return 'hsl(0, 70%, 50%)'; // red with lowered saturation
    }

    // Map score to hue (120 - red, 0 - green) by inverting the calculation
    let hue = 120 - ((score - min) / (max - min)) * 120;

    // Return hsl color string
    return `hsl(${hue}, 70%, 50%)`; // color with lowered saturation
}


function CourseDifficulty(props) {
    const {difficulty, isMobile } = props;
    // const difficulty = 9.5;

    const textColor = (difficulty < 6) ? 'text-gray-800' : 'text-white';
    // const textColor = 'text-white';

    // difficulty = 0-10, so change range to 0-9
    // const index = Math.max(Math.min(parseInt(difficulty), 9), 0);
    // const color = DIFFICULTY_COLORS[index];

    // adjusted min and max for scoreToColor function
    const color = scoreToColor(difficulty, 1, 9);
    // const color = scoreToColor(difficulty, 0, 10);

    // Calculate color based on difficulty using colorSpectrum function
    // const color = colorSpectrum(120, 0, 0, 10, difficulty);

    return (
        <button type="button" class={`px-${isMobile ? 3: 4} py-${isMobile ? 1: 1} cursor-default font-medium rounded-lg text-${isMobile ? 'xs': 'sm'}  ${textColor}`}
        style={{ backgroundColor: color }}
        data-tooltip-id="course-tooltip" data-tooltip-html={`<b>Complexity Rating: ${difficulty}/10</b><br/>This is an estimate based on weekly hours,<br/>year distributions, past reviews, and grade distributions.`}
        >
            {difficulty}
        </button>
    )
}

export default CourseDifficulty