import React, {useState, useContext} from "react";
import dayjs from 'dayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DarkModeProvider, useDarkMode } from '../helpers/DarkModeContext';
import {db} from "../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireBaseAuth } from "../config/firebase";
import { CurrentFlow, CourseSections } from '../helpers/Contexts';
import { set } from "date-fns";

import { Section, CourseTime } from "../data/Section";
import {restrictedConvertTime} from "../helpers/RestrictedHelpers.js";
import { DAY_LIST } from "../constants/Constants.js";


function RestrictedTimeView({ showRestrict, setShowRestrict, restrictedDict, setRestrictedDict }) {
    var {currentFlow, setCurrentFlow} = useContext(CurrentFlow);
    const {courseSections, setCourseSections} = useContext(CourseSections);

    // Restricted Times
    const [restrictStartTime, setRestrictStartTime] = useState(dayjs('2022-04-15T15:30'));
    const [restrictStopTime, setRestrictStopTime]   = useState(dayjs('2022-04-17T17:30'));
    // const [restrictEventName, setRestrictEventName] = useState('');
    const [errorMessage, setErrorMessage] = useState("");

    const isDarkMode = useDarkMode();
    const [user, loading, error] = useAuthState(fireBaseAuth);

    const DATE_STYLING = {
        "color": isDarkMode ? '#9ca3af': "black",
        "border-color": isDarkMode ? '#9ca3af': "black",
    };

    const DATE_STYLING_MULTI = {
            '& .MuiInputBase-root': DATE_STYLING,
            '& .MuiInputBase-input': DATE_STYLING,
            '& .MuiFormLabel-root': DATE_STYLING,
            '& .MuiFormLabel-root:hover': DATE_STYLING,
            '& .MuiIconButton-root': DATE_STYLING,
            '& .MuiIconButton-root:hover': DATE_STYLING,
            '& .MuiOutlinedInput-notchedOutline': DATE_STYLING,
            '& .MuiOutlinedInput-notchedOutline:hover': DATE_STYLING,
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': DATE_STYLING,
        };
    const [restrictedDays, setRestrictedDays] = useState([]);

    const handleRestrictedChange = (e, index) => {
        if (e.target.checked) {
            setRestrictedDays(prev => [...prev, index]);
        } else {
            setRestrictedDays(prev => prev.filter(i => i !== index));
        }
    }


    function addRestrictedTime() {
        // check if selected days is not empty
        if (restrictedDays.length === 0) {
            setErrorMessage("Please select at least one day");
            return;
        }

        // convert to 24 hour time
        // const startTime = restrictStartTime.hour() + restrictStartTime.minute() / 60;
        // const stopTime = restrictStopTime.hour() + restrictStopTime.minute() / 60;


        // check if the start time is before the end time
        if (restrictStartTime.hour() > restrictStopTime.hour() || 
            (restrictStartTime.hour() === restrictStopTime.hour() && restrictStartTime.minute() >= restrictStopTime.minute())) {
            setErrorMessage("Start time must be before end time");
            return;
        }

        // get value of input with id restricted_name
        var name = document.getElementById("restricted_name").value.toUpperCase();
        if (name === "") { name = "RESTRICTED"; }

        // TODO
        // CHECK whether it exists in the database already
        // !!!!

        // sort restricted Days
        restrictedDays.sort();

        const startArr = [restrictStartTime.hour(), (restrictStartTime.minute()/60).toFixed(2)];
        const stopArr = [restrictStopTime.hour(), (restrictStopTime.minute()/60).toFixed(2)];
        
        const newRestrictedTime = {...restrictedDict};
        newRestrictedTime[name] = {
            days: restrictedDays,
            start: startArr,
            stop: stopArr,
        };

        var sectionTimes = [];


        restrictedDays.map((day) => {
            var cTime = new CourseTime(day+1, startArr[0], startArr[1], 
                            stopArr[0], stopArr[1], name);
            cTime.restricted = true;
            sectionTimes.push(
                cTime
            );
        });

        const sectionDescription = restrictedDays.map((i) => DAY_LIST[i]).join(",") + " " + restrictedConvertTime(startArr) + "-" + restrictedConvertTime(stopArr) + ".";

        const sectionNumber = `${Object.keys(newRestrictedTime).length}`.padStart(2, '0');

        var section = Section.createRestricted(name, sectionNumber, "", "", "", "", sectionTimes, sectionDescription);
        section.flow = currentFlow;

        setCourseSections(prevState => ({
            ...prevState,
            [name]: [section]
        }));

        setRestrictedDict(newRestrictedTime);
        // setRestrictedDict(prevState => ({
        //     ...prevState,
        //     [name]: sections
        // }));


        // upload to firebase
        const restrictedRef = doc(db, 'Planned', user.uid, `${currentFlow[0]}-${currentFlow[1]}`, 'RESTRICTED');
        setDoc(restrictedRef, { items: newRestrictedTime });

        setRestrictedDays([]);
        document.getElementById("restricted_name").value = "";
        setShowRestrict(false);
    }
    
    if (!showRestrict) { return null; }

    return (
        <div tabindex="-1" className="pl-2 z-50 mt-5">
            <div className="relative bg-white rounded-lg dark:bg-gray-800 p-4">
                <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                    <h3 className="mb-3 text-xl text-left font-bold text-gray-900 dark:text-white">Add Restricted Time</h3>

                    <ul className="grid w-full px-5 gap-3 grid-cols-5">
                        {
                            ["M", "T", "W", "T", "F"].map((day, index) => (
                                <li key={index}>
                                    <input type="checkbox" id={`option-${index}`} 
                                        value={index}
                                        checked={restrictedDays.includes(index)}
                                        onChange={(e) => handleRestrictedChange(e, index)}
                                        className="hidden peer" 
                                        required="" />
                                    <label for={`option-${index}`} 
                                    className="inline-flex items-center justify-between w-full text-gray-500 bg-white border-[3px] border-gray-200 rounded-lg cursor-pointer 
                                    dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 
                                    peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700
                                    dark:peer-checked:bg-gray-700 ">                           
                                        <div className="block w-full">
                                            <div className="w-full text-center text-lg font-semibold">{day}</div>
                                        </div>
                                    </label>
                                </li>
                            ))
                        }
                    </ul>
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="mt-5 flex space-x-2">
                                <DesktopTimePicker
                                    label="Start Time"
                                    value={restrictStartTime}
                                    onChange={(newTime) => {
                                        // if (`${newTime.$H}`.length === 2 && `${newTime.$m}`.length === 2) {
                                            setRestrictStartTime(newTime);
                                        // }
                                    }}
                                    sx={DATE_STYLING_MULTI}
                                />

                                <DesktopTimePicker
                                    label="End Time"
                                    value={restrictStopTime}
                                    onChange={(newTime) => {
                                        // if (`${newTime.$H}`.length === 2 && `${newTime.$m}`.length === 2) {
                                            setRestrictStopTime(newTime);
                                        //  }
                                    }}
                                    sx={DATE_STYLING_MULTI}
                                />

                        </div>
                    </LocalizationProvider>

                    <div className="mt-2">
                        <label for="restricted_name" className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Event name</label>
                        <input type="text" id="restricted_name" 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="Restricted" 
                        // value={restrictEventName}
                        // onChange={(e) => setRestrictEventName(e.target.value)}
                        />
                        { errorMessage !== "" ?
                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Error: {errorMessage}</span></p>
                            : null 
                        } 
                    </div>

                    
                </div>
                <div className="justify-between items-center pt-0 space-y-4 mt-4">
                    {/* <a href="#" className="font-medium text-primary-600 dark:text-primary-500 hover:underline">Learn more about privacy</a> */}
                    <div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
                        <button id="close-modal" type="button"  className="py-2 px-4 w-full text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 sm:w-auto hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                        onClick={
                            () => {
                                setShowRestrict(false);
                            }
                        }>
                            Cancel
                        </button>
                        {/* <button id="confirm-button" type="button" className="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-auto hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Confirm</button> */}
                        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            onClick={
                                () => {
                                    addRestrictedTime();
                                }
                            }>
                            Confirm
                        </button>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RestrictedTimeView;