import React, {useState} from 'react'
import CatalogNavbar from '../components/Navbars/CatalogNavbar'
import { DarkModeProvider, useDarkMode } from '../helpers/DarkModeContext';
import { useIsMobile } from "../layouts/Mobile";
// import { CatalogDepartments } from '../catalog/CatalogDepartments'
import CatalogSubjectList from '../catalog/CatalogSubjectList'

import {
    IsMobile,
} from '../helpers/Contexts';

function CatalogSubject() {
    // get 
    var [isMobile, setIsMobile] = useState(useIsMobile());
    const mobileClass = isMobile ? 'mobile' : 'desktop';
    const isDarkMode = useState(false);//useDarkMode();

  return (
    <DarkModeProvider value={{isDarkMode}}>
        <IsMobile.Provider value={{isMobile, setIsMobile}}>
        <div className="flex flex-col overflow-hidden">
            <CatalogNavbar/>
            <div className={"flex dark:bg-zinc-900 dark:text-white items-center justify-center pt-14 pb-5 " + mobileClass}>
                <CatalogSubjectList/>
            </div>
        </div>
        </IsMobile.Provider>
    </DarkModeProvider>
  )
}

export default CatalogSubject