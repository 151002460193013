import { Navigate, useOutlet } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { fireBaseAuth } from "../config/firebase";
// import { NavBar } from "../components/Navbars/Navbar";
import { onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../hooks/useAuth";

const UnprotectedLayout = () => {
  // const {loggedIn, login, logout} = useAuth();
  const [user, loading, error] = useAuthState(fireBaseAuth);
  const outlet = useOutlet();
  if (user) {
    return <Navigate to="/"/>;
  }
  else {
    return (
      <div>
        {outlet}
      </div>
    );
  }
};

export default UnprotectedLayout;