
function getFrequentlyUsed() {
    // const key = `CourseMeta_${currentFlow[0]}_${currentFlow[1]}_${courseCode}`;
    const key = `Catalog_Frequently_Used`;
    const dataString = localStorage.getItem(key);

    if (dataString) {
        let data;
        
        try {
            data = JSON.parse(dataString);
        } catch (error) {
            return null;
        }
        
        return data;
    }
    return null;
}


function saveFrequentLinks(subject, subjectCode) {
    const key = `Catalog_Frequently_Used`;

    var frequent = getFrequentlyUsed();
    if (frequent === null) {
        frequent = [];
    }

    // check if already in frequent
    const newAddition = [subjectCode, subject];

    // remove if already in frequent
    frequent = frequent.filter(item => item[0] !== subjectCode);
    
    // add newAddition to the 0 index
    frequent.unshift(newAddition);

    // slice to be the first 6
    frequent = Array.from(frequent).slice(0, 6);

    const value = encodeURIComponent(JSON.stringify(frequent));
    localStorage.setItem(key, value);

}

export { getFrequentlyUsed, saveFrequentLinks };