import React from 'react'
import ReactEcharts from "echarts-for-react"; 
import { useDarkMode } from '../../helpers/DarkModeContext';
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';

// var testData = [0.059932659932659935,0.2895622895622896,0.12996632996632998,0.17777777777777778,0.15824915824915825,0.04781144781144781,0.027609427609427608,0.024242424242424242,0.011447811447811448,0.0026936026936026937,0.010774410774410775,0.0026936026936026937,0.0013468013468013469,0.04713804713804714,0.008754208754208754];
const columns = ["A+","A","A-","B+","B","B-","C+","C","C-","D+","D","D-","F","CR","NC"];


//maybe don't use percentage, but do rendered the percentage in the tooltip
function GradeDistribution(props) {
    const {data, course, isMobile} = props;
    const isDarkMode = useDarkMode();

    if (data === undefined) {
      return (<div className='h-10'></div>);
    }
    // const median = calculateMedian(data);

    const colors = [
      '#5899f7',
      '#6aa4f7',
      '#7caff8',
      '#8ebbf9',
      '#a1c6fa',
      '#b3d2fb',
      '#c5ddfc',
      '#d8e9fd' ]

    const option = {
        xAxis: {
          type: 'category',
          data: columns,
          axisLabel: {
            textStyle:{
              color: isDarkMode ? 'white' : 'black'
           },
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}%', // Format Y-axis labels as percentages
            textStyle:{
              color: isDarkMode ? 'white' : 'black'
           },
          },
        },
        series: [
          {
            data: data,
            type: 'bar',
            // color: "#00FA9A",
            itemStyle: {
              normal: {
                color: (params) => colors[Math.floor(params.dataIndex / 2)], // Use different colors based on the index of the bar
              },
            },
            markLine: {
                symbol: 'none', // Remove the arrow head
                lineStyle: {
                  color: '#FF5733', // Set the color of the line
                  width: 4, // Set the width of the line
                  type: 'solid' // Make the line solid
                },
                data: [
                 { xAxis: course.medianGrade !== undefined ? course.medianGrade : '' } // Replace 'specific-x-value' with the actual x-axis value where you want the line to appear.
                ],
                label: {
                    position: 'end',
                    color: '#FF5733', // Make the label color red
                    fontSize: 20, // Increase the font size of the label
                    fontWeight: 'bold' // Make the label bold
                  }
            }
          }
        ],
        tooltip: {
          formatter: (params) => {
            const dataIndex = params.dataIndex;
            if (params.componentType === "markLine") {
              return 'Median (' + course.medianGrade + ')';
            } else {
              return `${data[dataIndex]}%`;
              // return `${dataNormalised[dataIndex]}% (${data[dataIndex]} people)`;
            }
          },
        }
      };
      

  return (
    <div className="h-96">
      <Tooltip id="tool-tip-grade-info"/>
      
      <div className={`flex justify-${isMobile? "center" : "between"} pr-4 ${isMobile ? "space-x-4" : ""}`}>
        <h1 className="font-bold text-2xl">Grade Distribution</h1>

        <button className={`group relative ${isMobile ? "mt-1" : ""}`} data-tooltip-id="tool-tip-grade-info" data-tooltip-content={"Data from Stanford's 2020 grade distribution records."}>
          <svg className="w-6 h-6 text-gray-800 dark:text-white absolute top-0 left-0 opacity-100 group-hover:opacity-0 transition duration-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
          </svg>
          <svg className="w-6 h-6 text-gray-800 dark:text-white absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition duration-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          {/* <InfoIcon /> */}
        </button>
      </div>
      
      {/* <h1 className="font-extrabold text-xl">Grade Distribution <span className='text-teal-600 font-semibold'>({total} people)</span></h1> */}
      <ReactEcharts option={option} style={{
                  height: '100%',
                  width: '100%',
                }}/>
      
    </div>
    // option && myChart.setOption(option)
  )
}

export default GradeDistribution
