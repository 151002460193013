import {
	FILTERING_UNITS,
	GER_LIST,
	DAY_FILTERS,
	COMPONENT_LONG_LIST,
	COMPONENT_SHORT_LIST,
	CAREER_LIST,
	CAREER_SHORT,
	FILTER_TITLES,
	FINAL_LIST,
	GRADE_COLUMN_REVERSED,
} from "./CatalogConstants";

const PORT = 7500;

const search = async (q) => {
	var resp;
	try {
		const isDebugMode = process.env.REACT_APP_DEBUG === "true";
		const serverURL = isDebugMode ? `http://localhost:${PORT}` : "https://server-coyote.fly.dev";
		resp = await fetch(
			`${serverURL}/search?q=` + q
		);
	} catch (error) {
		console.error("There was a problem with the fetch:", error);
		return null;
	}

	if (!resp.ok) {
		console.error("Network response was not ok");
	}
	try {
		return resp.json();
	} catch (error) {
		console.error("There was a problem with the json conversion:", error);
		return null;
	}
};

const searchInstructor = async (q) => {
	var resp;
	try {
		resp = await fetch(
			`${process.env.REACT_APP_DEBUG ? `http://localhost:${PORT}` : "https://server-coyote.fly.dev"}/instructor?q=` +
				q
		);
	} catch (error) {
		console.error("There was a problem with the instructor fetch:", error);
		return null;
	}
	if (!resp.ok) {
		console.error("Network response was not ok (instructor)");
	}
	try {
		return resp.json();
	} catch (error) {
		console.error(
			"There was a problem with the instructor json conversion:",
			error
		);
		return null;
	}
};

// functions to format queries
const idPrefixQuery = (q) => {
	if (q === "") return "";
	q = q.replaceAll(" ", "").toUpperCase();
	if (q.startsWith("MSE")) q = "MS&E" + q.substring(3);
	if (q === "MS&E") return `@subject:${encodeURIComponent(q)}`;
	q = encodeURIComponent(q);
	return `@courseCode:${q}*`;
};

const titleKeywordQuery = (q) => {
	if (q === "") return "";
	q = encodeURIComponent(q);
	return `@title:(\"${q}\"|${q}*)`;
};

const descriptionKeywordQuery = (q) => {
	if (q === "") return "";
	q = encodeURIComponent(q);
	return `@description:(\"${q}\"|${q}*)`;
};

// filters

const QUARTER_QUERIES = ["inAut", "inWin", "inSpr", "inSum"];
const createFilterPrefix = (
	subject,
	quartersOffered,
	unitsSelectedIndexs,
	gersSelectedIndexs,
	filterDays,
	componentsSelectedIndexs,
	careerSelectedIndexs,
	finalselectedOption,
	meanHoursRange,
	medianGradeMinimumIndex
) => {
	var prefix = "";

	// department
	if (subject !== "") {
		prefix += `@subject:${subject} `;
	}

	// quarters
	if (quartersOffered.some((elem) => elem)) {
		// at least one quarter
		var elems = [];
		for (let i in quartersOffered) {
			if (quartersOffered[i])
				elems.push("@" + QUARTER_QUERIES[i] + ":[1 1]");
		}
		prefix += "(" + elems.join(" | ") + ") ";
	}

	// units
	if (unitsSelectedIndexs.some((elem) => elem)) {
		elems = [];
		for (let i in unitsSelectedIndexs) {
			var units = parseInt(i) + 1;
			if (unitsSelectedIndexs[i]) {
				if (i === 5) {
					elems.push("(@unitsMax:[6 %2Binf])");
				} else {
					elems.push(
						`(@unitMin:[0 ${units}] @unitMax:[${units} %2Binf])`
					);
				}
			}
		}
		prefix += "(" + elems.join(" | ") + ") ";
	}

	if (gersSelectedIndexs.some((elem) => elem)) {
		elems = [];
		for (let i in gersSelectedIndexs) {
			if (!gersSelectedIndexs[i]) continue;
			elems.push(GER_LIST[i].replaceAll("-", "\\-"));
		}
		prefix += "@gers:{ " + elems.join(" | ") + " } ";
	}

	if (filterDays.some((elem) => elem)) {
		elems = [];
		for (let i in filterDays) {
			if (!filterDays[i]) continue;
			elems.push(`@${DAY_FILTERS[i]}:[1 1]`);
		}
		prefix += "(" + elems.join(" | ") + ") ";
	}

	if (componentsSelectedIndexs.some((elem) => elem)) {
		elems = [];
		for (let i in componentsSelectedIndexs) {
			if (!componentsSelectedIndexs[i]) continue;
			elems.push(COMPONENT_SHORT_LIST[i].replaceAll("/", "\\/"));
		}
		prefix += "@component:{ " + elems.join(" | ") + " } ";
	}

	if (meanHoursRange[0] !== 0 || meanHoursRange[1] !== 40) {
		prefix += `@meanHours:[${meanHoursRange[0]} ${meanHoursRange[1]}] `;
	}

	if (careerSelectedIndexs.some((elem) => elem)) {
		elems = [];
		for (let i in careerSelectedIndexs) {
			if (!careerSelectedIndexs[i]) continue;
			elems.push(CAREER_SHORT[i]);
		}
		prefix += "@career:{ " + elems.join(" | ") + " } ";
	}

	if (finalselectedOption === "Final") {
		prefix += "@finalExam:[1 1] ";
	} else if (finalselectedOption === "No final") {
		prefix += "@finalExam:[0 0] ";
	}

	if (medianGradeMinimumIndex >= 0) {
		prefix += `@medianGrade:[${GRADE_COLUMN_REVERSED.length - 1 - medianGradeMinimumIndex} %2Binf] `;
	}
	return prefix;
};

const QUERIES = [idPrefixQuery, titleKeywordQuery, descriptionKeywordQuery];
export const overallSearch = async (
	q,
	subject,
	quartersOffered,
	unitsSelectedIndexs,
	gersSelectedIndexs,
	filterDays,
	componentsSelectedIndexs,
	careerSelectedIndexs,
	finalselectedOption,
	meanHoursRange,
	medianGradeMinimumIndex
) => {
	const filter = createFilterPrefix(
		subject,
		quartersOffered,
		unitsSelectedIndexs,
		gersSelectedIndexs,
		filterDays,
		componentsSelectedIndexs,
		careerSelectedIndexs,
		finalselectedOption,
		meanHoursRange,
		medianGradeMinimumIndex
	);

	var time = 0;
	for (const query of QUERIES) {
		var resp = await search(filter + query(q));
		if (resp && resp["count"] > 0) {
			resp["time"] += time;

			// add the ampersand back to mse courses
			for (var course of resp["courses"]) {
				if (course["subject"] === "MS&E") {
					course["courseCode"] = course["id"];
				}
			}

			// sort responses
			resp["courses"] = resp["courses"].sort((a, b) => {
				if (a["subject"] === b["subject"]) {
					const items_a = a["code"].match(/[a-zA-Z]+|[0-9]+/g);
					const items_b = b["code"].match(/[a-zA-Z]+|[0-9]+/g);
					const a_num = parseInt(items_a[0]);
					const b_num = parseInt(items_b[0]);
					if (a_num - b_num !== 0) return a_num - b_num;
					const a_letter = items_a.length < 2 ? "" : items_a[1];
					const b_letter = items_b.length < 2 ? "" : items_b[1];
					return a_letter.localeCompare(b_letter);
				}
				return a["subject"].localeCompare(b["subject"]);
			});

			return resp;
		}

		time = resp !== null ? resp["time"] : 0;
	}

	return null;
};
