export const DEPARTMENTS = [
    "Department of Athletics, Physical Education and Recreation",
    "Doerr School of Sustainability",
    "School of Engineering",
    "School of Humanities & Sciences",
    "Graduate School of Business",
    "Law School",
    "School of Medicine",
    "Office of Vice Provost for Undergraduate Education",
    "School of Education",
]

export const DEPARTMENTS_TO_SUBJECTS = {
    "Office of Vice Provost for Undergraduate Education": [
        ["CTL", "Center for Teaching and Learning"],
        ["COLLEGE", "Civic, Liberal, and Global Education"],
        ["ESF", "Education as Self-Fashioning"],
        ["ITALIC", "Immersion in the Arts"],
        ["SOAR", "Online Bridge Course"],
        ["ORALCOMM", "Oral Communications"],
        ["OSPGEN", "Overseas Studies General"],
        ["OSPAUSTL", "Overseas Studies in Australia"],
        ["CASB", "Overseas Studies in Barcelona"],
        ["OSPBEIJ", "Overseas Studies in Beijing"],
        ["OSPBER", "Overseas Studies in Berlin"],
        ["OSPCPTWN", "Overseas Studies in Cape Town"],
        ["OSPFLOR", "Overseas Studies in Florence"],
        ["OSPHONGK", "Overseas Studies in Hong Kong"],
        ["OSPISTAN", "Overseas Studies in Istanbul"],
        ["OSPKYOTO", "Overseas Studies in Kyoto"],
        ["KCJS", "Overseas Studies in Kyoto"],
        ["OSPMADRD", "Overseas Studies in Madrid"],
        ["OSPOXFRD", "Overseas Studies in Oxford"],
        ["OSPPARIS", "Overseas Studies in Paris"],
        ["OSPSANTG", "Overseas Studies in Santiago"],
        ["RESPROG", "Residential Programs"],
        ["ROTCAF", "ROTC Air Force"],
        ["ROTCARMY", "ROTC Army"],
        ["ROTCNAVY", "ROTC Navy"],
        ["SINY", "Stanford in New York"],
        ["SLE", "Structured Liberal Education"],
        ["THINK", "Thinking Matters"],
        ["UAR", "Undergraduate Advising and Research"],
        ["PWR", "Writing & Rhetoric, Program in"]
    ],
    "Law School": [
        ["LAW", "Law"],
        ["LAWGEN", "Law, Nonprofessional"]
    ],
    "Graduate School of Business": [
        ["ACCT", "Accounting"],
        ["ALP", "Action Learning Programs"],
        ["BUSGEN", "Business General Pathways"],
        ["MGTECON", "Economic Analysis & Policy"],
        ["FINANCE", "Finance"],
        ["GSBGEN", "GSB General & Interdisciplinary"],
        ["GSBGID", "GSB Interdisciplinary"],
        ["HRMGT", "Human Resource Management"],
        ["MKTG", "Marketing"],
        ["OIT", "Operations Information & Technology"],
        ["OB", "Organizational Behavior"],
        ["POLECON", "Political Economics"],
        ["STRAMGT", "Strategic Management"]
    ],
    "School of Humanities & Sciences": [
        ["AFRICAAM", "African & African American Studies"],
        ["AMELANG", "African & Middle Eastern Languages"],
        ["AMHRLANG", "Amharic"],
        ["ASLLANG", "American Sign Language"],
        ["AMSTUD", "American Studies"],
        ["ANTHRO", "Anthropology"],
        ["APPPHYS", "Applied Physics"],
        ["ARABLANG", "Arabic Language"],
        ["ARCHLGY", "Archaeology"],
        ["ARMELANG", "Armenian"],
        ["ARTHIST", "Art History"],
        ["ARTSTUDI", "Art Studio"],
        ["ARTSINST", "Arts Institute"],
        ["ASNAMST", "Asian American Studies"],
        ["ASNLANG", "Asian Languages"],
        ["BIO", "Biology"],
        ["BIOHOPK", "Biology/Hopkins Marine"],
        ["BIOPHYS", "Biophysics"],
        ["CATLANG", "Catalan Language Courses"],
        ["CHEM", "Chemistry"],
        ["CHRKELNG", "Cherokee"],
        ["CHILATST", "Chicana/o-Latina/o Studies"],
        ["CHINA", "Chinese"],
        ["CHINLANG", "Chinese Language"],
        ["CLASSICS", "Classics"],
        ["COMM", "Communication"],
        ["COMPLIT", "Comparative Literature"],
        ["CSRE", "Comparative Studies in Race & Ethnicity"],
        ["CZECLANG", "Czech"],
        ["DANCE", "Dance"],
        ["DATASCI", "Data Science"],
        ["DLCL", "Division of Literatures, Cultures, & Languages"],
        ["TAPS", "Drama"],
        ["DUTCHLNG", "Dutch"],
        ["EALC", "East Asian Languages & Cultures"],
        ["EASTASN", "East Asian Studies"],
        ["ECON", "Economics"],
        ["ENGLISH", "English"],
        ["ESOLLANG", "English for Speakers of Other Languages"],
        ["ETHICSOC", "Ethics in Society"],
        ["FEMGEN", "Feminist, Gender and Sexuality Studies"],
        ["FILIPINO", "Filipino"],
        ["FILMEDIA", "Film and Media Studies"],
        ["FILMPROD", "Film Production"],
        ["FINNLANG", "Finnish"],
        ["FRENLANG", "French Language"],
        ["FRENCH", "French Studies"],
        ["GERLANG", "German Language"],
        ["GERMAN", "German Studies"],
        ["GLOBAL", "Global Studies"],
        ["GUJARLNG", "Gujarati"],
        ["HAITLANG", "Haitian Creole"],
        ["HAWALANG", "Hawaiian"],
        ["HEBRLANG", "Hebrew"],
        ["HINDLANG", "Hindi"],
        ["HISTORY", "History"],
        ["HPS", "History & Philosophy of Science"],
        ["HITTLANG", "Hittite"],
        ["HNGRLANG", "Hungarian"],
        ["HUMBIO", "Human Biology"],
        ["HUMRTS", "Human Rights"],
        ["HUMSCI", "Humanities & Sciences"],
        ["HUMCORE", "Humanities Core"],
        ["ILAC", "Iberian & Latin American Cultures"],
        ["IGBOLANG", "Igbo"],
        ["FSI", "Institute for International Studies"],
        ["INTLPOL", "International Policy"],
        ["INTNLREL", "International Relations"],
        ["INUPQLNG", "Inupiaq"],
        ["ITALLANG", "Italian Language"],
        ["ITALIAN", "Italian Studies"],
        ["JAPAN", "Japanese"],
        ["JAPANLNG", "Japanese Language"],
        ["JEWISHST", "Jewish Studies"],
        ["KAZKHLNG", "Kazakh"],
        ["KHMRLANG", "Khmer"],
        ["KINYLANG", "Kinyarwanda"],
        ["KOREA", "Korean"],
        ["KORLANG", "Korean Language"],
        ["LAKOTLNG", "Lakota"],
        ["LATINAM", "Latin American Studies"],
        ["LINGUIST", "Linguistics"],
        ["LUGALANG", "Luganda"],
        ["MLA", "Master of Liberal Arts"],
        ["MCS", "Mathematical & Computational Science"],
        ["MATH", "Mathematics"],
        ["MEDVLST", "Medieval Studies"],
        ["GREELANG", "Modern Greek"],
        ["MTL", "Modern Thought & Literature"],
        ["MUSIC", "Music"],
        ["NAHUALNG", "Nahuatl"],
        ["NATIVEAM", "Native American Studies"],
        ["NVJOLANG", "Navajo"],
        ["OROMLANG", "Oromo"],
        ["PERSLANG", "Persian"],
        ["PHIL", "Philosophy"],
        ["PHYSICS", "Physics"],
        ["POLSHLNG", "Polish"],
        ["POLISCI", "Political Science"],
        ["PORTLANG", "Portuguese Language"],
        ["PSYCH", "Psychology"],
        ["PUBLPOL", "Public Policy"],
        ["PUNJLANG", "Punjabi"],
        ["QUECHLNG", "Quechua"],
        ["RELIGST", "Religious Studies"],
        ["ROMALANG", "Romanian"],
        ["RUSSLANG", "Russian"],
        ["REES", "Russian, East European, & Eurasian Studies"],
        ["SAMOALNG", "Samoan"],
        ["SNSKTLNG", "Sanskrit"],
        ["STS", "Science, Technology, & Society"],
        ["SERBLANG", "Serbian"],
        ["SLAVLANG", "Slavic Language"],
        ["SLAVIC", "Slavic Studies"],
        ["SLVNLANG", "Slovenian"],
        ["SOC", "Sociology"],
        ["SPANLANG", "Spanish Language"],
        ["ILAC", "Spanish, Portuguese, & Catalan Literature"],
        ["SPECLANG", "Special Language Program"],
        ["SIW", "Stanford in Washington"],
        ["STATS", "Statistics"],
        ["SWAHLANG", "Swahili"],
        ["SYMSYS", "Symbolic Systems"],
        ["TAMILANG", "Tamil"],
        ["TAPS", "Theater and Performance Studies"],
        ["TIBETLNG", "Tibetan Language"],
        ["TIGRYLNG", "Tigrinya"],
        ["TURKLANG", "Turkish"],
        ["TWILANG", "Twi"],
        ["UKRNLANG", "Ukrainian"],
        ["URBANST", "Urban Studies"],
        ["URDULANG", "Urdu"],
        ["UYGHLANG", "Uyghur"],
        ["VIETLANG", "Vietnamese"],
        ["YDDSHLNG", "Yiddish"],
        ["YORULANG", "Yoruba"],
        ["YUPKLANG", "Yup'ik"],
        ["ZULULANG", "Zulu"]
    ],
    "Doerr School of Sustainability": [
        ["EARTHSYS", "Earth Systems"],
        ["ENERGY", "Energy Science and Engineering"],
        ["ENVRES", "Environment and Resources"],
        ["EBS", "Environmental Behavioral Science"],
        ["EPS", "Earth and Planetary Sciences"],
        ["ESS", "Earth System Science"],
        ["GEP", "Global Environmental Policy"],
        ["OCEANS", "Oceans"],
        ["GEOPHYS", "Geophysics"],
        ["SUSTAIN", "Sustainability"],
        ["SUST", "Sustainability Science and Practice"]
    ],
    "School of Education": [
        ["EDUC", "Education"]
    ],
    "School of Engineering": [
        ["AA", "Aeronautics & Astronautics"],
        ["BIOE", "Bioengineering"],
        ["CHEMENG", "Chemical Engineering"],
        ["CEE", "Civil & Environmental Engineering"],
        ["CME", "Computational & Mathematical Engineering"],
        ["CS", "Computer Science"],
        ["DESIGN", "Design"],
        ["DESINST", "Design Institute"],
        ["EE", "Electrical Engineering"],
        ["ENGR", "Engineering"],
        ["MS&E", "Management Science & Engineering"],
        ["MATSCI", "Materials Science & Engineer"],
        ["ME", "Mechanical Engineering"],
        ["SCCM", "Scientific Computing & Comput'l Math"]
    ],
    "Department of Athletics, Physical Education and Recreation": [
        ["ATHLETIC", "Athletics and Club Sports"],
        ["HORSE", "Horse"],
        ["KIN", "Kinesiology"],
        ["OUTDOOR", "Outdoor Education"],
        ["PHYSWELL", "Physical Wellness"]
    ],
    "School of Medicine": [
        ["ANES", "Anesthesia"],
        ["BIOC", "Biochemistry"],
        ["BIODS", "Biomedical Data Science"],
        ["BIOMEDIN", "Biomedical Informatics"],
        ["BMP", "Biomedical Physics"],
        ["BIOS", "Biosciences Interdisciplinary"],
        ["CBIO", "Cancer Biology"],
        ["CTS", "Cardiothoracic Surgery"],
        ["CSB", "Chemical & Systems Biology"],
        ["CHPR", "Community Health and Prevention Research"],
        ["COMPMED", "Comparative Medicine"],
        ["DERM", "Dermatology"],
        ["DBIO", "Developmental Biology"],
        ["EMED", "Emergency Medicine"],
        ["EPI", "Epidemiology"],
        ["FAMMED", "Family and Community Medicine"],
        ["GENE", "Genetics"],
        ["HRP", "Health Research & Policy"],
        ["IMMUNOL", "Immunology"],
        ["LEAD", "Leadership Innovations"],
        ["LIFE", "Lifeworks"],
        ["MED", "Medicine"],
        ["INDE", "Medicine Interdisciplinary"],
        ["MI", "Microbiology & Immunology"],
        ["MCP", "Molecular & Cellular Physiology"],
        ["NBIO", "Neurobiology"],
        ["NENS", "Neurology & Neurological Sciences"],
        ["NEPR", "Neurosciences Program"],
        ["NSUR", "Neurosurgery"],
        ["OBGYN", "Obstetrics & Gynecology"],
        ["OPHT", "Ophthalmology"],
        ["ORTHO", "Orthopedic Surgery"],
        ["OTOHNS", "Otolaryngology"],
        ["PATH", "Pathology"],
        ["PEDS", "Pediatrics"],
        ["PAS", "Physician Assistant Studies"],
        ["PSYC", "Psychiatry"],
        ["RADO", "Radiation Oncology"],
        ["RAD", "Radiology"],
        ["SOMGEN", "School of Medicine General"],
        ["STEMREM", "Stem Cell Biology and Regenerative Medicine"],
        ["SBIO", "Structural Biology"],
        ["SURG", "Surgery"],
        ["UROL", "Urology"],
        ["WELLNESS", "Wellness Education"]
    ]
};

export const FILTERING_UNITS = ["1", "2", "3", "4", "5", "5<"];

export const GER_LIST = [
    "WAY-A-II",
    "WAY-AQR",
    "WAY-CE",
    "WAY-EDP",
    "WAY-ER",
    "WAY-FR",
    "WAY-SI",
    "WAY-SMA",
    "Writing 1",
    "Writing 2",
    "Writing SLE",
    "College",
    "Language"
    ];

export const DAY_LIST = ["M", "T", "W", "T", "F", "S", "S"];
export const DAY_FILTERS = ["onMon", "onTue", "onWed", "onThu", "onFri", "onSat", "onSun"];

export const COMPONENT_LONG_LIST = ["Lecture", "Seminar", "Discussion Section", "Laboratory", "Lab Section", "Activity", "Case Study", "Colloquium", "Workshop", "Independent Study", "Intro Dial, Sophomore", "Intro Sem, Freshman", "Intro Sem, Sophomore", "Internship", "Arts Intensive Program", "Language", "Clerkship", "Practicum", "Practicum", "Research", "Sophomore College", "Thesis/Dissertation"];
export const COMPONENT_SHORT_LIST = ["LEC", "SEM", "DIS", "LAB", "LBS", "ACT", "CAS", "COL", "WKS", "INS", "IDS", "ISF", "ISS", "ITR", "API", "LNG", "CLK", "PRA", "PRC", "RES", "SCS", "T/D"];

export const CAREER_LIST = ["Undergraduate", "Graduate", "Graduate School of Business", "Law School", "Medical School"];
export const CAREER_SHORT = ["UG", "GR", "GSB", "LAW", "MED"];

export const FILTER_TITLES = ["Quarter Offered", "Units", "GERs", "Days", "Components", "Mean Hours", "Career", "Final Exam", "Minimum Median Grade"];

export const FINAL_LIST = ["No preference", "Final", "No final"];
export const GRADE_COLUMNS = ["A+","A","A-","B+","B","B-","C+","C","C-","D+","D","D-","F","CR","NC"];
export const GRADE_COLUMN_REVERSED = ["NC","CR","F","D-","D","D+","C-","C","C+","B-","B","B+","A-","A","A+"];


export const QUARTERS = ["AUT", "WIN", "SPR", "SUM"];
export const QUARTERS_LONG = ["Autumn", "Winter", "Spring", "Summer"];

export const meanHoursMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 40,
    label: '>40',
  },
];