import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as Sentry from "@sentry/react";


Sentry.init({
	dsn: "https://db7cefa8873770806644bc27202d1211@o4506401894629376.ingest.sentry.io/4506401895940096",
	integrations: [
	  new Sentry.BrowserTracing({
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // tracePropagationTargets: ["localhost", /^https:\/\/oncourse\.college/],

    tracePropagationTargets: [/^https:\/\/oncourse\.college/], // removed localhost for testing doesn't work?


	  }),
	  new Sentry.Replay({
      // remove me later, this is for debuggging issues with sentry
      networkDetailAllowUrls: [window.location.origin], // allow all urls to be captured in detai;
    }),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
