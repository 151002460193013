import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fireBaseAuth } from "../config/firebase";
import { signOut, setPersistence, signInWithRedirect, GoogleAuthProvider, getRedirectResult, signInWithPopup, browserLocalPersistence, onAuthStateChanged } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';

setPersistence(fireBaseAuth, browserLocalPersistence)

const provider = new GoogleAuthProvider();
provider.setCustomParameters({'hd': 'stanford.edu'})

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  onAuthStateChanged(fireBaseAuth, (user) => {
    if (user) {
      var distilledUser = {"email": user.email, "displayName": user.displayName, "photoURL": user.photoURL};
      localStorage.setItem("user", JSON.stringify(distilledUser));
    }
  });

  // call this function when you want to authenticate the user
  const login = async () => {
    try {
      // await signInWithRedirect(fireBaseAuth, provider);
      // const result = await getRedirectResult(fireBaseAuth);
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // const curUser = result.user;
      await signInWithPopup(fireBaseAuth, provider);
      // await signInWithRedirect(fireBaseAuth, provider);
      const result = await getRedirectResult(fireBaseAuth);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
    } catch (error) {
      console.log(error);
      navigate("/login", { replace: true });
    }
  };
  

  // call this function to sign out logged in user
  const logout = () => {
    signOut(fireBaseAuth);
    navigate("/login", { replace: true });
  };

  return <AuthContext.Provider value={{
      login,
      logout
    }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};