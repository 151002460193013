import SignIn from '../components/Google.js';
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useProfile } from '../helpers/ProfileContext.js';
import { Navigate } from "react-router-dom";
import { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import { fireBaseAuth } from "../config/firebase";
import SigningIn from '../components/SigningIn.js';

function Login() {
    const { login, logout } = useAuth();
    const [user, loading, error] = useAuthState(fireBaseAuth);
    if (loading) {
        return <SigningIn/>;
    }
    return (
        <div className="font-sans text-left space-y-1.5 w-screen h-screen dark:bg-zinc-800">
        <div className="w-full h-full p-4 rounded-md text-center flex flex-col justify-center">
            <div className="w-2/3 h-1/2 flex flex-col justify-center bg-gradient-to-b from-[#1499f7] via-[#8A2BE2] to-[#FF69B4] rounded-xl self-center p-1">
                <div className="w-full h-full flex flex-col justify-center self-center dark:bg-gray-900 bg-white rounded-xl">
                    <h1 className="w-full mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">Welcome to <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#1499f7] via-[#8A2BE2] to-[#FF69B4]">OnCourse</span></h1>

                    <p className="w-full mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">OnCourse is a Stanford course planning application</p>
                    <div className="w-60 self-center">
                    <button onClick={login} className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                        Sign in
                        <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                    </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Login;