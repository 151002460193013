import React, {useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import CatalogNavbar from '../components/Navbars/CatalogNavbar'
import { DarkModeProvider, useDarkMode } from '../helpers/DarkModeContext';
import { useIsMobile } from "../layouts/Mobile";
import profileImg from '../assets/instructor-profile.png';
import { QUARTER_LONG_LIST, INSTRUCTOR_PATH } from '../constants/Constants';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { scoreToColor } from '../components/InstructorCurrent';
import {db} from "../config/firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";

import {
    IsMobile,
} from '../helpers/Contexts';

function InstructorView() {
        // Use useSearchParams to get the query parameters
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');

    var [isMobile, setIsMobile] = useState(useIsMobile());
    const mobileClass = isMobile ? 'mobile' : 'desktop';
    const isDarkMode = useState(false);//useDarkMode();
    const [data, setData] = useState({});
    const [years, setYears] = useState({});

    useEffect(() => {
        if (!id) return;

        const docRef = doc(db, INSTRUCTOR_PATH, id)

        getDoc(docRef)
        .then((doc) => {
            if (doc.exists()) {
                setData(doc.data())
                getYearData(doc.data().classes);
            }
        })
        .catch((error) => {
            console.error("Error getting document:", error);
        });

    }, [id])

    function getYearData(classes) {
        if (classes === undefined) return;
        // split the clases data by year
        var years = {}
        classes.forEach(c => {
            const split = c.flow.split('-');
            const flow_year = split[0]
            const flow_quarter = parseInt(split[1])

            if (!(flow_year in years)) {
                years[flow_year] = [ [], [], [], [] ]
            }

            years[flow_year][flow_quarter].push(c)
        })

        setYears(years);
    }


    function processedTerm(rating, year, qtr) {
        const quarter = QUARTER_LONG_LIST[qtr];
        if (qtr !== 0) {
            year = parseInt(year) + 1;
        }
        return rating.toFixed(1) + "/5 - " + quarter + " " + year;
    }


    const yearRow = (year, classesForYear) => {
        return (
        <div className='w-full mt-4'>
            <h2 className='font-bold text-xl text-center text-gray-800 dark:text-white'>
                {year}-{parseInt(year)+1}
            </h2>
            <div className='flex flex-row space-x-2 w-[24%] mt-2'>
                {classesForYear.map((q, i) => {
                    return (
                        <div className={`transition-all transition-duration-300 bg-slate-50 dark:bg-[#1c1c1c] shadow-md rounded-lg shadow-blue-500/40 
                        dark:shadow-blue-800/20 w-full border dark:border-slate-700`}
                            style={{
                                flex: `1 0 auto`
                            }}>
                            <div className={`rounded-lg bg-white dark:bg-zinc-900 
                            px-2 border-b-[1px] dark:border-b-slate-700 relative py-1 cursor-pointer`}>
                                <span className="relative left-0 text-sm text-left font-bold text-gray-900 dark:text-white">
                                    {QUARTER_LONG_LIST[i]}
                                </span>
                            </div>
                            <div className="flex flex-col mb-1" style={{ flex: '1 1 auto' }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1 0 auto"
                                }}>
                            
                                 {
                                    q.map((item, index) => {
                                        return (
                                        <a href={`./${item.code}`} className="flex border-solid border-slate-300/40 dark:border-slate-400/40 border-2 rounded-lg justify-between 
                                        bg-slate-50 my-0.5 mx-1 p-0.5 dark:bg-zinc-900 z-10 pointer-cursor h-8 items-center"
                                            data-tooltip-id="instructor-tip-id" data-tooltip-html={`<b>${item.code}</b>: ${item.title}<br/><b>Role</b>: ${item.role}${item.rating ? `. <b>Rating</b>: ${item.rating}/5`: ""}`}>
                                            <p className="w-full px-1 text-sm truncate dark:text-white text-left"><span className="font-bold">{item.code}</span>:{" "}
                                            {item.title}</p>
                                            { item.rating &&
                                            <div style={{ backgroundColor: scoreToColor(item.rating) }} 
                                                data-tooltip-id="instructor-tip-id" data-tooltip-content={processedTerm(item.rating, year, i)} //alt={processedTerm(terms[index])}
                                                className={"shadow-sm w-10 h-6 rounded-md text-white flex items-center justify-center text-sm font-bold"}
                                                key={index}>
                                                    {item.rating.toFixed(1)}
                                                </div>
                                            }
                                        </a>
                                        )
                                    })
                                 }
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

        </div>
        )
    }

    return (
        <DarkModeProvider value={{isDarkMode}}>
            <IsMobile.Provider value={{isMobile, setIsMobile}}>
            <div className="flex flex-col overflow-hidden">
                <CatalogNavbar/>
                <Tooltip id="instructor-tip-id" className='z-20' opacity="1"
                    style={{ backgroundColor: "rgb(59, 130, 246)", color: "#222" }}/>
                
                <div className={"flex dark:bg-zinc-900 dark:text-white items-center justify-center pt-14 pb-5 " + mobileClass}>
                    <div className='w-4/5 space-y-4'>

                        <div className={`w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 cursor-default transition-all transition-duration-300`}>
                            <div className="px-5 py-3.5">

                                {/* LEFT */}
                                <div className='w-full flex-col items-center justify-center '>
                                    {/* border-gray-900 border-2 rounded-md */}
                                    <div className='flex justify-center'> {/* Centering the image horizontally */}
                                        <img src={profileImg} className='w-28 h-28 p-5'/>
                                    </div>
                                    <h2 className='pt-1 text-2xl font-medium pb-2'>
                                        {data.fullName}
                                    </h2>
                                    <a className='text-lgfont-medium text-blue-500' href={`mailto:${id}@stanford.edu`}>
                                        {id}@stanford.edu
                                    </a>
                                </div>
                                
                                {
                                    years && Object.keys(years).reverse().map(year => {
                                        return yearRow(year, years[year]);
                                    })
                                }

                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            </IsMobile.Provider>
        </DarkModeProvider>
    )
}

export default InstructorView