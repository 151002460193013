import React from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import {QUARTER_LONG_LIST} from "../constants/Constants.js";

const MIN = 3.5;
const MAX = 5;
export function scoreToColor(score) {
    // Ensure score is within bounds
    score = Math.min(MAX, Math.max(MIN, score));

    // Scores above 4.8 are green
    if (score > 4.8) {
        return 'hsl(120, 70%, 50%)'; // green with lowered saturation
    }
    // Scores below 2.5 are red
    else if (score < MIN) {
        return 'hsl(0, 70%, 50%)'; // red with lowered saturation
    }
    else {
        // Map score to hue (0 - red, 120 - green)
        let hue = ((score - MIN) / (MAX - MIN)) * 120;
  
        // Return hsl color string
        return `hsl(${hue}, 70%, 50%)`; // color with lowered saturation
    }
}

function processedTerm(rating, term) {
    const split = term.split("-");

    var [year, qtr] = split;
    // const quarter = QUARTER_SHORT_TO_LONG[term.substring(4, 7)];
    if (parseInt(qtr) > 0) {
        year = parseInt(year) + 1;
    }
    const quarterStr = QUARTER_LONG_LIST[qtr];
    return rating.toFixed(1) + "/5 - " + quarterStr + " " + year;
}

function truncateName(name) {
    const words = name.split(" ");
    if (words.length < 2) {
        return name;
    }
    return words[0][0] + ". " + words[1];
}

function ratingElements(name, ratings, terms, id, isMobile) {
    const MAX_RATINGS = isMobile ? 3: 5;

    if (ratings.length > MAX_RATINGS) {
        ratings = ratings.slice(0, MAX_RATINGS);
    }

    return (
        <div className="flex justify-between items-center">
            <Tooltip id="tool-tip-id"/>
            <a className='text-blue-500 cursor-pointer' href={`./instructor?id=${id}`}>
                {isMobile ? truncateName(name): name}
            </a>
            <div className="flex justify-end space-x-1 pb-1">
            {/* add tooltip of term */}
            {
                ratings.map((rating, index) => (
                    <div style={{ backgroundColor: scoreToColor(rating) }} 
                    data-tooltip-id="tool-tip-id" data-tooltip-content={processedTerm(rating, terms[index])} //alt={processedTerm(terms[index])}
                    className={"shadow-sm w-14 h-7 rounded-md text-white flex items-center justify-center"}
                    key={index}>
                        {ratings[index].toFixed(1)}
                    </div>
                ))
            }

            {/* <Tooltip content={ratings[i][1]}> */}
            {/* </Tooltip> */}
            </div>
        </div>
    );
}

function InstructorCurrent(props) {
    const {name, ratings, terms, id, isMobile} = props;
    return (
        <div className='pl-1'>
            {
                ratingElements(name, ratings, terms, id, isMobile)
            }
        </div>
    )
}

export default InstructorCurrent