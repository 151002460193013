import React from 'react'
import { useContext, useState, useRef, useEffect } from 'react'
import { CourseCode, CurrentFlow } from '../helpers/Contexts';
import { descriptionofGER, styleForGER, processedGERs } from "../styles/GERStyling.js";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
// import { calculateMedian, percentageAs} from '../helpers/GradeHelpers';
import { COURSES_PATH, DEFAULT_COURSE } from "../constants/Constants.js";
import {processClassSearch, getDocsFromQuery} from "../helpers/SearchProcessing";
import { getCourse } from "../Cache/CourseCaching";
import { doc, getDoc } from "firebase/firestore";
import {db} from "../config/firebase";
import { courseConverter } from "../adapters/CourseAdapters";

function Comparison(props) {
    const {courseCode, setCourseCode} = useContext(CourseCode);
    var {currentFlow, setCurrentFlow} = useContext(CurrentFlow);
    const {course} = props;

    const rows = ["Title", "Units", "UG Reqs", "Median Hours", "Mean Hours", "Grading", "Median Grade", "% A/A+", "Quarters"];

    function formatUnits(unitsMin, unitsMax) {
        if (unitsMin === unitsMax) {
            return unitsMin;
        } else {
            return unitsMin + "-" + unitsMax;
        }
    }
    const curentCourseData = [course.title, 
        formatUnits(course.unitsMin, course.unitsMax), 
        course.gers, 
        course.medianHours, 
        course.meanHours, 
        course.grading, 
        course.medianGrade, 
        course.percentAs, 
        course.quartersOffered];
    
    // State to control the dropdown visibility
    var [searchItems, setSearchItems] = useState([]);

    var [comparisonCourseCode1, setComparisonCourseCode1] = useState(undefined);
    var [comparisonCourse1, setComparisonCourse1] = useState(undefined);
    var [comparisonCourseData1, setComparisonCourseData1] = useState([undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]);
    // const [searchText1, setSearchText1] = useState('');

    var [comparisonCourseCode2, setComparisonCourseCode2] = useState(undefined);
    var [comparisonCourse2, setComparisonCourse2] = useState(undefined);
    var [comparisonCourseData2, setComparisonCourseData2] = useState([undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]);
    // const [searchText2, setSearchText2] = useState('');

    // const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [searchTexts, setSearchTexts] = useState(['', '']);
    const [searchesIsOpen, setSearchesIsOpen] = useState([false, false]);
    const [searchBarRefs, setSearchBarRefs] = useState([useRef(null), useRef(null)]);
    // const searchBarRef = useRef(null);

    useEffect(() => {
      if (comparisonCourse1 === undefined) return;
      
      setComparisonCourseData1([comparisonCourse1.title, 
        formatUnits(comparisonCourse1.unitsMin, comparisonCourse1.unitsMax), 
        comparisonCourse1.gers, 
        comparisonCourse1.medianHours, 
        comparisonCourse1.meanHours, 
        comparisonCourse1.grading, 
        comparisonCourse1.medianGrade, 
        comparisonCourse1.percentAs, 
        comparisonCourse1.quartersOffered]);
    }, [comparisonCourse1]);

    useEffect(() => {
      if (comparisonCourseCode1 === undefined) {
          return;
      }
  
      // check for cached course data
      const cachedCourse = getCourse(currentFlow, comparisonCourseCode1);
      if (cachedCourse !== null) {
        setComparisonCourse1(cachedCourse);
      } else {
        const docRef = doc(db, COURSES_PATH, `${currentFlow[0]}`, `${comparisonCourseCode1}`, "meta").withConverter(courseConverter);
        
        getDoc(docRef)
          .then(docSnap => {
              const courseData = docSnap.data();
              setComparisonCourse1(courseData);
          })
      }
    }, [comparisonCourseCode1]); // Dependency array


    // Comparison Course 2
    useEffect(() => {
      if (comparisonCourse2 === undefined) return;
      
      setComparisonCourseData2([comparisonCourse2.title, 
        formatUnits(comparisonCourse2.unitsMin, comparisonCourse2.unitsMax), 
        comparisonCourse2.gers, 
        comparisonCourse2.medianHours, 
        comparisonCourse2.meanHours, 
        comparisonCourse2.grading, 
        comparisonCourse2.medianGrade, 
        comparisonCourse2.percentAs, 
        comparisonCourse2.quartersOffered]);
    }, [comparisonCourse2]);

    useEffect(() => {
      if (comparisonCourseCode2 === undefined) {
          return;
      }
  
      // check for cached course data
      const cachedCourse = getCourse(currentFlow, comparisonCourseCode2);
      if (cachedCourse !== null) {
        setComparisonCourse2(cachedCourse);
      } else {
        const docRef = doc(db, COURSES_PATH, `${currentFlow[0]}`, `${comparisonCourseCode2}`, "meta").withConverter(courseConverter);
        
        getDoc(docRef)
          .then(docSnap => {
              const courseData = docSnap.data();
              setComparisonCourse2(courseData);
          })
      }
    }, [comparisonCourseCode2]); // Dependency array

    // handle Key Change
    const handleChange = (event) => {
        const searchTerm = event.target.value;
        const tag = parseInt(event.target.id[event.target.id.length - 1]);

        // setSearchText1(searchTerm);
        setSearchTexts(searchTexts => {
          const newSearchTexts = [...searchTexts];
          newSearchTexts[tag] = searchTerm;
          return newSearchTexts;
        })

        if (searchTerm === "") {
            setSearchItems([]);
            // setSearchIsOpen(false);
            setSearchesIsOpen(searchesOpen => {
              const newSearchesOpen = [...searchesOpen];
              newSearchesOpen[tag] = false;
              return newSearchesOpen;
            })

            return;
        } else {
            if (!searchesIsOpen[tag]) {
                // setSearchIsOpen(true);
                setSearchesIsOpen(searchesOpen => {
                  const newSearchesOpen = [...searchesOpen];
                  newSearchesOpen[tag] = true;
                  return newSearchesOpen;
                })
            }

            const queries = processClassSearch(searchTerm);
            
            Promise.all(queries.map(q => getDocsFromQuery(q)))
                .then(resultsArrays => {
                    const combinedResults = [].concat(...resultsArrays);

                    setSearchItems(combinedResults);
                })
                .catch(error => {
                    console.error("Error getting documents: ", error);
                });
        }
    };

    const handleKeyDown = (event) => {
        const tag = parseInt(event.target.id[event.target.id.length - 1]);

        if (event.key === 'Enter') {
            // Process input value
            var inputCode = event.target.value.replaceAll(" ", "").toUpperCase();
            if (inputCode !== "") {
                // setSearchIsOpen(false);
                setSearchesIsOpen(searchesOpen => {
                  const newSearchesOpen = [...searchesOpen];
                  newSearchesOpen[tag] = false;
                  return newSearchesOpen;
                })
                if (tag === 0) {
                    setComparisonCourseCode1(inputCode);
                } else {
                    setComparisonCourseCode2(inputCode);
                }
                // setComparisonCourseCode(inputCode);
                searchBarRefs[tag].current.value = '';
                // searchBarRef.current.value = '';
            }
        }
    };


    useEffect(() => {
        // Function to handle the click event
        function handleClickOutside(event) {
            const searchBar0 = document.getElementById('search-navbar-0');
            const searchBar1 = document.getElementById('search-navbar-1');

            if (searchBarRefs[0].current && !searchBarRefs[0].current.contains(event.target) && event.target !== searchBar0) {
                // setSearchIsOpen(false);
                setSearchesIsOpen(searchesOpen => {
                  const newSearchesOpen = [...searchesOpen];
                  newSearchesOpen[0] = false;
                  return newSearchesOpen;
                })
            }

            if (searchBarRefs[1].current && !searchBarRefs[1].current.contains(event.target) && event.target !== searchBar1) {
                // setSearchIsOpen(false);
                setSearchesIsOpen(searchesOpen => {
                  const newSearchesOpen = [...searchesOpen];
                  newSearchesOpen[1] = false;
                  return newSearchesOpen;
                })
            }
        }

        // Attach the click event listener
        document.addEventListener("click", handleClickOutside);
        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [searchBarRefs]);


    function SEARCH_BAR (index) {
      return (
      <div ref={searchBarRefs[index]} className="w-full">
          <input type="text" id={`comparison-navbar-${index}`} data-dropdown-toggle="dropdownSearch" 
          className="block w-full p-2 text-sm text-gray-900 border border-gray-300 
          rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 
          dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-medium
          text-center" value={searchTexts[index]} tag={index}
          placeholder="Enter class to compare..." onChange={handleChange} onKeyDown={handleKeyDown} />

          { searchesIsOpen[index] ?
          <div id="dropdownSearch"
          className={`absolute w-[150%] mt-0 z-10 bg-white divide-gray-100 rounded-md shadow w-44 dark:bg-zinc-800 
          left-1/2 transform -translate-x-1/2`}>
              {searchItems.map((item) => (
                  <div className="pl-3 py-3 text-sm text-left text-gray-900 dark:text-white cursor-pointer hover:dark:bg-zinc-600 rounded-md"
                  key={item.code}
                  onClick={
                      () => {
                          // setSearchIsOpen(false);
                          setSearchesIsOpen(searchesOpen => {
                            const newSearchesOpen = [...searchesOpen];
                            newSearchesOpen[index] = false;
                            return newSearchesOpen;
                          })

                          if (index === 0) {
                            setComparisonCourseCode1(item.code);
                          } else {
                            setComparisonCourseCode2(item.code);
                          }
                          // setComparisonCourseCode1(item.code);
                          
                          setSearchTexts(searchTexts => {
                            const newSearchTexts = [...searchTexts];
                            newSearchTexts[index] = item.code;
                            return newSearchTexts;
                          })
                          // setSearchText(item.code);
                          // searchBarRef.current.value = '';
                      }
                  }>
                      <div className="truncate line-clamp-1 block">
                          <span className="font-semibold">{item.code}: </span>
                          {item.title}
                      </div>
                  </div>
              ))}
          </div> : null
          }
      </div>);
  };

  function hoursDeltaText(base, comp) {
    if (comp === undefined ) return "-";

    if (base !== undefined) {
      const delta = comp - base;
      if (delta === 0) return (
        <span > {comp} <span className={`pl-2 text-sm text-gray-500 font-bold`}>{delta.toFixed(2)}</span></span>
      );

      return (
        <span > {comp} <span className={`pl-2 text-sm text-${delta < 0 ? "green": "red"}-500 font-bold`}>{(delta > 0 ? "+":"")}{delta.toFixed(2)}</span></span>
      )
    }
    return comp;
  }
  
  function gradeDeltaText(base, comp) {
    if (comp === undefined ) return "-";

    if (base !== undefined) {
      const baseConverted = parseFloat(base.slice(0, -1));
      const compConverted = parseFloat(comp.slice(0, -1));
      const delta = compConverted - baseConverted;
      if (delta === 0) return (
        <span > {comp} <span className={`pl-2 text-sm text-gray-500 font-bold`}>{delta.toFixed(2)}%</span></span>
      );

      return (
        <span > {comp} <span className={`pl-2 text-sm text-${delta > 0 ? "green": "red"}-500 font-bold`}>{(delta > 0 ? "+":"")}{delta.toFixed(2)}%</span></span>
      )
    }
    return comp;

  }

  return (
    <div className="p-4 shadow-xl shadow-blue-500/40 rounded-md bg-white dark:bg-zinc-800">
        <h1 className="font-bold text-2xl pb-4">Compare</h1>
        {/* <div className="flex space-x-4">
            <div className="w-1/2 px-2 py-2 rounded-md border-2 border-blue-300 items-center">
                <h1 className="font-bold text-2xl pb-2 w-full text-center text-blue-500">{courseCode}</h1>
            </div>

            <div className="w-1/2 px-2 py-2 rounded-md border-2 border-blue-300 items-center">
                <h1 className="font-bold text-2xl pb-2 w-full text-center text-blue-500">{courseCode}</h1>
            </div>

        </div> */}


        {/* <div className="flex justify-left"> */}
        <div className="w-full relative overflow-x-auto shadow-md rounded-lg">
            <table className="w-full text-md text-center rtl:text-right text-gray-500 dark:text-gray-800 ">
                <thead className="text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3 w-2/12 text-left">
                            
                        </th>
                        <th scope="col" className="px-6 py-3 w-[27.77%]">
                            {courseCode}
                        </th>
                        <th scope="col" className="px-6 py-3 w-[27.77%]">
                          <div className="flex justify-center">
                            <></>
                            <div className="relative hidden md:block w-3/4 ">
                                {SEARCH_BAR(0)}
                            </div>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3w-[27.77%]">
                          <div className="flex justify-center">
                            <></>
                            <div className="relative hidden md:block w-3/4 ">
                                {SEARCH_BAR(1)}
                            </div>
                          </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                  {
                    rows.map((row, i) => (
                      <tr className="
                      bg-white dark:bg-zinc-900 hover:bg-gray-50 dark:hover:bg-gray-800 
                      border-b border-gray-300 dark:border-gray-700 dark:text-gray-300"
                      key={row}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-left 
                        ">
                            {row}
                        </th>
                        <td className="px-6 py-4 ">

                            {(() => {
                                switch (row) {
                                  case "UG Reqs":
                                    return (
                                      <div>
                                        <Tooltip id="curWaysToolTip" className='z-10'/>
                                        <div className="justify-left space-x-1 pb-1">
                                          {
                                            course.gers.length > 0 ?
                                            processedGERs(course.gers).map(ger => (
                                              <p
                                                className={`inline-flex items-center ml-0 px-3 py-1 text-sm font-medium text-center text-white  
                                                            rounded-lg ${styleForGER(ger)}`}
                                                data-tooltip-id="curWaysToolTip"
                                                data-tooltip-content={descriptionofGER(ger)}
                                              >
                                                {ger}
                                              </p>
                                            ))
                                            : "-"
                                          }
                                        </div>
                                      </div>
                                    );
                                    case "Quarters":
                                        return (
                                        course.qtrDesc !== "" ? 
                                            course.qtrDesc
                                        : `Not offered in ${currentFlow[0]}`)
                                    
                                    default:
                                        return curentCourseData[i] !== undefined ?
                                            curentCourseData[i]
                                            : "-";
                                }
                              })()}
                        </td>
                        <td className="px-6 py-4">
                            
                          {
                          comparisonCourseCode1 === undefined || comparisonCourse1 === undefined ? "":
                          (() => {
                              switch (row) {
                                case "UG Reqs":
                                  return (
                                    <div>
                                      <Tooltip id="curWaysToolTip" className='z-10'/>
                                      <div className="justify-left space-x-1 pb-1">
                                        {
                                          comparisonCourse1.gers.length > 0 ?
                                          processedGERs(comparisonCourse1.gers).map(ger => (
                                            <p
                                              className={`inline-flex items-center ml-0 px-3 py-1 text-sm font-medium text-center text-white  
                                                          rounded-lg ${styleForGER(ger)}`}
                                              data-tooltip-id="curWaysToolTip"
                                              data-tooltip-content={descriptionofGER(ger)}
                                            >
                                              {ger}
                                            </p>
                                          ))
                                          : "-"
                                        }
                                      </div>
                                    </div>
                                  );
                                  case "Quarters":
                                      return (
                                        comparisonCourse1.qtrDesc !== "" ? 
                                          comparisonCourse1.qtrDesc
                                      : `Not offered in ${currentFlow[0]}`)
                                    
                                  // DELTAS
                                  case "Median Hours":
                                  case "Mean Hours":
                                    return hoursDeltaText(curentCourseData[i], comparisonCourseData1[i]);

                                  case "% A/A+":
                                    return gradeDeltaText(curentCourseData[i], comparisonCourseData1[i]);

                                  case "Title":
                                    return comparisonCourseData1[i] !== undefined ? 
                                        <a onClick={() => setCourseCode(comparisonCourseCode1)} className='cursor-pointer text-blue-500'>
                                          {comparisonCourseData1[i]}
                                        </a>
                                    : "-";

                                  default:
                                      return comparisonCourseData1[i] !== undefined ?
                                        comparisonCourseData1[i]
                                          : "-";
                              }
                            })()}


                            
                        </td>

                        <td className="px-6 py-4">
                            
                          {
                          comparisonCourseCode2 === undefined || comparisonCourse2 === undefined ? "":
                          (() => {
                              switch (row) {
                                case "UG Reqs":
                                  return (
                                    <div>
                                      <Tooltip id="curWaysToolTip" className='z-10'/>
                                      <div className="justify-left space-x-1 pb-1">
                                        {
                                          comparisonCourse2.gers.length > 0 ?
                                          processedGERs(comparisonCourse2.gers).map(ger => (
                                            <p
                                              className={`inline-flex items-center ml-0 px-3 py-1 text-sm font-medium text-center text-white  
                                                          rounded-lg ${styleForGER(ger)}`}
                                              data-tooltip-id="curWaysToolTip"
                                              data-tooltip-content={descriptionofGER(ger)}
                                            >
                                              {ger}
                                            </p>
                                          ))
                                          : "-"
                                        }
                                      </div>
                                    </div>
                                  );
                                  case "Quarters":
                                      return (
                                        comparisonCourse2.qtrDesc !== "" ? 
                                          comparisonCourse2.qtrDesc
                                      : `Not offered in ${currentFlow[0]}`)

                                    // DELTAS
                                    case "Median Hours":
                                    case "Mean Hours":
                                      return hoursDeltaText(curentCourseData[i], comparisonCourseData2[i]);
  
                                    case "% A/A+":
                                      return gradeDeltaText(curentCourseData[i], comparisonCourseData2[i]);
                                      
                                    case "Title":
                                      return comparisonCourseData2[i] !== undefined ? 
                                      <a onClick={() => setCourseCode(comparisonCourseCode2)} className='cursor-pointer text-blue-500'>
                                            {comparisonCourseData2[i]}
                                          </a>
                                      : "-";
                                  default:
                                      return comparisonCourseData2[i] !== undefined ?
                                        comparisonCourseData2[i]
                                          : "-";
                              }
                            })()}


                            
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
            </table>
        {/* </div> */}
      </div>

    </div>
  )
}

export default Comparison