import CourseMeta from "../data/CourseMeta"

export const QUARTER_SHORT_TO_LONG = {
  Aut: "Autumn",
  Win: "Winter",
  Spr: "Spring",
  Sum: "Summer",
}
export const QUARTER_SHORTENED_LIST = ["Aut", "Win", "Spr", "Sum"]
export const QUARTER_LONG_LIST = ["Autumn", "Winter", "Spring", "Summer"]
export const DAY_LIST = ["M", "T", "W", "T", "F"]
export const CATALOG_YEAR = 2024

// Latest Year in DB
export const LATEST_YEAR_IN_DB = 2024

export const APP_VERSION = "1.2.2"
export const METRICS_VERSION = "1.2.0"
export const REVIEW_VERSION = "1.2.0"

// VERSION
export const COURSES_PATH = "Courses2"
export const SEARCH_PATH = "Search2"
export const INSTRUCTOR_PATH = "Instructors"
export const REVIEW_PATH = "Reviews2"
export const PROGRAMS_PATH = "Programs2"

export const GER_LIST = [
  "WAY-A-II",
  "WAY-AQR",
  "WAY-CE",
  "WAY-EDP",
  "WAY-ER",
  "WAY-FR",
  "WAY-SI",
  "WAY-SMA",
  "Writing 1",
  "Writing 2",
  "College",
  "Language",
]
export const GER_SLE_LIST = [
  "WAY-AQR",
  "WAY-CE",
  "WAY-FR",
  "WAY-SI",
  "WAY-SMA",
  "Writing SLE",
  "Language",
]

export const GER_DESCRIPTIONS = {
  "WAY-A-II": "Aesthetic and Interpretive Inquiry",
  "WAY-AQR": "Applied Quantitative Reasoning",
  "WAY-CE": "Creative Expression",
  "WAY-EDP": "Exploring Diversity and Power",
  "WAY-ER": "Ethical Reasoning",
  "WAY-FR": "Formal Reasoning",
  "WAY-SI": "Social Inquiry",
  "WAY-SMA": "Scientific Method and Analysis",
  "Writing 1": "Writing 1",
  "Writing 2": "Writing 2",
  "Writing SLE": "Writing SLE",
  Language: "Language",
  College: "College",
}

export const GER_REQUIREMENTS = {
  "WAY-A-II": { units: 6, courses: 2 }, // 6 units, 2 courses
  "WAY-AQR": { units: 3, courses: 1 },
  "WAY-CE": { units: 2, courses: 1 },
  "WAY-EDP": { units: 3, courses: 1 },
  "WAY-ER": { units: 3, courses: 1 },
  "WAY-FR": { units: 3, courses: 1 },
  "WAY-SI": { units: 6, courses: 2 },
  "WAY-SMA": { units: 6, courses: 2 },
  "Writing 1": { units: 0, courses: 1 }, // if it's 0, don't display
  "Writing 2": { units: 0, courses: 1 },
  "Writing SLE": { units: 24, courses: 3 },
  Language: { units: 0, courses: 3 },
  College: { units: 0, courses: 2 },
}

export function gerColor(req) {
  if (req.startsWith("WAY")) {
    return "bg-blue-200/50 dark:bg-blue-800"
  } else if (req.startsWith("Writing")) {
    return "bg-green-200 dark:bg-green-800"
  } else if (req.startsWith("College")) {
    return "bg-yellow-200 dark:bg-yellow-500"
  } else {
    return "bg-blue-200 dark:bg-blue-800"
  }
}

export function gerBasicColor(req) {
  if (req.startsWith("WAY")) {
    return "purple"
  } else if (req.startsWith("Writing")) {
    return "green"
  } else if (req.startsWith("College")) {
    return "orange"
  } else {
    return "blue"
  }
}

export const PROGRAM_COLORS = ["purple", "green", "orange", "blue", "red", "yellow"]

export function programColor(index) {
  return PROGRAM_COLORS[index % PROGRAM_COLORS.length]
}

export const QUARTER_SCHEDULES = {
  // first date is the first monday of the quarter
  // second date is the start of the quarter,
  // third date is the end of the quarter
  "2023-0": ["2023-09-25", "2023-09-26", "2023-12-08"],
  "2023-1": ["2024-01-08", "2024-01-08", "2024-03-15"],
  "2023-2": ["2024-04-01", "2024-04-01", "2024-06-05"],
  "2023-3": ["2024-06-24", "2024-06-24", "2024-08-15"],
  "2024-0": ["2024-09-23", "2024-09-23", "2024-12-06"],
  "2024-1": ["2025-01-06", "2025-01-06", "2025-03-14"],
  "2024-2": ["2025-03-31", "2025-03-31", "2025-06-04"],
  "2024-3": ["2025-06-23", "2025-06-23", "2025-08-14"],
}

// ---------

// DEFAULT INTIIALIZATIONS

export const DEFAULT_COURSE = new CourseMeta(
  "", // career
  "", // code
  "", // courseCode
  "", // department
  "", // description,
  true, // finalExam
  [], // gers
  "", // grading
  "", // id
  {}, // instructors
  {}, // instructorIDs
  0, // maxTimesRepeated
  "", // medianGrade
  0, // medianHours
  0, // meanHours
  "", // percentAs
  [], // qtrs
  "", // subject
  "", // title
  0, // unitsMin
  0, //unitsMax
  [], // pastReviews
  "", // syllabus
)

// go to nameLookupConcise and copy it here
export const PROGRAM_NAME_TO_LONG = {
  "AA-BS": "Aeronautics and Astronautics (BS)",
  "AA-ENG": "Aeronautics and Astronautics (ENG)",
  "AA-MIN": "Aeronautics and Astronautics (Minor)",
  "AA-MS": "Aeronautics and Astronautics (MS)",
  "AA-PHD": "Aeronautics and Astronautics (PhD)",
  "AA-PMN": "Aeronautics and Astronautics (PhD Minor)",
  "AEPHY-MS": "Applied and Engineering Physics (MS)",
  "AFRAM-BA": "African and African American Studies (BA)",
  "AFRAM-MIN": "African and African American Studies (Minor)",
  "AMSTU-BA": "American Studies (BA)",
  "AMSTU-MIN": "American Studies (Minor)",
  "ANTHR-BA": "Anthropology (BA)",
  "ANTHR-MA": "Anthropology (MA)",
  "ANTHR-MIN": "Anthropology (Minor)",
  "ANTHR-PHD": "Anthropology (PhD)",
  "ANTHR-PMN": "Anthropology (PhD Minor)",
  "APLPH-MS": "Applied Physics (MS)",
  "APLPH-PHD": "Applied Physics (PhD)",
  "ARCHA-BA": "Archaeology (BA)",
  "ARCHA-MIN": "Archaeology (Minor)",
  "ART-MIN": "Art History (Minor)",
  "ARTHS-BA": "Art History (BA)",
  "ARTHS-MA": "Art History (MA)",
  "ARTHS-PHD": "Art History (PhD)",
  "ARTHS-PMN": "Art History (PhD Minor)",
  "ARTP-BA": "Art Practice (BA)",
  "ARTP-MFA": "Art Practice (MFA)",
  "ARTP-MIN": "Art Practice (Minor)",
  "ARTS-IHN": "Honors in the Arts (IHN)",
  "ARTS-MIN": "Interdisciplinary Arts (Minor)",
  "ASAM-BA": "Asian American Studies (BA)",
  "ASAM-MIN": "Asian American Stu (Minor)",
  "BIO-BS": "Biology (BS)",
  "BIO-MIN": "Biology (Minor)",
  "BIO-MS": "Biology (MS)",
  "BIO-PHD": "Biology (PhD)",
  "BIOC-MS": "Biochemistry (MS)",
  "BIOC-PHD": "Biochemistry (PhD)",
  "BIOE-BS": "Bioengineering (BS)",
  "BIOE-MS": "Bioengineering (MS)",
  "BIOE-PHD": "Bioengineering (PhD)",
  "BIOE-PMN": "Bioengineering (PhD Minor)",
  "BIOPH-MS": "Biophysics (MS)",
  "BIOPH-PHD": "Biophysics (PhD)",
  "BMDS-MS": "Biomedical Data Science (MS)",
  "BMDS-PHD": "Biomedical Data Science (PhD)",
  "BMDS-PMN": "Biomedical Data Science (PhD Minor)",
  "BMP-PHD": "Biomedical Physics (PhD)",
  "CANBI-MS": "Cancer Biology (MS)",
  "CANBI-PHD": "Cancer Biology (PhD)",
  "CE-BS": "Civil Engineering (BS)",
  "CE-MIN": "Civil Engineering (Minor)",
  "CEE-ENG": "Civil and Environmental Engineering (ENG)",
  "CEE-MS": "Civil and Environmental Engineering (MS)",
  "CEE-PHD": "Civil and Environmental Engineering (PhD)",
  "CEE-PMN": "Civil and Environmental Engineering (PhD Minor)",
  "CHEM-BS": "Chemistry (BS)",
  "CHEM-MIN": "Chemistry (Minor)",
  "CHEM-MS": "Chemistry (MS)",
  "CHEM-PHD": "Chemistry (PhD)",
  "CHEM-PMN": "Chemistry (PhD Minor)",
  "CHEME-BS": "Chemical Engineering (BS)",
  "CHEME-ENG": "Chemical Engineering (ENG)",
  "CHEME-MIN": "Chemical Engineering (Minor)",
  "CHEME-MS": "Chemical Engineering (MS)",
  "CHEME-PHD": "Chemical Engineering (PhD)",
  "CHEME-PMN": "Chemical Engineering (PhD Minor)",
  "CHILT-BA": "Chicana/o - Latina/o Studies (BA)",
  "CHILT-MIN": "Chicana/o - Latina/o Studies (Minor)",
  "CHPR-MS": "Community Health and Prevention Research (MS)",
  "CIMGT-MS": "Clinical Informatics Management (MS)",
  "CLASS-BA": "Classics (BA)",
  "CLASS-MA": "Classics (MA)",
  "CLASS-MIN": "Classics (Minor)",
  "CLASS-PHD": "Classics (PhD)",
  "CLASS-PMN": "Classics (PhD Minor)",
  "CME-MS": "Computational and Mathematical Engineering (MS)",
  "CME-PHD": "Computational and Mathematical Engineering (PHD)",
  "CME-PMN": "Computational and Mathematical Engineering (PhD Minor)",
  "COMMU-BA": "Communication (BA)",
  "COMMU-MA": "Communication (MA)",
  "COMMU-MIN": "Communication (Minor)",
  "COMMU-PHD": "Communication (PhD)",
  "COMMU-PMN": "Communication (PhD Minor)",
  "CPLIT-BA": "Comparative Literature (BA)",
  "CPLIT-MIN": "Comparative Literature (Minor)",
  "CPLIT-PHD": "Comparative Literature (PhD)",
  "CPLIT-PMN": "Comparative Literature (PhD Minor)",
  "CRWRIT-MIN": "Creative Writing (Minor)",
  "CS-BS": "Computer Science (BS)",
  "CS-MIN": "Computer Science (Minor)",
  "CS-MS": "Computer Science (MS)",
  "CS-PHD": "Computer Science (PhD)",
  "CS-PMN": "Computer Science (PhD Minor)",
  "CSB-MS": "Chemical and Systems Biology (MS)",
  "CSB-PHD": "Chemical and Systems Biology (PhD)",
  "CSRE-BA": "Comparative Studies in Race and Ethnicity (BA)",
  "CSRE-IHN": "Comparative Studies in Race and Ethnicity (IHN)",
  "CSRE-MIN": "Comparative Studies in Race and Ethnicity (Minor)",
  "CSRE-PMN": "Comparative Studies in Race and Ethnicity (PhD Minor)",
  "DATSC-BA": "Data Science (BA)",
  "DATSC-BS": "Data Science (BS)",
  "DATSCI-MIN": "Data Science (Minor)",
  "DBIO-MS": "Developmental Biology (MS)",
  "DBIO-PHD": "Developmental Biology (PhD)",
  "DESIGN-BS": "Design (BS)",
  "DESIGN-MS": "Design (MS)",
  "DIGHUM-MIN": "Digital Humanities (Minor)",
  "EASST-BA": "East Asian Studies (BA)",
  "EASST-MA": "East Asian Studies (MA)",
  "EASST-MIN": "East Asian Studies (Minor)",
  "EASYS-BS": "Earth Systems (BS)",
  "EASYS-MA": "Earth Systems (MA)",
  "EASYS-MIN": "Earth Systems (Minor)",
  "EASYS-MS": "Earth Systems (MS)",
  "ECON-BA": "Economics (BA)",
  "ECON-BS": "Economics (BS)",
  "ECON-MA": "Economics (MA)",
  "ECON-MIN": "Economics (Minor)",
  "ECON-PHD": "Economics (PhD)",
  "ECON-PMN": "Economics (PhD Minor)",
  "ED-IHN": "Education (IHN)",
  "ED-MA": "Education (MA)",
  "ED-MS": "Education (MS)",
  "ED-PHD": "Education (PhD)",
  "ED-PMN": "Education (PhD Minor)",
  "EDUC-MIN": "Education (Minor)",
  "EE-BS": "Electrical Engineering (BS)",
  "EE-MIN": "Electrical Engineering (Minor)",
  "EE-MS": "Electrical Engineering (MS)",
  "EE-PHD": "Electrical Engineering (PhD)",
  "EE-PMN": "Electrical Engineering (PhD Minor)",
  "ENERGY-BS": "Energy Science and Engineering (BS)",
  "ENERGY-MIN": "Energy Science and Engineering (Minor)",
  "ENERGY-MS": "Energy Science and Engineering (MS)",
  "ENERGY-PHD": "Energy Science and Engineering (PhD)",
  "ENERGY-PMN": "Energy Science and Engineering (PhD Minor)",
  "ENGL-BA": "English (BA)",
  "ENGL-MA": "English (MA)",
  "ENGL-MIN": "English (Minor)",
  "ENGL-PHD": "English (PhD)",
  "ENGR-BS": "Engineering (BS)",
  "ENGR-MS": "Engineering (MS)",
  "ENVRES-MS": "Environment and Resources (MS)",
  "ENVRES-PHD": "Environment and Resources (PHD)",
  "ENVSE-BS": "Environmental Systems Engineering (BS)",
  "ENVSE-MIN": "Environmental Systems Engineering (Minor)",
  "EPIDCR-PHD": "Epidemiology and Clinical Research (PhD)",
  "EPIDM-MS": "Epidemiology and Clinical Research (MS)",
  "EPS-BS": "Earth and Planetary Sciences (BS)",
  "EPS-MS": "Earth and Planetary Sciences (MS)",
  "EPS-PHD": "Earth and Planetary Sciences (PhD)",
  "EPS-PMN": "Earth and Planetary Sciences (PMn)",
  "ESS-MS": "Earth System Science (MS)",
  "ESS-PHD": "Earth System Science (PhD)",
  "ETHSO-IHN": "Ethics in Society",
  "ETHSO-MIN": "Ethics in Society (Minor)",
  "FGSS-BA": "Feminist, Gender, and Sexuality Studies (BA)",
  "FGSS-IHN": "Feminist, Gender, and Sexuality Studies",
  "FGSS-MIN": "Feminist, Gender, and Sexuality Studies (Minor)",
  "FGSS-PMN": "Feminist, Gender, and Sexuality Studies (PhD Minor)",
  "FILM-BA": "Film and Media Studies (BA)",
  "FILM-MFA": "Documentary Film and Video (MFA)",
  "FILM-MIN": "Film and Media Studies (Minor)",
  "FRENC-BA": "French (BA)",
  "FRENC-MA": "French (MA)",
  "FRENC-MIN": "French (Minor)",
  "FRENC-PHD": "French (PhD)",
  "FRENC-PMN": "French (PhD Minor)",
  "FRNIT-PHD": "French and Italian (PhD)",
  "GENE-MS": "Genetics (MS)",
  "GENE-PHD": "Genetics (PhD)",
  "GEOPH-BS": "Geophysics (BS)",
  "GEOPH-MIN": "Geophysics (Minor)",
  "GEOPH-MS": "Geophysics (MS)",
  "GEOPH-PHD": "Geophysics (PhD)",
  "GEOPH-PMN": "Geophysics (PhD Minor)",
  "GERST-BA": "German Studies (BA)",
  "GERST-MA": "German Studies (MA)",
  "GERST-MIN": "German Studies (Minor)",
  "GERST-PHD": "German Studies (PhD)",
  "GERST-PMN": "German Studies (PhD Minor)",
  "GLBLST-MIN": "Global Studies (Minor)",
  "GSB-MSM": "Management (MSM)",
  "HMN GEN-MS": "Human Genetics and Genetic Counseling (MS)",
  "HRP-MS": "Health Policy (MS)",
  "HRP-PHD": "Health Policy (PhD)",
  "HSTRY-BA": "History (BA)",
  "HSTRY-MA": "History (MA)",
  "HSTRY-MIN": "History (Minor)",
  "HSTRY-PHD": "History (PhD)",
  "HSTRY-PMN": "History (PhD Minor)",
  "HUMBI-BA": "Human Biology (BA)",
  "HUMBI-BS": "Human Biology (BS)",
  "HUMBI-MIN": "Human Biology (Minor)",
  "HUMRTS-MIN": "Human Rights (Minor)",
  "IDMEN-BS": "Individually Designed Major in Engineering (BS)",
  "ILAC-BA": "Iberian and Latin American Cultures (BA)",
  "ILAC-MA": "Iberian & Latin American Cultures (MA)",
  "ILAC-PHD": "Iberian & Latin American Cultures (PhD)",
  "ILAC-PMN": "Iberian & Latin American Cultures (PhD Minor)",
  "IMMUN-MS": "Immunology (MS)",
  "IMMUN-PHD": "Immunology (PhD)",
  "INPOL-MA": "International Policy (MA)",
  "INSST-IHN": "International Security Studies (IHN)",
  "INTLR-BA": "International Relations (BA)",
  "INTLR-MIN": "International Relations (Minor)",
  "ITAL-BA": "Italian (BA)",
  "ITAL-MA": "Italian (MA)",
  "ITAL-MIN": "Italian (Minor)",
  "ITAL-PHD": "Italian (PhD)",
  "ITAL-PMN": "Italian (PhD Minor)",
  "JEWSH-BA": "Jewish Studies (BA)",
  "JEWSH-MIN": "Jewish Studies (Minor)",
  "LAMER-MA": "Latin American Studies (MA)",
  "LING-BA": "Linguistics (BA)",
  "LING-MA": "Linguistics (MA)",
  "LING-MIN": "Linguistics (Minor)",
  "LING-PHD": "Linguistics (PhD)",
  "LING-PMN": "Linguistics (PhD Minor)",
  "MATH-BS": "Mathematics (BS)",
  "MATH-MIN": "Mathematics (Minor)",
  "MATH-MS": "Mathematics (MS)",
  "MATH-PHD": "Mathematics (PhD)",
  "MATH-PMN": "Mathematics (PhD Minor)",
  "MATSC-BS": "Materials Science and Engineering (BS)",
  "MATSC-ENG": "Materials Science and Engineering (ENG)",
  "MATSC-MIN": "Materials Science and Engineering (Minor)",
  "MATSC-MS": "Materials Science and Engineering (MS)",
  "MATSC-PHD": "Materials Science and Engineering (PhD)",
  "MCP-PHD": "Molecular and Cellular Physiology (PhD)",
  "ME-BS": "Mechanical Engineering (BS)",
  "ME-ENG": "Mechanical Engineering (ENG)",
  "ME-MIN": "Mechanical Engineering (Minor)",
  "ME-MS": "Mechanical Engineering (MS)",
  "ME-PHD": "Mechanical Engineering (PhD)",
  "ME-PMN": "Mechanical Engineering (PhD Minor)",
  "MED-MD": "Medicine (MD)",
  "MED-MS": "Medicine (MS)",
  "MEDHUM-MIN": "Medical Humanities (Minor)",
  "MEDST-MIN": "Medieval Studies (Minor)",
  "MELLC-MIN": "Middle Eastern Languages, Literatures, and Cultures (Minor)",
  "MGTSC-BS": "Management Science and Engineering (BS)",
  "MGTSC-MIN": "Management Science and Engineering (Minor)",
  "MGTSC-MS": "Management Science and Engineering (MS)",
  "MGTSC-PHD": "Management Science and Engineering (PhD)",
  "MGTSC-PMN": "Management Science and Engineering (PhD Minor)",
  "MI-PHD": "Microbiology and Immunology (PhD)",
  "MLA-MLA": "Master of Liberal Arts (MLA)",
  "MLASC-MS": "Laboratory Animal Science (MS)",
  "MODLAN-MIN": "Modern Language (Minor)",
  "MTLIT-MA": "Modern Thought and Literature (MA)",
  "MTLIT-PHD": "Modern Thought and Literature (PhD)",
  "MUSIC-BA": "Music (BA)",
  "MUSIC-DMA": "Musical Arts (DMA)",
  "MUSIC-MA": "Music (MA)",
  "MUSIC-MIN": "Music (Minor)",
  "MUSIC-PHD": "Music (PhD)",
  "NATAM-BA": "Native American Studies (BA)",
  "NATAM-MIN": "Native American Studies (Minor)",
  "NEURS-MS": "Neurosciences (MS)",
  "NEURS-PHD": "Neurosciences (PhD)",
  "OCEANS-PHD": "Oceans (PhD)",
  "PHILO-BA": "Philosophy (BA)",
  "PHILO-MA": "Philosophy (MA)",
  "PHILO-MIN": "Philosophy (Minor)",
  "PHILO-PHD": "Philosophy (PhD)",
  "PHILO-PMN": "Philosophy (PhD Minor)",
  "PHREL-BA": "Philosophy and Religious Studies (BA)",
  "PHYS-BS": "Physics (BS)",
  "PHYS-MIN": "Physics (Minor)",
  "PHYS-MS": "Physics (MS)",
  "PHYS-PHD": "Physics (PhD)",
  "PHYS-PMN": "Physics (PhD Minor)",
  "POLSC-BA": "Political Science (BA)",
  "POLSC-MA": "Political Science (MA)",
  "POLSC-MIN": "Political Science (Minor)",
  "POLSC-PHD": "Political Science (PhD)",
  "POLSC-PMN": "Political Science (PhD Minor)",
  "PORT-MIN": "Portuguese (Minor)",
  "PSYCH-BA": "Psychology (BA)",
  "PSYCH-MA": "Psychology (MA)",
  "PSYCH-MIN": "Psychology (Minor)",
  "PSYCH-PHD": "Psychology (PhD)",
  "PSYCH-PMN": "Psychology (PhD Minor)",
  "PUBPO-BA": "Public Policy (BA)",
  "PUBPO-MA": "Public Policy (MA)",
  "PUBPO-MIN": "Public Policy (Minor)",
  "PUBPO-MPP": "Public Policy (MPP)",
  "REES-MA": "Russian, East European and Eurasian Studies (MA)",
  "RELST-BA": "Religious Studies (BA)",
  "RELST-MA": "Religious Studies (MA)",
  "RELST-MIN": "Religious Studies (Minor)",
  "RELST-PHD": "Religious Studies (PhD)",
  "RELST-PMN": "Religious Studies (PhD Minor)",
  "SBIO-PHD": "Structural Biology (PhD)",
  "SLAV-BA": "Slavic Languages and Literatures (BA)",
  "SLAV-MA": "Slavic Languages and Literatures (MA)",
  "SLAV-MIN": "Slavic Languages and Literatures (Minor)",
  "SLAV-PHD": "Slavic Languages and Literatures (PhD)",
  "SLAV-PMN": "Slavic Languages and Literatures (PhD Minor)",
  "SOCIO-BA": "Sociology (BA)",
  "SOCIO-MA": "Sociology (MA)",
  "SOCIO-MIN": "Sociology (Minor)",
  "SOCIO-PHD": "Sociology (PhD)",
  "SOCIO-PMN": "Sociology (PhD Minor)",
  "SPAN-BA": "Spanish (BA)",
  "SPAN-MIN": "Spanish (Minor)",
  "STATS-MIN": "Statistics (Minor)",
  "STATS-MS": "Statistics (MS)",
  "STATS-PHD": "Statistics (PhD)",
  "STATS-PMN": "Statistics (PhD Minor)",
  "STMRM-MS": "Stem Cell Biology and Regenerative Medicine (MS)",
  "STMRM-PHD": "Stem Cell Biology and Regenerative Medicine (PhD)",
  "STS-BA": "Science, Technology, and Society (BA)",
  "STS-BS": "Science, Technology, and Society (BS)",
  "STS-IHN": "Science, Technology, and Society",
  "SUSTSCI-MA": "Sustainability Science and Practice (MA)",
  "SUSTSCI-MS": "Sustainability Science and Practice (MS)",
  "SYMBO-BS": "Symbolic Systems (BS)",
  "SYMBO-MIN": "Symbolic Systems (Minor)",
  "SYMBO-MS": "Symbolic Systems (MS)",
  "THPST-BA": "Theater and Performance Studies (BA)",
  "THPST-MIN": "Theater and Performance Studies (Minor)",
  "THPST-PHD": "Theater and Performance Studies (PhD)",
  "THPST-PMN": "Theater and Performance Studies (Minor)",
  "TRAM-MS": "Translational Research and Applied Medicine (MS)",
  "TRANS-MIN": "Translation Studies (Minor)",
  "URBST-BA": "Urban Studies (BA)",
  "URBST-MIN": "Urban Studies (Minor)"
}