import { useState, useRef, useEffect } from 'react'
import { useAuth } from '../../hooks/useAuth.js';
import { useIsMobile } from "../../layouts/Mobile";
import profileImg from '../../assets/instructor-profile.png';
import {processClassSearch, processInstructorsSearch, getDocsFromQuery, codeSearchProcessText} from  "../../helpers/SearchProcessing";

function Navbar() {
    const { login, logout } = useAuth();
    const user = JSON.parse(localStorage.getItem("user"));

    // State to control the dropdown visibility
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle dropdown visibility
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    // State to control the dropdown visibility
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    var [searchItems, setSearchItems] = useState([]);

    // handle Key Change; TODO
    const handleChange = (event) => {
        const searchTerm = event.target.value;

        if (searchTerm === "") {
            setSearchItems([]);
            setSearchIsOpen(false);
            return;
        } else {
            if (!searchIsOpen) {
                setSearchIsOpen(true);
            }

            const queries = processClassSearch(searchTerm);
            
            Promise.all(queries.map(q => getDocsFromQuery(q)))
                .then(resultsArrays => {
                    const combinedResults = [].concat(...resultsArrays);

                    // if 0 results, try instructors
                    if (combinedResults.length === 0) {
                        const instructorQueries = processInstructorsSearch(searchTerm);
                        Promise.all(instructorQueries.map(q => getDocsFromQuery(q)))
                            .then(instructorResultsArrays => {
                                const combinedInstructorResults = [].concat(...instructorResultsArrays);
                                // give each one a property of type instructor
                                combinedInstructorResults.forEach((item) => {
                                    item.type = "instructor";
                                });
                                // console.log(combinedInstructorResults)
                                setSearchItems(combinedInstructorResults);
                            })
                            .catch(error => {
                                console.error("Error getting documents: ", error);
                            });
                    } else {
                        setSearchItems(combinedResults);
                    }

                    setSearchItems(combinedResults);
                })
                .catch(error => {
                    console.error("Error getting documents: ", error);
                });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const inputCode = codeSearchProcessText(event.target.value);
            var prefixURL = "./"

            if (window.location.href.includes("/share/")) {
                prefixURL = "../";
            } 

            if (inputCode !== "") {
                setSearchIsOpen(false);
                window.location.href = prefixURL + inputCode;
            }
        }
    };

    const searchBarRef = useRef(null);

    useEffect(() => {
        // Function to handle the click event
        function handleClickOutside(event) {
            const searchBar = document.getElementById('search-navbar');

            if (searchBarRef.current && !searchBarRef.current.contains(event.target) && event.target !== searchBar) {
                setSearchIsOpen(false);
            }
        }

        // Attach the click event listener
        document.addEventListener("click", handleClickOutside);
        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [searchBarRef]);
    
    const SEARCH_BAR = (
        <div ref={searchBarRef} >
            <input type="text" id="search-navbar" data-dropdown-toggle="dropdownSearch" 
            className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            placeholder="Search..." onChange={handleChange} onKeyDown={handleKeyDown} />

            { searchIsOpen ?
                <div id="dropdownSearch"
                className={`absolute w-full mt-0 z-10 bg-white divide-gray-100 rounded-md shadow w-44 dark:bg-zinc-800`}>
                    {searchItems.map((item) => (
                        <div className="pl-3 py-3 text-sm text-left text-gray-900 dark:text-white cursor-pointer hover:dark:bg-zinc-600 rounded-md"
                        onClick={
                            () => {
                                setSearchIsOpen(false);
                                var prefixURL = "./"

                                if (window.location.href.includes("/share/")) {
                                    prefixURL = "../";
                                } 

                                if (item.type == "instructor") {
                                    window.location.href = prefixURL + `instructor?id=${item.id}`;
                                }  else {
                                    window.location.href = prefixURL + item.code;
                                }
                            }
                        }>
                             <div className="truncate line-clamp-1 block">
                            { (item.type != "instructor") ? 
                                <span>
                                    <span className="font-semibold">{item.code}: </span>
                                    {item.title}
                                </span>
                            :
                            <div className='flex items-center'>
                                <div className='flex justify-center items-center'>
                                    <img src={profileImg} className='inline-block w-7 h-7 mr-2 p-0.5'/>
                                </div>
                                <span className="font-semibold">{item.fullName} ({item.sunet})</span>
                            </div>
                        
                            }
                            </div>
                        </div>
                    ))}
                </div> : null
            }
        </div>
    );

    const [isMobile, setIsMobile] = useState(useIsMobile());

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1000px)')
        setIsMobile(mediaQuery.matches);

        const handler = (event) => setIsMobile(event.matches);
        mediaQuery.addEventListener('change', handler);

        return () => mediaQuery.removeEventListener('change', handler);
    }, []);

    const [navOpen, setNavOpen] = useState(false);
    const [mobileSearchBarShow, setMobileSearchBarShow] = useState(false);

    return (
        <nav className="bg-white dark:bg-zinc-900 relative">
        {/* // bg-blue-500 dark:bg-blue-500"> */}
            <div className="flex flex-wrap py-2 items-center mx-0"> 
             {/* mx-auto"> */}
                <div className={`${isMobile ? "ml-5" : ""} flex w-1/3 relative`}>
                    <a href="./" style={{left: "55%", transform: "translateX(-50.45%)"}} className="relative text-left text-4xl font-extrabold whitespace-nowrap text-transparent bg-clip-text bg-gradient-to-r from-[#1499f7] via-[#8A2BE2] to-[#FF69B4]">
                        OnCourse
                    </a>
                </div>
                <div dir={isMobile ? "rtl" : ""} className={isMobile ? "w-3/5 relative flex flex-row" : "w-1/3 ml-5"}>
                    { isMobile && 
                        <div className="relative bg-slate-50 dark:bg-zinc-900">
                        <button data-collapse-toggle="navbar-search" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 relative" aria-controls="navbar-search" aria-expanded="false"
                            onClick={() => {
                                setNavOpen(!navOpen);
                            }}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                            </svg>
                            <div id="dropdownAvatar" className={`absolute right-0 top-11 z-10 ${navOpen ? '' : 'hidden'} bg-white dark:bg-zinc-900 divide-y divide-gray-100 rounded-lg shadow w-30 dark:bg-gray-700 dark:divide-gray-600`}>
                                <div className="text-sm text-gray-900 dark:text-white w-max flex flex-col text-center">
                                    <a type="button" href={(window.location.href.includes("/share/")) ? "../" : "./"}
                                        className="hover:bg-slate-100 dark:hover:bg-gray-800 font-medium text-sm py-2 rounded-t-lg text-center inline-flex items-center">
                                            <span className="mx-5 text-gray-800 dark:text-white">Schedule</span>
                                    </a>
                                    {/* <a type="button" href="./import" 
                                        className="hover:bg-slate-100 dark:hover:bg-gray-800 font-medium text-sm py-2 text-left inline-flex items-center">
                                            <span className="mx-auto text-gray-800 dark:text-white">Import</span>
                                    </a> */}
                                    <a type="button" href={(window.location.href.includes("/share/")) ? "../catalog" : "./catalog"}
                                        className="hover:bg-slate-100 dark:hover:bg-gray-800 font-medium text-sm py-2 rounded-t-lg text-center inline-flex items-center">
                                            <span className="mx-5 text-gray-800 dark:text-white">Catalog</span>
                                    </a>
                                </div>
                                
                                <div className="w-full">
                                    <button onClick={logout} className="w-full block py-2 px-4 text-sm text-red-700 dark:text-red-500 dark:hover:text-red-400">Sign out</button>
                                </div>
                            </div>
                        </button>
                        </div>
                    }
                    {
                        isMobile ? 
                        // <div className="">
                            <button type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" 
                            className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 mr-1" 
                            onClick={
                                () => {
                                    setMobileSearchBarShow(!mobileSearchBarShow);
                                }
                            }>
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                                <span className="sr-only">Search</span>
                            </button>
                    :
                        <div className="relative hidden md:block">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                                <span className="sr-only">Search icon</span>
                            </div>
                            {SEARCH_BAR}
                        </div>
                    }
                </div>
                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-search">
                    <div className="relative mt-3 md:hidden">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                        </div>
                        {SEARCH_BAR}
                    </div>
                </div>


                
                { !isMobile && 
                <div dir="rtl" className="w-1/3 absolute right-0 flex">
                    <div className="relative inline-block px-4">
                        <button onClick={toggleDropdown} id="dropdownUserAvatarButton" data-dropdown-toggle="dropdownAvatar" className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 ml-auto" type="button">
                            <span className="sr-only">Open user menu</span>
                            {!user ? <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg> : <img className="w-8 h-8 rounded-full" src={user.photoURL} alt="user" />}
                        </button>

                        <div id="dropdownAvatar" className={`absolute right-0 mt-2 z-10 ${isOpen ? '' : 'hidden'} bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}>
                            <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                                <div>{!user ? "Loading..." : user.displayName}</div>
                                <div className="font-medium truncate">{!user ? "Loading..." : user.email}</div>
                            </div>
                            <div className="py-2">
                                <button onClick={logout} className="w-full block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-red-500 dark:hover:text-red">Sign out</button>
                            </div>
                        </div>
                    </div> {/* <-- Close the container */}

                    <div className="mx-2 relative inline-block items-center cursor-pointer">
                        {/* <a type="button" href={(window.location.href.includes("/share/")) ? "../import" : "./import"} 
                        className="hover:bg-slate-100 dark:hover:bg-gray-800 font-medium rounded-lg text-sm px-0.5 py-0.5 text-center inline-flex items-center">

                            <span className="mx-2 text-xl font-bold text-gray-800 dark:text-white">Import</span>
                        </a> */}
                    </div>

                    <div className="mx-2 relative inline-block items-center cursor-pointer">
                        <a type="button" href={(window.location.href.includes("/share/")) ? "../" : "./"} 
                        className="hover:bg-slate-100 dark:hover:bg-gray-800 font-medium rounded-lg text-sm px-0.5 py-0.5 text-center inline-flex items-cente">
                            {/* <svg className="w-6 h-6 text-gray-800 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z"/>
                            </svg> */}

                            <span className="mx-2 text-xl font-bold text-gray-800 dark:text-white">Schedule</span>
                        </a>
                    </div>

                    <div className="mx-2 relative inline-block items-center cursor-pointer">
                        <a type="button" href="./catalog" 
                        className="hover:bg-slate-100 dark:hover:bg-gray-800 font-medium rounded-lg text-sm px-0.5 py-0.5 text-center inline-flex items-center">
                            <span className="mx-2 text-xl font-bold text-gray-800 dark:text-white">Catalog</span>
                        </a>
                    </div>
                </div>
                }

            </div>

            { isMobile && mobileSearchBarShow &&
            <div className="flex w-full px-5">
                <div className="relative md:block w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                        <span className="sr-only">Search icon</span>
                    </div>
                    {SEARCH_BAR}
                </div>
            </div>
            }
        </nav>

    );
}

export default Navbar;