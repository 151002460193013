import { QUARTER_LONG_LIST } from '../constants/Constants';

export function toCamelCase(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export function convertFlowToHumanReadable(flow) {
    // split by -
    const flowParts = flow.split("-");
    var year = parseInt(flowParts[0]);
    const quarter = parseInt(flowParts[1]);

    const quarterDesc = QUARTER_LONG_LIST[parseInt(quarter)];

    if (quarter > 0) {
        year += 1;
    }

    return quarterDesc + " " + year;
}