import React, { useState, useRef, useEffect, createContext } from "react";
import Course from "../components/Course.js";
import CourseMobile from "../components/Course_Mobile.js";
import Calendar from "../components/Calendar.js";
import PinnedCourses from "../components/PinnedCourses.js";
import { DarkModeProvider, useDarkMode } from "../helpers/DarkModeContext";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../config/firebase";
import { query, orderBy, limit, onSnapshot } from "firebase/firestore";
import { doc, collection, getDocs, setDoc, getDoc } from "firebase/firestore";
import CourseFlow from "../components/CourseFlow.js";
import SectionEditing from "../components/SectionEditing.js";
import { QUARTER_SHORTENED_LIST, DAY_LIST, COURSES_PATH, LATEST_YEAR_IN_DB } from "../constants/Constants.js";
import { sectionConverter } from "../adapters/SectionAdapters";
import Navbar from "../components/Navbars/Navbar";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireBaseAuth } from "../config/firebase";
import { initialCourseFlow } from "../helpers/DateProcessing.js";
import { useIsMobile } from "../layouts/Mobile";
import "../styles/Home.css";
import RestrictedTimeView from "../components/RestrictedTimeView.js";
import DownloadCalendarButton from "../components/DownloadCalendarButton.js";

import { Section, CourseTime } from "../data/Section";
import { restrictedConvertTime } from "../helpers/RestrictedHelpers.js";

import {
	PinnedClasses,
	CurrentFlow,
	CourseSections,
	CourseCode,
	IsMobile,
	MobileCourseOpen,
} from "../helpers/Contexts";
import { set } from "date-fns";
import { Class } from "@mui/icons-material";

function Home() {
	const { CLASS_CODE } = useParams();

	var [pinnedClasses, setPinnedClasses] = useState({});
	var [currentFlow, setCurrentFlow] = useState(initialCourseFlow(new Date()));
	var [courseSections, setCourseSections] = useState({});
	var [courseCode, setCourseCode] = useState(undefined);

	useEffect(() => {
		// this fixes the loading section bug
		if (CLASS_CODE === undefined) {
			setCourseCode(undefined);
		} else {
			setCourseCode(CLASS_CODE.toUpperCase());
		}
	}, [CLASS_CODE]);

	const [user, loading, error] = useAuthState(fireBaseAuth);

	// const isMobile = useState(useIsMobile());
	var [isMobile, setIsMobile] = useState(useIsMobile());
	const mobileClass = isMobile ? "mobile" : "desktop";
	var [mobileCourseOpen, setMobileCourseOpen] = useState(false);

	// Ref to track the initial mount
	const initialMount = useRef(true);

	const isDarkMode = useState(false); //useDarkMode();
	// const { isDarkMode, toggleDarkMode } = useDarkMode();

	// Restricted
	var [showRestrict, setShowRestrict] = useState(false);
	var [restrictedDict, setRestrictedDict] = useState({});

	useEffect(() => {
		// Skip effect during initial mount
		if (initialMount.current) {
			initialMount.current = false;
			return;
		}

		if (isMobile && !mobileCourseOpen) {
			setMobileCourseOpen(true);
		}
	}, [courseCode, isMobile]);

	const addClass = (courseCode, data, flowWhenWeStarted) => {
		if (courseCode !== "RESTRICTED") {
            const restrictedYear = Math.min(currentFlow[0], LATEST_YEAR_IN_DB);
            const docRef = doc(db, COURSES_PATH, `${restrictedYear}`, `${courseCode}`, QUARTER_SHORTENED_LIST[currentFlow[1]])
                            .withConverter(sectionConverter(courseCode, flowWhenWeStarted));

			getDoc(docRef)
				.then((doc) => {
					if (doc.exists()) {
						const sections = doc.data();

						setCourseSections((prevState) => {
							// 1. Filter prevState to keep items with the correct flow.
							const filteredState = Object.entries(
								prevState
							).reduce((acc, [key, value]) => {
								if (
									value[0] !== undefined &&
									(value[0].flow === flowWhenWeStarted || value[0].type === "NOT_AVAILABLE")
								) {
									acc[key] = value;
								}
								return acc;
							}, {});

							// 2. Return the updated state with the specific course.
							return {
								...filteredState,
								[courseCode]: sections,
							};
						});

						setPinnedClasses((prevState) => {
							// 1. Filter prevState to keep items with the correct flow.
							const filteredState = Object.entries(
								prevState
							).reduce((acc, [key, value]) => {
								// if (value === undefined) return acc;
								if (value.flow === flowWhenWeStarted) {
									acc[key] = value;
								} else if (value.disabled === true) {
									value.flow = flowWhenWeStarted;
									acc[key] = value;
								}
								return acc;
							}, {});

							// 2. Return the updated state with the specific course.
							return {
								...filteredState,
								[courseCode]: {
									show: data.show
										? data.show
										: Array(sections.length).fill(true),
									hours: data.medianHours
										? data.medianHours
										: data.units * 3,
									units: data.units ? data.units : 0,
									title: data.title,
									// "unitChoice": data.unitChoice,
									gers: data.gers,
									flow: flowWhenWeStarted,
								},
							};
						});
					} else {
                        // the class is not available this quarter

                        setCourseSections((prevState) => {
							// 1. Filter prevState to keep items with the correct flow.
							const filteredState = Object.entries(
								prevState
							).reduce((acc, [key, value]) => {
								if (
									value[0] !== undefined &&
									(value[0].flow === flowWhenWeStarted || value[0].type === "NOT_AVAILABLE")
								) {
									acc[key] = value;
								}
								return acc;
							}, {});

							// 2. add undefined to represent not available
							return {
								...filteredState,
								[courseCode]: [{
                                    type: "NOT_AVAILABLE",
                                }],
							};
						});
					}
				})
				.catch((error) => {
					console.error("Error getting document:", error);
				});

			// const docRef = collection(db, "Courses", `${currentFlow[0]}`, `${courseCode}`, "sections", QUARTER_SHORTENED_LIST[currentFlow[1]])
			//                 .withConverter(sectionTimesConverter(courseCode));
			// const q = query(docRef, limit(5));

			// onSnapshot(q, (querySnapshot) => {
			//     var sections = [];
			//     var lectureFound = false;
			//     querySnapshot.forEach((doc) => {
			//         const section = doc.data();
			//         section.flow = flowWhenWeStarted;
			//         if (section.component === "LEC") {
			//             lectureFound = true;
			//             sections.push(section);
			//         } else if (!lectureFound) {
			//             sections.push(section);
			//         }
			//     });

			//     setCourseSections(prevState => {
			//         // 1. Filter prevState to keep items with the correct flow.
			//         const filteredState = Object.entries(prevState)
			//             .reduce((acc, [key, value]) => {
			//                 if (value[0] !== undefined && value[0].flow === flowWhenWeStarted) {
			//                     acc[key] = value;
			//                 }
			//                 return acc;
			//             }, {});

			//         // 2. Return the updated state with the specific course.
			//         return {
			//             ...filteredState,
			//             [courseCode]: sections
			//         };
			//     });

			//     setPinnedClasses(prevState => {
			//         // 1. Filter prevState to keep items with the correct flow.
			//         const filteredState = Object.entries(prevState)
			//             .reduce((acc, [key, value]) => {
			//                 // if (value === undefined) return acc;
			//                 if (value.flow === flowWhenWeStarted) {
			//                     acc[key] = value;
			//                 } else if (value.disabled === true) {
			//                     value.flow = flowWhenWeStarted;
			//                     acc[key] = value;
			//                 }
			//                 return acc;
			//             }, {});

			//         // 2. Return the updated state with the specific course.
			//         return {
			//             ...filteredState,
			//             [courseCode]: {
			//                 "show": (data.show) ? data.show : Array(sections.length).fill(true),
			//                 "hours": (data.medianHours) ? data.medianHours : 0,
			//                 "units": (data.units) ? data.units : 0,
			//                 "title": data.title,
			//                 // "unitChoice": data.unitChoice,
			//                 "gers": data.gers,
			//                 "flow": flowWhenWeStarted
			//             }
			//         };
			//     });
			// });
		} else {
			// RESTRICTED
			setRestrictedDict(data.items);

			// var restrictedSections = [];
			const filteredState = Object.entries(courseSections).reduce(
				(acc, [key, value]) => {
					if (
						value[0] !== undefined &&
                        (value[0].flow === flowWhenWeStarted || value[0].type === "NOT_AVAILABLE")
					) {
						acc[key] = value;
					}
					return acc;
				},
				{}
			);

			Object.keys(data.items).forEach((key, index) => {
				const item = data.items[key];

				var sectionTimes = [];

				item.days.map((day) => {
					var cTime = new CourseTime(
						day + 1,
						item.start[0],
						item.start[1],
						item.stop[0],
						item.stop[1],
						key
					);
					cTime.restricted = true;
					sectionTimes.push(cTime);
				});

				const sectionDescription =
					item.days.map((i) => DAY_LIST[i]).join(",") +
					" " +
					restrictedConvertTime(item.start) +
					"-" +
					restrictedConvertTime(item.stop) +
					".";

				var section = Section.createRestricted(
					key,
					(index + 1).toString().padStart(2, "0"),
					"",
					"",
					"",
					"",
					sectionTimes,
					sectionDescription
				);
				section.flow = flowWhenWeStarted;

				// restrictedSections.push(section);
				filteredState[key] = [section];
			});

			setCourseSections(filteredState);

			// setCourseSections(prevState => {
			//     // 1. Filter prevState to keep items with the correct flow.

			//     // 2. Return the updated state with the specific course.
			//     return {
			//         ...filteredState,
			//         ["RESTRICTED"]: restrictedSections
			//     };
			// });
		}
	};

	var [message, setMessage] = useState("Share with Friends");

	function generateShare() {
		const key = user.uid + `${currentFlow[0]}-${currentFlow[1]}`;
		// hash the key
		async function hashString(string) {
			const encoder = new TextEncoder(); // Convert string to ArrayBuffer
			const data = encoder.encode(string);
			const hashBuffer = await crypto.subtle.digest("SHA-256", data); // Hash the data
			const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
			const hashHex = hashArray
				.map((byte) => byte.toString(16).padStart(2, "0"))
				.join(""); // Convert bytes to hex string
			return hashHex;
			// return ":";
		}

		hashString(key).then((hash) => {
			// console.log(hash);  // This will print the hashed string in hexadecimal format

			var uploadDict = {
				classes: {},
				name: user.displayName,
				flow: currentFlow,
			};

			Object.keys(pinnedClasses).forEach((courseCode) => {
				const show = pinnedClasses[courseCode].show;
				var classDict = {
					show: show,
					title: pinnedClasses[courseCode].title,
					sections: [],
				};

				if (courseSections[courseCode] !== undefined) {
					courseSections[courseCode].forEach((section, i) => {
						// if (show[i]) {
						var sectionTimes = [];

						if (section.schedules !== undefined) {
							section.schedules.map((schedule) => {
								sectionTimes.push({
									day: schedule.day,
									startHour: schedule.startHour,
									startRemainder: schedule.startRemainder,
									endHour: schedule.endHour,
									endRemainder: schedule.endRemainder,
								});
							});
						}

						classDict["sections"].push({
							component: section.component,
							description: section.description,
							classID: section.classID,
							sectionNumber: section.sectionNumber,
							schedules: sectionTimes,
						});
						// }
					});
				}

				uploadDict.classes[courseCode] = classDict;
			});

			// upload to firebase
			const docRef = doc(db, "Share", hash);
			setDoc(docRef, uploadDict);

			const firstName = user.displayName.split(" ")[0];

			const link = "https://oncourse.college/share/" + hash;
			message = `View ${firstName}'s schedule at ` + link;
			setMessage(message);

			navigator.clipboard.writeText(message);
		});
	}

	useEffect(() => {
		const fetchData = async () => {
			const flowWhenWeStarted = `${currentFlow[0]}-${currentFlow[1]}`;

			setPinnedClasses((prevState) => {
				const filteredState = Object.entries(prevState).reduce(
					(acc, [key, value]) => {
						if (value.flow === flowWhenWeStarted) {
							acc[key] = value;
						} else if (value.disabled === true) {
							value.flow = flowWhenWeStarted;
							acc[key] = value;
						}
						return acc;
					},
					{}
				);
				return { ...filteredState };
			});
			setCourseSections((prevState) => {
				// 1. Filter prevState to keep items with the correct flow.
				const filteredState = Object.entries(prevState).reduce(
					(acc, [key, value]) => {
						if (
							value[0] !== undefined &&
                            (value[0].flow === flowWhenWeStarted 
								// || value[0].type === "NOT_AVAILABLE"
								// removing for now becuase it is causing issues (showing ghost classes from other quarters)
							)
						) {
							acc[key] = value;
						}
						return acc;
					},
					{}
				);

				// 2. Return the updated state with the specific course.
				return { ...filteredState };
			});
			setRestrictedDict({});

			const plannedSnapshot = await getDocs(
				collection(db, "Planned", user.uid, flowWhenWeStarted)
			);
			plannedSnapshot.forEach((doc) => {
				const data = doc.data();
				addClass(doc.id, data, flowWhenWeStarted);
			});
		};

		setShared(false);
		fetchData();
	}, [currentFlow]);

	const [shared, setShared] = useState(false);

	const scrollableRef = useRef(null); // Create a ref for the scrollable element

	const scrollToBottom = () => {
		if (scrollableRef.current) {
			// Scroll to the bottom by setting scrollTop to the scrollHeight
			scrollableRef.current.scrollTop =
				scrollableRef.current.scrollHeight;
		}
	};

	useEffect(() => {
		// Check if showRestrict is true and then scroll to the bottom
		if (showRestrict) {
			scrollToBottom();
		}
	}, [showRestrict]); // Listen for changes to showRestrict

	const navigate = useNavigate();
	// history implementation
	useEffect(() => {
		if (courseCode === undefined) return;
		const newURL = `/${courseCode}`;
		// history.push(newURL);
		navigate(newURL);
	}, [courseCode]);

	useEffect(() => {
		if (courseCode === undefined) return;
		if (CLASS_CODE === undefined) return;
		setCourseCode(CLASS_CODE.toUpperCase());
	}, [CLASS_CODE]);

	const ShareButton = () => (
		<div>
			{shared ? (
				<div
					id="alert-border-3"
					className="flex items-center p-4 text-green-800 dark:text-green-400 "
					role="alert"
				>
					{/* <svg class="flex-shrink-0 w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 30"> */}
					<svg
						className="w-[18px] h-[18px]"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 16 12"
					>
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M1 5.917 5.724 10.5 15 1.5"
						/>
					</svg>
					<div className="ml-1 text-sm font-medium px-3">
						<a
							className="font-semibold underline hover:no-underline"
							onClick={() => {
								navigator.clipboard.writeText(message);
							}}
						>
							Copied
						</a>
						. If you make changes, you’ll need to reshare.
					</div>
					<button
						type="button"
						className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
						data-dismiss-target="#alert-border-3"
						aria-label="Close"
						onClick={() => {
							setShared(false);
						}}
					>
						<span className="sr-only">Dismiss</span>
						<svg
							className="w-3 h-3"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 14 14"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
							/>
						</svg>
					</button>
				</div>
			) : null}

			<div className="relative mt-2 mb-2">
				<DownloadCalendarButton restrictedDict={restrictedDict} />

				<button
					className="relative inline-flex items-center justify-center p-0.5 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group
                    bg-gradient-to-r from-[#1499f7] via-[#8A2BE2] to-[#FF69B4]
                    hover:text-white dark:text-white"
					onClick={() => {
						generateShare();
					}}
				>
					<span
						className="group flex items-center text-lg relative px-5 py-2.5 space-x-1 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
						onClick={() => {
							setShared(true);
						}}
					>
						<svg
							className="w-6 h-6 text-gray-800 group-hover:text-white dark:text-white"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 24 24"
						>
							<path d="M16.922 11.76a1.56 1.56 0 0 0-.551-1.208L11.264 6.3a1.35 1.35 0 0 0-1.473-.2 1.542 1.542 0 0 0-.872 1.427v1.221a6.922 6.922 0 0 0-6 7.134v1.33A1.225 1.225 0 0 0 4.143 18.5a1.187 1.187 0 0 0 1.08-.73 4.72 4.72 0 0 1 3.7-2.868v1.085a1.546 1.546 0 0 0 .872 1.428 1.355 1.355 0 0 0 1.472-.2l5.108-4.25a1.56 1.56 0 0 0 .547-1.206Z" />
							<path d="m21.428 10.205-5.517-4.949a1 1 0 1 0-1.336 1.488l5.517 5.014-5.611 5.088a1 1 0 1 0 1.344 1.482l5.611-5.088a2.049 2.049 0 0 0-.008-3.035Z" />
						</svg>
						<span>Share with Friends</span>
					</span>
				</button>

				<button
					className="absolute w-[30px] h-[30px] top-1/2 right-0 transform -translate-y-1/2 mr-3"
					onClick={() => {
						setShowRestrict(!showRestrict);
					}}
				>
					<div className="group">
						<svg
							className="absolute inset-0 w-[30px] h-[30px] group-hover:opacity-0 text-gray-800 dark:text-white"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 20 20"
						>
							<path
								stroke="currentColor"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
							/>
						</svg>

						<svg
							className="absolute inset-0 opacity-0 hover:opacity-100"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
						</svg>
					</div>
				</button>
			</div>

			<RestrictedTimeView
				showRestrict={showRestrict}
				setShowRestrict={setShowRestrict}
				restrictedDict={restrictedDict}
				setRestrictedDict={setRestrictedDict}
			/>
		</div>
	);

    const [bannerOpen, setBannerOpen] = useState(true);

    return (
        <DarkModeProvider value={{isDarkMode}}>
            <PinnedClasses.Provider value={{pinnedClasses, setPinnedClasses}}>
                <CurrentFlow.Provider value={{currentFlow, setCurrentFlow}}>
                    <CourseSections.Provider value={{courseSections, setCourseSections}}>
                        <CourseCode.Provider value={{courseCode, setCourseCode}}>
                            <IsMobile.Provider value={{isMobile, setIsMobile}}>
                            <div className="flex flex-col h-screen overflow-hidden">
                                <Navbar/>
                                <div className={"flex h-screen dark:bg-zinc-900 dark:text-white pt-14 " + mobileClass}>
                                      {/* key={isDarkMode ? 'dark' : 'light'}> */}
                                    <div className={`${isMobile ? "w-screen" : "w-1/3 scrollable-content"} overflow-y-scroll`}
                                        ref={scrollableRef}>
                                        <Calendar/>
                                        <div style={!isMobile ? { paddingLeft: '10%' }: null} className="pb-8">
                                            {/* <p className="my-2"></p> */}
                                            <CourseFlow />
                                            <PinnedCourses/>
                                            <SectionEditing restrictedDict={restrictedDict} setRestrictedDict={setRestrictedDict}/>
                                            <ShareButton />
                                        </div>
                                    </div>
                                    
                                    {!isMobile ?
                                        <div className="px-4 py-2 w-2/3 overflow-y-scroll">
                                        {/* <button className="bg-red" onClick={() => {
                                           console.log("break the world");
                                           console.log(pinnedClasses["test"]["item"])
                                        }}>Break the world</button> */}

												<Course />
											</div>
										: mobileCourseOpen &&
										  courseCode !== undefined ? (
											// <div className={`fixed top-[5%] left-[0%] h-[95%] w-[100%] z-20`}>
											<div
												className={`fixed top-[8%] left-[0%] h-[95%] w-[100%] z-20`}
											>
												{/* Fixed button positioned at the top */}
												{/* <button className="fixed top-[10%] left-[2%] px-4 py-2 bg-blue-500 text-white z-30"> */}
												<button
													type="button"
													className="w-2/3 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br 
                                                focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 
                                                dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg px-5 py-2.5 text-center mr-2 mb-2
                                                font-extrabold text-xl"
													onClick={() => {
														setMobileCourseOpen(
															false
														);
														setCourseCode(
															undefined
														);
													}}
												>
													Close Course
												</button>

												{/* Scrollable content */}
												<div className="overflow-y-auto h-full">
													{/* <Course/> */}
													<CourseMobile />
												</div>
											</div>
										) : null}
									</div>
								</div>
							</IsMobile.Provider>
						</CourseCode.Provider>
					</CourseSections.Provider>
				</CurrentFlow.Provider>
			</PinnedClasses.Provider>
		</DarkModeProvider>
	);
}

export default Home;
