import Section from "../data/Section.js";

// export const sectionTimesConverter  = (courseCode) => { //(courseCode) { //}, show) {
//     return {
//         toFirestore: (section) => {
//             return {};
//         },
//         fromFirestore: (snapshot, options) => {
//             const data = snapshot.data(options);
//             // Now you can use courseCode within this function
//             return new Section(courseCode, data.sectionNumber, data.classId, data.component, data.maxClassSize, data.notes, data.schedules);
//         }
//     };
// };


export const sectionConverter  = (courseCode, flowWhenWeStarted) => { //(courseCode) { //}, show) {
    return {
        toFirestore: (section) => {
            return {};
        },
        fromFirestore: (snapshot, options) => {
            var sections = []

            const data = snapshot.data(options);
            const info = data["sections"];

            var lectureFound = false;

            for (var i = 0; i < info.length; i++) {
                const sectionData = info[i];
                const section = new Section(courseCode, sectionData.sectionNumber, sectionData.classId, 
                    sectionData.component, sectionData.maxClassSize, sectionData.notes, sectionData.schedules);
                section.flow = flowWhenWeStarted;

                if (section.component === "LEC") {
                    lectureFound = true;
                    sections.push(section);
                } else if (!lectureFound) {
                    sections.push(section);
                }
            }
            return sections;

            // Now you can use courseCode within this function
            // return new Section(courseCode, data.sectionNumber, data.classId, data.component, data.maxClassSize, data.notes, data.schedules);
        }
    };
};