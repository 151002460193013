import React, { useState, useRef, useEffect } from "react"
import {
  FILTERING_UNITS,
  GER_LIST,
  DAY_LIST,
  COMPONENT_LONG_LIST,
  COMPONENT_SHORT_LIST,
  CAREER_LIST,
  FILTER_TITLES,
  FINAL_LIST,
  GRADE_COLUMNS,
  GRADE_COLUMN_REVERSED,
  QUARTERS,
  QUARTERS_LONG,
  meanHoursMarks,
} from "./CatalogConstants"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import "./CatalogStyle.css"
import { overallSearch } from "./QueryServer"
import { toCamelCase } from "../helpers/StringHelpers"
import { collection, doc, getDoc, setDoc } from "firebase/firestore"
import { db } from "../config/firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { fireBaseAuth } from "../config/firebase"
import { produce } from "immer"
import { useLocation, useNavigate } from "react-router-dom"
import { colorToRgb } from "../components/Colors"
import { CATALOG_YEAR } from "../constants/Constants"

// const INSTRUCTOR_LINK = "https://explorecourses.stanford.edu/instructor/";
const INSTRUCTOR_LINK = "./instructor?id="

function CatalogSubjectList() {
  var [catalogSearchItems, setCatalogSearchItems] = useState(null)
  var [instructorsOpen, setInstructorsOpen] = useState([])
  var [sectionsOpen, setSectionsOpen] = useState([])
  var [sectionInfoMap, setSectionInfoMap] = useState({})
  var [mainSectionsPinned, setMainSectionsPinned] = useState([])
  const catalogSearchBarRef = useRef(null)
  var [catalogSearchText, setCatalogSearchText] = useState("")
  const [user, loading, error] = useAuthState(fireBaseAuth)

  const catalogHandleChange = (event) => {
    const searchTerm = event.target.value
    setCatalogSearchText(searchTerm)

    if (searchTerm === "") {
      setCatalogSearchItems(null)
    }
  }

  let navigate = useNavigate()
  let location = useLocation()

  const arg_names = [
    "query",
    "subject",
    "qtrs",
    "units",
    "gers",
    "days",
    "comps",
    "career",
    "final",
    "hours",
    "grade",
  ]
  const default_params = [
    "",
    "",
    Array(4).fill(false),
    Array(FILTERING_UNITS.length).fill(false),
    Array(GER_LIST.length).fill(false),
    Array(DAY_LIST.length).fill(false),
    Array(COMPONENT_SHORT_LIST.length).fill(false),
    Array(CAREER_LIST.length).fill(false),
    "",
    [0, 40],
    -1,
  ]

  // reformulated search function. assumes params are all in URL
  function requestSearch() {
    // extract all parameters from URL
    const params = new URLSearchParams(location.search)
    let args = []
    for (let i in arg_names) {
      i = parseInt(i)
      let val = params.get(arg_names[i])
      if (val != null) {
        val = JSON.parse(decodeURIComponent(val))
        args.push(val)
      } else {
        args.push(default_params[i])
      }
    }

    // perform search
    overallSearch(...args).then((resp) => {
      if (resp) setCatalogSearchItems(resp)
      else setCatalogSearchItems([])
    })
  }

  const catalogHandleKeyDown = (event) => {
    if (event.key === "Enter") {
      const params = new URLSearchParams(location.search)
      const query = event.target.value
      params.set("query", encodeURIComponent(JSON.stringify(query)))
      navigate({ pathname: location.pathname, search: params.toString() })
    }
  }

  const [subject, setSubject] = useState("")
  const [quartersOffered, setQuartersOffered] = useState(Array(4).fill(false)) // fill for the amount of GERs
  const [unitsSelectedIndexs, setUnitsSelectedIndexs] = useState(
    Array(FILTERING_UNITS.length).fill(false),
  ) // fill for the amount of units
  const [gersSelectedIndexs, setGersSelectedIndexs] = useState(Array(GER_LIST.length).fill(false)) // fill for the amount of GERs
  const gerMidpoint = Math.floor((GER_LIST.length + 1) / 2)
  const [filterDays, setFilterDays] = useState(Array(DAY_LIST.length).fill(false))
  const [componentsSelectedIndexs, setComponentsSelectedIndexs] = useState(
    Array(COMPONENT_SHORT_LIST.length).fill(false),
  ) // fill for the amount of GERs
  const componentsMidpoint = Math.floor((COMPONENT_SHORT_LIST.length + 1) / 2)
  const [careerSelectedIndexs, setCareerSelectedIndexs] = useState(
    Array(CAREER_LIST.length).fill(false),
  ) // fill for the amount of GERs
  const careersMidpoint = Math.floor((CAREER_LIST.length + 1) / 2)
  const [finalselectedOption, setFinalSelectedOption] = useState("") // Initialize state with an empty string or the default value
  const [medianGradeMinimumIndex, setMedianGradeMinimumIndex] = useState(-1) // Initialize state with an empty string or the default value

  function hoursTextFormat(value) {
    return `${value} hours`
  }

  const minDistance = 2
  const [meanHoursRange, setMeanHoursRange] = React.useState([0, 40])
  const [openFilterIndexs, setOpenFilterIndexs] = useState(Array(FILTER_TITLES.length).fill(false))

  const meanHoursRangeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setMeanHoursRange([Math.min(newValue[0], meanHoursRange[1] - minDistance), meanHoursRange[1]])
    } else {
      setMeanHoursRange([meanHoursRange[0], Math.max(newValue[1], meanHoursRange[0] + minDistance)])
    }
  }

  function GER_ITEM(ger, index) {
    return (
      <div className="flex items-center ps-3">
        <input
          id={`gerFilter-${index}-checkbox`}
          type="checkbox"
          value=""
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          checked={gersSelectedIndexs[index]}
          onClick={() => {
            setGersSelectedIndexs((prevState) => {
              const newState = [...prevState]
              newState[index] = !newState[index]
              return newState
            })
          }}
        />
        <label
          for={`gerFilter-${index}-checkbox`}
          className="w-full text-left py-1 ms-2 text-base font-medium text-gray-900 dark:text-gray-300"
        >
          {ger}
        </label>
      </div>
    )
  }

  function COMPONENT_ITEM(comp, index) {
    return (
      <div className="flex items-center ps-3">
        <input
          id={`compFilter-${index}-checkbox`}
          type="checkbox"
          value=""
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          checked={componentsSelectedIndexs[index]}
          onClick={() => {
            setComponentsSelectedIndexs((prevState) => {
              const newState = [...prevState]
              newState[index] = !newState[index]
              return newState
            })
          }}
        />
        <label
          for={`compFilter-${index}-checkbox`}
          className="w-full text-left py-1 ms-2 font-medium text-gray-900 dark:text-gray-300"
        >
          {COMPONENT_LONG_LIST[index]} ({comp})
        </label>
      </div>
    )
  }

  function CAREER_ITEM(career, index) {
    return (
      <div className="flex items-center ps-3">
        <input
          id={`careerFilter-${index}-checkbox`}
          type="checkbox"
          value=""
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          checked={careerSelectedIndexs[index]}
          onClick={() => {
            setCareerSelectedIndexs((prevState) => {
              const newState = [...prevState]
              newState[index] = !newState[index]
              return newState
            })
          }}
        />
        <label
          for={`careerFilter-${index}-checkbox`}
          className="w-full text-left py-1 ms-2 font-medium text-gray-900 dark:text-gray-300"
        >
          {career}
        </label>
      </div>
    )
  }
  const FilterTitle = ({ index }) => (
    // return (
    <div
      className="flex justify-between items-center px-3"
      onClick={() => {
        setOpenFilterIndexs({ ...openFilterIndexs, [index]: !openFilterIndexs[index] })
      }}
    >
      <h3 className="pt-3 text-base font-semibold text-gray-900 dark:text-white text-left">
        {FILTER_TITLES[index]}
      </h3>

      <button className="text-gray-800 dark:text-slate-400 text-2xl h-full flex items-center">
        {!openFilterIndexs[index] ? (
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 8"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
            />
          </svg>
        ) : (
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 8"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
            />
          </svg>
        )}
      </button>
    </div>
    // )
  )
  const mainSectionQuarterBlock = (qtr, sections, instructorMap, toBePinnedInfo, course_index) => {
    var pinnedBefore = undefined
    if (mainSectionsPinned[course_index] !== undefined) {
      if (mainSectionsPinned[course_index][qtr] !== undefined) {
        pinnedBefore = mainSectionsPinned[course_index][qtr]
      }
    }

    return (
      <div>
        {sections.map((section, i) => (
          <div
            key={i}
            className="flex pl-1 font-custom-mono text-left text-sm font-medium items-center line-clamp-1"
          >
            <button
              type="button"
              className=""
              onClick={() => {
                /* Pin to Qtr */
                const index = QUARTERS.indexOf(qtr)
                const courseCode = toBePinnedInfo["courseCode"]

                const planRef = doc(db, "Planned", user.uid, `${CATALOG_YEAR}-${index}`, courseCode)
                setDoc(planRef, {
                  // show: Array(sections.length).fill(true),
                  units: toBePinnedInfo["units"],
                  medianHours: toBePinnedInfo["medianHours"],
                  meanHours: toBePinnedInfo["meanHours"],
                  title: toBePinnedInfo["title"],
                  gers: toBePinnedInfo["gers"],
                })

                setMainSectionsPinned((prevStates) => {
                  const newStates = produce(prevStates, (draft) => {
                    // Ensure that course_index is within a valid range
                    if (course_index >= 0 && course_index < draft.length) {
                      if (!(qtr in draft[course_index])) {
                        draft[course_index][qtr] = {}
                      }
                      if (i >= 0 && i < draft[course_index][qtr].length) {
                        // Update the boolean value
                        draft[course_index][qtr][i] = !draft[course_index][qtr][i]
                      }
                    }
                  })

                  return newStates
                })
              }}
              onMouseOver={(event) => (event.currentTarget.style.transform = "scale(1.05)")}
              onMouseOut={(event) => (event.currentTarget.style.transform = "scale(1)")}
            >
              {pinnedBefore !== undefined && pinnedBefore[i] === true /* if pinned */ ? (
                <div className="pr-2 group">
                  <svg
                    className="w-6 h-6 p-1 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z" />
                  </svg>
                </div>
              ) : (
                <div className="pr-2 group">
                  <svg
                    className="w-6 h-6 p-1 text-gray-800 dark:text-white group-hover:hidden"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
                    />
                  </svg>

                  <svg
                    className="w-6 h-6 p-1 text-gray-800 dark:text-white hidden group-hover:block"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z" />
                  </svg>
                </div>
              )}
            </button>

            {/* QTR      SectionNumber*/}
            <div className="flex items-center w-full">
              <span className="line-clamp-1 min-w-max">
                {toCamelCase(qtr)} {String(i + 1).padStart(2, "0")} ({section["component"]}) -{" "}
                {section["schedule"].length > 0
                  ? section["schedule"].join(";")
                  : "No times available"}
              </span>
              <span>
                {
                  /* Instructors */
                  section["instructors"].length > 0 && (
                    <span className="line-clamp-1 truncates pl-2">
                      (
                      {section["instructors"].map((instructorID, index) => {
                        const instructor = instructorMap[instructorID]

                        // Skip rendering if instructor details are not available
                        if (!instructor) return null

                        return (
                          <span key={instructorID}>
                            <span className="hover:underline cursor-pointer text-green-500">
                              <a
                                href={`${INSTRUCTOR_LINK}${instructorID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {`${instructor[1]}, ${instructor[0][0]}.`}
                              </a>
                            </span>
                            {index === section["instructors"].length - 1 ? "" : "; "}
                          </span>
                        )
                      })}
                      )
                    </span>
                  )
                }
              </span>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const displayCourse = (courseInfo, i) => {
    // create offered quarters string in the format "Aut, Win".
    let offeredQuarters = []
    for (let key of QUARTERS) {
      if (courseInfo[key] === 1) {
        offeredQuarters.push(key.charAt(0).toUpperCase() + key.slice(1).toLowerCase())
      }
    }
    if (offeredQuarters.length === 0)
      offeredQuarters.push(
        `Not offered in ${CATALOG_YEAR}/${(CATALOG_YEAR + 1).toString().slice(-2)}`,
      )
    const offeredQuartersString = offeredQuarters.join(", ")

    // units string
    let unitPrefix = courseInfo["unitsMin"].toString()
    if (courseInfo["unitsMax"] !== courseInfo["unitsMin"]) {
      unitPrefix += "-" + courseInfo["unitsMax"]
    }
    let unitSuffix = "unit"
    if (courseInfo["unitsMax"] > 1) {
      unitSuffix += "s"
    }
    const unitString = unitPrefix + " " + unitSuffix
    const regex = /\[\[([^[\]]+)\]\]/g
    const parts = courseInfo["description"].split(regex).filter((part) => part !== "")
    // filter to remove empty strings

    var offset = 1
    if (courseInfo["description"].startsWith("[[")) offset = 0

    const courseCode = courseInfo["courseCode"]
    const instructorIDs = courseInfo["instructorIDs"]
    const instructorMap = courseInfo["instructorsMap"]
    const mainSections = courseInfo["main_sections"]

    var firstMainSection = undefined
    var lastMainSection = 4

    for (let i = 0; i < QUARTERS.length; i++) {
      if (mainSections[QUARTERS[i]] !== undefined) {
        if (mainSections[QUARTERS[i]].length > 0) {
          if (firstMainSection === undefined) {
            firstMainSection = i
          }
          lastMainSection = i
        }
      }
    }

    const meanHours = courseInfo["meanHours"] ? courseInfo["meanHours"] : courseInfo["unitsMax"] * 3
    const medianHours = courseInfo["medianHours"]
      ? courseInfo["medianHours"]
      : courseInfo["unitsMax"] * 3

    const toBePinnedInfo = {
      courseCode: courseCode,
      units: courseInfo["unitsMax"],
      medianHours: medianHours,
      meanHours: meanHours,
      title: courseInfo["title"],
      gers: courseInfo["gers"],
    }

    const showBtn = () => {
      return (
        <button
          className="pl-2 flex-shrink-0 font-bold text-blue-500 show-more-button"
          onClick={() => {
            setInstructorsOpen((prevStates) => {
              const newStates = [...prevStates]
              newStates[i] = !newStates[i] // Toggle the boolean value
              return newStates
            })
          }}
        >
          {/* Show */}
          Show {instructorsOpen[i] ? "Less" : "More"}
        </button>
      )
    }

    // useEffect(() => {
    //   const paragraphElement = paragraphRef.current;

    //   if (paragraphElement) {
    //     setIsOverflowed(paragraphElement.clientWidth < paragraphElement.scrollWidth);
    //   }
    // }, [instructorIDs]); // You might want to adjust the dependencies

    const sectionRow = (sectionNo, sectionData, lastRow) => {
      const schedule = sectionData["schedule"]
      const location = sectionData["location"]
      const instructors = sectionData["instructors"]

      return (
        // <div className="block bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <div
          className={`pl-4 pr-2 py-1 hover:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-700
          border border-t-0
          ${lastRow ? "border-b-0" : ""}
          border-gray-200 
        `}
        >
          {[
            `Section ${sectionNo}`,
            sectionData["component"],
            schedule !== undefined && schedule.length > 0 ? schedule : "No times available",
          ]
            .filter(Boolean)
            .join(" | ")}

          {location !== undefined && location !== "" ? (
            <span>
              {" at "}
              <a
                href={`https://campus-map.stanford.edu/?srch=${location}`}
                target="_blank"
                className="text-blue-500 hover:underline cursor-pointer"
              >
                {location}
              </a>
            </span>
          ) : null}

          {/* Section {sectionNo}
          {sectionData["component"]} {GAP}
          { schedule !== undefined && schedule.length > 0 ? schedule.join(";"): "No times available"} {GAP}380-380Y
          {(location !== undefined) && (location !== "") ? `${location} ${GAP}` : ""} */}
          {/* {instructors.length > 0 ? "with": ""}  */}
          <br></br>
          {instructors !== undefined ? (
            <div>
              <span className="font-bold">Instructors: </span>
              <span className="font-semibold text-green-500">
                {instructors.map((instructorID) => {
                  const instructor = instructorMap[instructorID] // Assuming you have a function to get instructor details by ID
                  if (instructor === undefined) return null // Skip rendering if instructor details are not available

                  return (
                    <span key={instructorID}>
                      <span className="hover:underline cursor-pointer">
                        <a href={`${INSTRUCTOR_LINK}${instructorID}`} target="_blank">
                          {instructor[1]}, {instructor[0][0]}.{" "}
                        </a>
                        ({instructor[2]}){";"}
                      </span>{" "}
                    </span>
                  )
                })}
              </span>
            </div>
          ) : null}
        </div>
      )
    }

    const mainSectionsEmpty = Object.keys(mainSections).length === 0

    return (
      //
      <div
        className={`px-2 pb-${mainSectionsEmpty ? 0 : 2} bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 cursor-default`}
        key={courseInfo["code"] + i.toString()}
      >
        <div className="flex justify-between items-center">
          <h4 className="text-2xl pl-3 font-bold tracking-tight text-gray-900 dark:text-white pt-3">
            {courseCode}
            <span className="font-semibold">: {courseInfo["title"]}</span>
          </h4>
          <svg
            className="w-3.5 h-3.5 cursor-pointer"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 18"
            onClick={() => {
              window.open("https://oncourse.college/" + courseCode, "_blank")
            }}
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
            ></path>
          </svg>
        </div>
        <div className="pl-5">
          <p className="font-medium text-sm pt-1 w-full overflow-hidden overflow-wrap-normal">
            {" "}
            {/* Description */}
            <span>
              {parts.map((part, index) => {
                if (index % 2 === offset) {
                  const strippedCode = part.replace(/ /g, "")
                  return (
                    <span
                      key={index}
                      className="font-semibold text-blue-500 cursor-pointer"
                      onClick={() => {
                        window.location.href = `./catalog?query=${encodeURIComponent(JSON.stringify(strippedCode))}`
                      }}
                    >
                      {part}
                    </span>
                  )
                } else {
                  const regexLINK = /{{(.*?)}}/g

                  var replacedString = part
                    .replace(/¿/g, "'")
                    .replace(regexLINK, function (match, id) {
                      var linkF = id
                      if (!id.startsWith("http://") && !id.startsWith("https://")) {
                        linkF = `http://${id}`
                      }

                      return `<a style="font-weight: bold; color: #00A86B; cursor: pointer;" target="_blank" rel="noopener noreferrer" href="${linkF}">${id}</a>`
                    })

                  return (
                    <span
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: replacedString,
                      }}
                    />
                  )
                }
              })}
            </span>
          </p>

          <p className="text-base font-medium text-blue-500">
            {offeredQuartersString} · {unitString}
            {courseInfo["gers"].length > 0 && " · "}
            {courseInfo["gers"].join(", ")} · {courseInfo["grading"]} · Repeatable{" "}
            {courseInfo["maxTimesRepeated"]} time{courseInfo["maxTimesRepeated"] > 1 && "s"}
            {" ·"} {courseInfo["finalExam"] ? "Final" : "No Final"}
            {courseInfo["meanHours"] === 0 ? "" : ` · ${courseInfo["meanHours"]} hours/week`}
            {courseInfo["medianGrade"] === -1
              ? ""
              : ` · ${GRADE_COLUMN_REVERSED[courseInfo["medianGrade"]]} Median`}
          </p>

          {instructorIDs.length > 0 && (
            <div>
              <div className="flex text-base items-center align-items w-full">
                {/* <p className={`${!instructorsOpen[i] ? "flex-grow overflow-hidden truncate" : ""} font-medium text-green-500 `}> */}
                <p
                  className={`font-medium text-green-500 ${!instructorsOpen[i] ? "h-[1.5em] overflow-hidden" : ""}`}
                  // ref={(el) => {
                  //   if (el && el.scrollWidth > el.clientWidth) {
                  //     el.parentElement.querySelector('.show-more-button').style.display = 'inline-block';
                  //   } else {
                  //     el.parentElement.querySelector('.show-more-button').style.display = 'none';
                  //   }
                  // }}
                >
                  {instructorIDs.map((instructorID) => {
                    const instructor = instructorMap[instructorID] // Assuming you have a function to get instructor details by ID

                    if (!instructor) return null // Skip rendering if instructor details are not available

                    return (
                      <span key={instructorID}>
                        <span className="hover:underline cursor-pointer">
                          <a
                            href={`${INSTRUCTOR_LINK}${instructorID}`}
                            target="_blank"
                            className="font-semibold"
                          >
                            {instructor[1]}, {instructor[0][0]}.{" "}
                          </a>
                          ({instructor[2]}){";"}
                        </span>{" "}
                      </span>
                    )
                  })}
                </p>

                {/* // paragrpahsHaveOverflowed[i] && */}
                {!instructorsOpen[i] && showBtn()}
              </div>

              {instructorsOpen[i] && <div className="flex justify-end">{showBtn()}</div>}
            </div>
          )}
        </div>

        {/* Main sections */}
        <div className="pl-5 pt-1">
          {QUARTERS.map((quarter) => (
            <div key={quarter}>
              {mainSections[quarter] !== undefined &&
                mainSectionQuarterBlock(
                  quarter,
                  mainSections[quarter],
                  instructorMap,
                  toBePinnedInfo,
                  i,
                )}
            </div>
          ))}
        </div>

        {/* All Sections */}
        <div className="mt-2 ml-3">
          {sectionsOpen[i] !== undefined &&
            QUARTERS.map((quarter, quarter_i) => (
              <div key={`${courseCode}-qtr-${quarter_i}`}>
                {courseInfo[quarter] ? (
                  <div id="accordion-collapse" data-accordion="collapse" className="pt-0 pl-3 ">
                    <h2 id={`#accordion-collapse-heading-${i}`}>
                      <button
                        type="button"
                        className={`flex items-center justify-between w-full py-1.5 pl-3 font-medium rtl:text-right
                             border ${quarter_i < lastMainSection ? "border-b-0" : ""}
                             border-gray-200 
                             ${quarter_i === firstMainSection ? "rounded-t-xl" : ""}
                             ${quarter_i === lastMainSection && !sectionsOpen[i][quarter_i] ? "rounded-b-xl" : ""}
                             dark:border-gray-700 dark:text-white dark:bg-gray-800 
                             hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 
                             ${sectionsOpen[i][quarter_i] ? "bg-gray-100 dark:bg-gray-800" : ""}
                             `}
                        data-accordion-target={`#accordion-collapse-body-${i}`}
                        aria-expanded={sectionsOpen[i][quarter_i]}
                        aria-controls={`#accordion-collapse-body-${i}`}
                        onClick={() => {
                          setSectionsOpen((prevStates) => {
                            const newStates = [...prevStates]
                            newStates[i] = [...prevStates[i]] // Make sure to create a new array for inner array
                            newStates[i][quarter_i] = !newStates[i][quarter_i]
                            return newStates
                          })

                          const key = courseCode + "-" + quarter_i
                          if (key in sectionInfoMap) return // check if fetched before

                          // fetch that info and update it
                          const sectionDocRef = doc(
                            db,
                            "Catalog",
                            `${CATALOG_YEAR}`,
                            `${courseCode}`,
                            quarter,
                          )
                          getDoc(sectionDocRef).then((docSnap) => {
                            const data = docSnap.data()

                            setSectionInfoMap((prevStates) => {
                              const newStates = { ...prevStates }
                              newStates[key] = data
                              return newStates
                            })
                          })
                        }}
                      >
                        <span>{QUARTERS_LONG[quarter_i]}</span>

                        <svg
                          data-accordion-icon
                          className={`w-3 h-3 mr-2 shrink-0 ${sectionsOpen[i][quarter_i] ? "rotate-0" : "rotate-180"}`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                          />
                        </svg>
                      </button>
                    </h2>

                    <div
                      id={`#accordion-collapse-body-${i}`}
                      className={`border 
                          border-${quarter_i < lastMainSection ? "b" : "t"}-0 
                          ${quarter_i === lastMainSection ? "rounded-b-xl" : ""}
                          border-gray-200 dark:border-gray-700 dark:bg-gray-900 ${
                            sectionsOpen[i][quarter_i] ? "" : "hidden"
                          }`}
                      aria-labelledby={`#accordion-collapse-heading-${i}`}
                    >
                      {sectionInfoMap[courseCode + "-" + quarter_i] !== undefined ? (
                        <div className="pt-1 pb-1">
                          {/* {
                                    sectionInfoMap[courseCode + "-" + quarter_i].map((section, section_i) => ( */}

                          {Object.keys(sectionInfoMap[courseCode + "-" + quarter_i])
                            .sort()
                            .map((sectionNo, i) => (
                              <div key={sectionNo}>
                                {sectionRow(
                                  sectionNo,
                                  sectionInfoMap[courseCode + "-" + quarter_i][sectionNo],
                                  i ===
                                    Object.keys(sectionInfoMap[courseCode + "-" + quarter_i])
                                      .length -
                                      1,
                                )}
                              </div>
                            ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
      </div>
    )
  }

  function resetFilters() {
    setQuartersOffered(Array(4).fill(false))
    setUnitsSelectedIndexs(Array(FILTERING_UNITS.length).fill(false))
    setGersSelectedIndexs(Array(GER_LIST.length).fill(false))
    setFilterDays(Array(DAY_LIST.length).fill(false))
    setComponentsSelectedIndexs(Array(COMPONENT_SHORT_LIST.length).fill(false))
    setCareerSelectedIndexs(Array(CAREER_LIST.length).fill(false))
    setFinalSelectedOption("")
    setMedianGradeMinimumIndex(-1)
    setMeanHoursRange([0, 40])
  }

  const update_functions = [
    setCatalogSearchText,
    setSubject,
    setQuartersOffered,
    setUnitsSelectedIndexs,
    setGersSelectedIndexs,
    setFilterDays,
    setComponentsSelectedIndexs,
    setCareerSelectedIndexs,
    setFinalSelectedOption,
    setMedianGradeMinimumIndex,
    setMeanHoursRange,
  ]

  // given URL, set parameters for the first time.
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    for (let i in update_functions) {
      i = parseInt(i)
      if (params.has(arg_names[i])) {
        update_functions[i](JSON.parse(decodeURIComponent(params.get(arg_names[i]))))
      }
    }
  }, [])

  // update URL whenever filter parameters change
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    let notDefault = false

    const args = [
      subject,
      quartersOffered,
      unitsSelectedIndexs,
      gersSelectedIndexs,
      filterDays,
      componentsSelectedIndexs,
      careerSelectedIndexs,
      finalselectedOption,
      meanHoursRange,
      medianGradeMinimumIndex,
    ]

    // if all queries are defaults, don't display any changes, because this is likely just the loading stage.
    for (let i in args) {
      i = parseInt(i)
      if (JSON.stringify(default_params[i + 1]) !== JSON.stringify(args[i])) {
        notDefault = true
        break
      }
    }
    if (!notDefault) return

    for (let i in args) {
      i = parseInt(i)
      if (JSON.stringify(default_params[i + 1]) === JSON.stringify(args[i])) {
        // don't display defaults in URL
        params.delete(arg_names[i + 1])
      } else {
        params.set(arg_names[i + 1], encodeURIComponent(JSON.stringify(args[i])))
      }
    }
    navigate({ pathname: location.pathname, search: params.toString() })
  }, [
    subject,
    quartersOffered,
    unitsSelectedIndexs,
    gersSelectedIndexs,
    filterDays,
    componentsSelectedIndexs,
    careerSelectedIndexs,
    finalselectedOption,
    meanHoursRange,
    medianGradeMinimumIndex,
  ])

  // whenever URL changes, call request Search
  useEffect(() => {
    requestSearch()
  }, [location.search])

  // Update search results when they change
  useEffect(() => {
    if (catalogSearchItems === null) return
    setInstructorsOpen(Array(catalogSearchItems.count).fill(false))

    const filled = Array(catalogSearchItems.count).fill([false, false, false, false])
    setSectionsOpen(filled)

    var filledMainSections = Array(catalogSearchItems.count).fill({})
    for (let i = 0; i < catalogSearchItems.count; i++) {
      if (catalogSearchItems["courses"][i] === undefined) break

      const mainSections = catalogSearchItems["courses"][i]["main_sections"]
      var current = {}

      for (const quarter of QUARTERS) {
        if (mainSections[quarter] !== undefined) {
          current[quarter] = Array(mainSections[quarter].length).fill(false)
        }
      }

      filledMainSections[i] = current
    }

    setMainSectionsPinned(filledMainSections)
  }, [catalogSearchItems])

  return (
    <div className="relative flex w-4/5 space-x-4 ">
      <div className="w-3/4 text-left">
        <div className="flex space-x-3 absolute -top-9 ">
          <button
            className="flex items-center text-left text-sm font-bold px-2 py-1 rounded-lg cursor-pointer 
                    dark:bg-gray-700"
            onClick={() => {
              navigate("/catalog")
            }}
          >
            <svg
              class="w-3 h-3 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 8 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"
              />
            </svg>
            <span className="ml-1 mr-1">Departments</span>
          </button>

          {subject !== "" && (
            <button
              className="text-left text-sm font-bold px-2 py-1 rounded-lg hover:line-through cursor-pointer "
              style={{
                backgroundColor: colorToRgb("purple", 400, 0.2),
                borderColor: `1px solid ${colorToRgb("purple", 400, 0.1)}`,
                color: colorToRgb("purple", 600, 1),
              }}
              onClick={() => {
                const params = new URLSearchParams(location.search)
                params.delete("subject")
                setSubject("")
                navigate({ pathname: location.pathname, search: params.toString() })
              }}
            >
              <span className="font-semibold">Subject:</span> {subject}
            </button>
          )}
        </div>
        {/* <CATALOG_SEARCH_BAR /> */}
        <div className="space-y-2">
          <div className="relative md:block">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search icon</span>
            </div>
            <div ref={catalogSearchBarRef}>
              <input
                type="text"
                id="catalog-search-navbar"
                data-dropdown-toggle="catalogDropdownSearch"
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 
                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search..."
                onChange={catalogHandleChange}
                onKeyDown={catalogHandleKeyDown}
                value={catalogSearchText}
              />
            </div>
          </div>
          <div className="w-full space-y-2">
            {catalogSearchItems !== null &&
              (catalogSearchItems.length === 0 ? (
                <div
                  className={`pl-4 pr-2 py-1 dark:border-gray-700 border border-gray-200 text-center`}
                >
                  Your search returned no results.
                </div>
              ) : (
                catalogSearchItems["courses"].map((course, i) => displayCourse(course, i))
              ))}
          </div>
        </div>
      </div>

      {/* FILTERS */}
      <div className="w-1/4 dark:bg-gray-700 rounded-md pb-2 h-full">
        {/* <h3 className="pt-3 pl-3 text-base font-semibold text-gray-900 dark:text-white text-left">Quarter Offered</h3> */}
        <FilterTitle index={0} />
        {openFilterIndexs[0] && (
          <div className="pl-3">
            <div className="flex">
              {["Autumn", "Winter"].map((quarter, index) => (
                <div className="flex w-1/2 items-center ps-3">
                  <input
                    id={`qOffered-${index}-checkbox`}
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    checked={quartersOffered[index]}
                    onChange={() => {
                      setQuartersOffered((prevState) => {
                        const newState = [...prevState]
                        newState[index] = !newState[index]
                        return newState
                      })
                    }}
                  />
                  <label
                    for={`qOffered-${index}-checkbox`}
                    className="w-full text-left py-1 ms-2 text-base font-medium text-gray-900 dark:text-gray-300"
                  >
                    {quarter}
                  </label>
                </div>
              ))}
            </div>

            <div className="flex">
              {["Spring", "Summer"].map((quarter, index) => (
                <div className="flex w-1/2 items-center ps-3">
                  <input
                    id={`qOffered-${index + 2}-checkbox`}
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    checked={quartersOffered[index + 2]}
                    onChange={() => {
                      setQuartersOffered((prevState) => {
                        const newState = [...prevState]
                        newState[index + 2] = !newState[index + 2]
                        return newState
                      })
                    }}
                  />
                  <label
                    for={`qOffered-${index + 2}-checkbox`}
                    className="w-full text-left py-1 ms-2 text-base font-medium text-gray-900 dark:text-gray-300"
                  >
                    {quarter}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        <FilterTitle index={1} />
        {openFilterIndexs[1] && (
          <div className="pt-1 inline-flex rounded-md shadow-sm" role="group">
            {FILTERING_UNITS.map((unit, index) => (
              <button
                type="button"
                className={`inline-flex transition-colors duration-300 items-center px-6 py-2 text-sm font-medium text-gray-900 border border-gray-200 dark:border-gray-600 dark:text-white ${unitsSelectedIndexs[index] ? "bg-blue-700 text-white" : "bg-white dark:bg-gray-800 hover:bg-blue-700 dark:hover:bg-blue-600"} ${index === 0 ? "rounded-s-lg" : ""} ${index === FILTERING_UNITS.length - 1 ? "rounded-e-lg" : ""}`}
                onClick={() => {
                  setUnitsSelectedIndexs((prevState) => {
                    const newState = [...prevState]
                    newState[index] = !newState[index]
                    return newState
                  })
                }}
              >
                {unit}
              </button>
            ))}
          </div>
        )}

        <FilterTitle index={2} />

        {openFilterIndexs[2] && (
          <div className="flex px-4">
            <div className="w-1/2">
              {GER_LIST.slice(0, gerMidpoint).map((ger, index) =>
                // <div className="flex items-center ps-3">
                //     <input id={`gerFilter-${index}-checkbox`} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                //     <label for={`gerFilter-${index}-checkbox`} className="w-full text-left py-1 ms-2 text-base font-medium text-gray-900 dark:text-gray-300">{ger}</label>
                // </div>
                GER_ITEM(ger, index),
              )}
            </div>
            <div className="w-1/2">
              {GER_LIST.slice(gerMidpoint).map((ger, index) =>
                // <div className="flex items-center ps-3">
                //     <input id={`gerFilter-${index+gerMidpoint}-checkbox`} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                //     <label for={`gerFilter-${index+gerMidpoint}-checkbox`} className="w-full text-left py-1 ms-2 text-base font-medium text-gray-900 dark:text-gray-300">{ger}</label>
                // </div>

                GER_ITEM(ger, index + gerMidpoint),
              )}
            </div>
          </div>
        )}

        <FilterTitle index={3} />

        {openFilterIndexs[3] && (
          <div className="pt-1 inline-flex rounded-md shadow-sm" role="group">
            {DAY_LIST.map((unit, index) => (
              <button
                type="button"
                className={`inline-flex transition-colors duration-300 items-center px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 dark:border-gray-600 dark:text-white ${filterDays[index] ? "bg-blue-700 text-white " : "bg-white dark:bg-gray-800 hover:bg-blue-700 dark:hover:bg-blue-600"} ${index === 0 ? "rounded-s-lg" : ""} ${index === DAY_LIST.length - 1 ? "rounded-e-lg" : ""}`}
                onClick={() => {
                  setFilterDays((prevState) => {
                    const newState = [...prevState]
                    newState[index] = !newState[index]
                    return newState
                  })
                }}
              >
                {unit}
              </button>
            ))}
          </div>
        )}

        <FilterTitle index={4} />

        {openFilterIndexs[4] && (
          <div className="flex px-4 text-sm pt-2">
            <div className="w-1/2">
              {COMPONENT_SHORT_LIST.slice(0, componentsMidpoint).map((comp, index) =>
                COMPONENT_ITEM(comp, index),
              )}
            </div>
            <div className="w-1/2">
              {COMPONENT_SHORT_LIST.slice(componentsMidpoint).map((comp, index) =>
                COMPONENT_ITEM(comp, index + componentsMidpoint),
              )}
            </div>
          </div>
        )}

        <FilterTitle index={5} />

        {openFilterIndexs[5] && (
          <Box className="px-6 dark:text-white">
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={meanHoursRange}
              onChange={meanHoursRangeChange}
              valueLabelDisplay="auto"
              getAriaValueText={hoursTextFormat}
              marks={meanHoursMarks}
              min={0}
              max={40}
            />
          </Box>
        )}

        <FilterTitle index={6} />

        {openFilterIndexs[6] && (
          <div className="flex px-4 text-sm pt-2">
            <div className="w-1/2">
              {CAREER_LIST.slice(0, careersMidpoint).map((career, index) =>
                CAREER_ITEM(career, index),
              )}
            </div>
            <div className="w-1/2">
              {CAREER_LIST.slice(careersMidpoint).map((career, index) =>
                CAREER_ITEM(career, index + careersMidpoint),
              )}
            </div>
          </div>
        )}

        <FilterTitle index={7} />

        {openFilterIndexs[7] && (
          <div className="pl-6 pt-2 text-sm jusitfy-between space-y-2">
            {FINAL_LIST.map((final, index) => (
              <div className="flex items-center">
                {/* <input id={`finalFilter-${index}-radio`} type="radio" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        checked={finalSelectedIndexs[index]} 
                        onClick={ () => {
                          setFinalSelectedIndexs(prevState => {
                            const newState = [...prevState];
                            newState[index] = !newState[index];
                            return newState;
                          })
                        }}/>
                      <label for={`finalFilter-${index}-radio`} className="text-left ms-2 font-medium text-gray-900 dark:text-gray-300">{final}</label> */}

                <input
                  id={`finalFilter-${index}-radio`}
                  type="radio"
                  value=""
                  name="default-radio"
                  checked={finalselectedOption === final}
                  onChange={() => setFinalSelectedOption(final)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor={`finalFilter-${index}-radio`}
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {final}
                </label>
              </div>
            ))}
          </div>
        )}

        <FilterTitle index={8} />

        {openFilterIndexs[8] && (
          <div className="pl-4 pt-2 text-sm jusitfy-between space-y-2 pr-2">
            <select
              id="median_minimum_select"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={medianGradeMinimumIndex}
              onChange={(e) => setMedianGradeMinimumIndex(e.target.value)}
            >
              <option value={-1} selected>
                No preference
              </option>
              {GRADE_COLUMNS.map((grade, index) => (
                <option value={index}>{grade}</option>
              ))}
            </select>
          </div>
        )}

        {/* RESET button */}
        <button
          type="button"
          onClick={() => resetFilters()}
          className="mt-3 text-white bg-gray-800 hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:hover:bg-gray-900 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          Reset all filters
        </button>
      </div>
    </div>
  )
}

export default CatalogSubjectList
