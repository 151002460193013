import React, {useContext} from 'react'
import { CurrentFlow, CourseSections, PinnedClasses } from '../helpers/Contexts';
import {QUARTER_SCHEDULES} from '../constants/Constants';

const { DateTime } = require('luxon');

// 1. Generate ICS content
function generateICS(events, currentFlow) {
    let icsContent = 
`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//YourApp//EN
`;

    const flowDesc = `${currentFlow[0]}-${currentFlow[1]}`;
    const quarterSchedule = QUARTER_SCHEDULES[flowDesc];

    if (quarterSchedule === undefined) {
        alert("Unfortunately downloading your calendar for this quarter is not supported yet.")
        return;
    }

    // const startMonday = new Date(quarterSchedule[0]);
    // const startDate = new Date(quarterSchedule[1]);
    // const endDate = new Date(quarterSchedule[2]);


    const timeZone = 'America/Los_Angeles';
    const ISO_FORMAT = 'yyyyMMdd\'T\'HHmmss';
    const startMonday = DateTime.fromFormat(quarterSchedule[0], 'yyyy-MM-dd', { zone: timeZone });
    const startDate   = DateTime.fromFormat(quarterSchedule[1], 'yyyy-MM-dd', { zone: timeZone });
    const endDate     = DateTime.fromFormat(quarterSchedule[2], 'yyyy-MM-dd', { zone: timeZone });

    for (let currentDate = startMonday; 
        currentDate <= endDate; 
        currentDate = currentDate.plus({ days: 7 })) {
        for (let event of events) {
            // Create a DateTime object representing the event start date
            var eventStart = currentDate.plus({ days: event.dayI });
            
            // check for mid week start (e.g. Holiday on Monday of quarter)
            if (eventStart < startDate) {
                continue;
            }

            var eventEnd = eventStart.set({});

            eventStart = eventStart.plus({ hours: event.startHr, minutes: event.startMin });

            // set hour and minute of eventEnd
            // eventEnd.setHours(event.endHr + 1);
            // eventEnd.setMinutes(event.endMin + 1);
            eventEnd = eventEnd.plus({ hours: event.endHr, minutes: event.endMin + 1 });
            
            const start = `DTSTART;TZID=${timeZone}:${eventStart.toFormat(ISO_FORMAT)}`;
            const end = `DTEND;TZID=${timeZone}:${eventEnd.toFormat(ISO_FORMAT)}`;

            var title = event.title;
            if (event.component !== "") { title += " (" + event.component + ")";}

// LEAVE ME UNINDENTED!!!
            icsContent += 
            `BEGIN:VEVENT
${start}
${end}
SUMMARY:${title}
`;
if (event.location !== "") {
icsContent+=`LOCATION:${event.location}
`;
}

icsContent += `END:VEVENT
`;
    // LEAVE ME UNINDENTED!!!
        }

        // Testing: only do one week for test
        // break; 
    }

    icsContent += `END:VCALENDAR`;
    // console.log(icsContent)

    return icsContent;
}

function downloadICS(pinnedClasses, courseSections, currentFlow, restrictedDict) {
    // extract events
    var events = [];
    for (const code in courseSections) {
        if (pinnedClasses[code] === undefined) { continue; }
        const show = pinnedClasses[code]["show"];
        const sections = courseSections[code];

        for (let idx = 0; idx < sections.length; idx++) {
            const section = sections[idx];

            // if (!showingUserSchedule && section.currentUserSection == true) {
            //     continue; // break as all events in this class are for the current user
            // }

            if (show === undefined || show[idx]) {
                section.schedules.forEach(event => {
                    if (event.day && event.day > 0 && event.day <= 5) {
                        // if (event === "RESTRICTED") {
                        if (event.restricted || section.currentUserSection === true ||  (code in pinnedClasses && pinnedClasses[code]["disabled"] === true)) {
                            // if (event.restricted) {
                            //     console.log(event);
                            // }
                            
                        } else {
                            const info = {
                                dayI: event.day - 1,
                                startHr: event.startHour,
                                startMin: event.startRemainder * 60,
                                endHr: event.endHour,
                                endMin: event.endRemainder * 60,
                                title: event.title,
                                location: event.location,
                                component: event.component
                            }

                            events.push(info);
                        }
                    }
                }
                );
            }
        }
    }

    for (const code in restrictedDict) {
        const item = restrictedDict[code];

        for (let i = 0; i < item.days.length; i++) {
            const day = item.days[i];
            const info = {
                dayI: parseInt(day),
                startHr: item.start[0],
                startMin: parseFloat(item.start[1]) * 60,
                endHr: item.stop[0],
                endMin: parseFloat(item.stop[1]) * 60,
                title: code,
                location: "",
                component: "MANUAL"
            }

            events.push(info);
        }
    }

    const icsContent = generateICS(events, currentFlow);

    const blob = new Blob([icsContent], { type: 'text/calendar' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'schedule.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function DownloadCalendarButton(props) {
    const {restrictedDict} = props;
    const {courseSections, setCourseSections} = useContext(CourseSections);
    const {pinnedClasses, setPinnedClasses} = useContext(PinnedClasses);
    var {currentFlow, setCurrentFlow} = useContext(CurrentFlow);
    
    return (
    <button 
        className="absolute w-[30px] h-[30px] top-1/2 left-3 transform -translate-y-1/2 ml-3"
        onClick={
            () => {
                downloadICS(pinnedClasses, courseSections, currentFlow, restrictedDict);
            }
        }>
        <div className="group">
            <svg 
                className="absolute inset-0 w-[30px] h-[30px] group-hover:opacity-0 text-gray-800 dark:text-white"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"
            >
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15h.01M4 12H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-3M9.5 1v10.93m4-3.93-4 4-4-4"/>
            </svg>

            <svg 
                className="absolute inset-0 w-[30px] h-[30px] opacity-0 hover:opacity-100" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"
            >
                <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z"/>
                <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>
            </svg>
        </div>
        
    </button>);
}

export default DownloadCalendarButton;