
const DAY_MAP = {
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
}

export class CourseTime {
    
    constructor(day, startHour, startRemainder, endHour, endRemainder, title, location = "", component = "") {
        this.day = day;
        this.startHour = startHour;
        this.startRemainder = parseFloat(startRemainder);
        this.endHour = endHour;
        this.endRemainder = parseFloat(endRemainder);

        this.startTime = startHour + this.startRemainder;
        this.endTime = endHour + this.endRemainder;

        this.title = title;
        this.index = 0;
        this.divisor = 1;

        // console.log(this.startHour, this.startRemainder, this.endHour, this.endRemainder)
        // this.description = this.startHour + ":" + Math.round(this.startRemainder*60) + "-" + this.endHour 
        //                     + ":" + Math.round(this.endRemainder*60);

        function convertTo12Hour(hour, remainder) {
            const minutes = Math.round(remainder * 60); // Convert decimal hour to minutes
            let period = 'AM';
            
            if (hour >= 12) {
                if (hour > 12) hour -= 12;  // Convert to 12-hour format
                period = 'PM';
            } else if (hour === 0) {
                hour = 12;  // Handle midnight
            }
        
            // Return formatted time
            return `${hour}:${minutes.toString().padStart(2, '0')}${period}`;
        }

        const startTime = convertTo12Hour(startHour, startRemainder);
        const endTime = convertTo12Hour(endHour, endRemainder);

        this.description = `${startTime}-${endTime}`;

        this.location = location;
        this.component = component;
    }
    
}

export class Section {

    createSection(courseCode, sectionNumber, classId, component, maxClassSize, notes, schedulesFormatted, scheduleDescription) {
        this.courseCode = courseCode;
        this.sectionNumber = sectionNumber;
        this.classID = classId;
        this.component = component;
        this.maxSize = maxClassSize;
        this.notes = notes;
        this.schedules = schedulesFormatted;
        this.description = scheduleDescription;
    }
    
    constructor(courseCode, sectionNumber, classId, component, maxClassSize, notes, schedules) {
        var schedulesF = [];
        var scheduleDescription = "";

        function convertTime(time) {
            var timeArray = time.split(":");
            var hour = timeArray[0];
            var minute = timeArray[1];
            var ampm = timeArray[2].split(" ")[1];
            return hour + ":" + minute + ampm;
        }

        if (schedules !== undefined) {
            for (const schedule of schedules) {
                if (schedule.days === null) { continue; }
                const days = schedule.days.split(" ");

                const startTimestamp = schedule.startTimestamp / (60*60);
                const endTimestamp = schedule.endTimestamp / (60*60);

                // split startTimestamp into hours and minutes
                const startHour = Math.floor(startTimestamp);
                const startLeftover = (startTimestamp - startHour);

                const endHour = Math.floor(endTimestamp);
                const endLeftover = (endTimestamp - endHour);

                const location = schedule.location;
                // join first character of each day
                scheduleDescription += days.map((day) => day[0]).join(",") + " " + convertTime(schedule.startTime) + "-" + convertTime(schedule.endTime) + ".";

                for (const day of days) {
                    // scheduleDescription += day;
                    const dayNum = DAY_MAP[day];
                    const courseTime = new CourseTime(dayNum, startHour, startLeftover, endHour, endLeftover, courseCode, location, component);
                    schedulesF.push(courseTime);
                }
            }
        }
        // this.schedules = schedulesF;

        // this.description = scheduleDescription;
        this.createSection(courseCode, sectionNumber, classId, component, maxClassSize, notes, schedulesF, scheduleDescription)
    }

    static createRestricted(courseCode, sectionNumber, classId, component, maxClassSize, notes, schedulesFormatted, scheduleDescription) {
        let section = new Section(); // Create an empty instance
        section.createSection(courseCode, sectionNumber, classId, component, maxClassSize, notes, schedulesFormatted, scheduleDescription);
        return section; // Return the populated instance
    }

    // constructor(courseCode, sectionNumber, classId, component, maxClassSize, notes, schedules) {
    //     this.courseCode = courseCode;
    //     this.sectionNumber = sectionNumber;
    //     this.classID = classId;
    //     this.component = component;
    //     this.maxSize = maxClassSize;
    //     this.notes = notes;

    //     var schedulesF = [];
    //     var scheduleDescription = "";

    //     function convertTime(time) {
    //         var timeArray = time.split(":");
    //         var hour = timeArray[0];
    //         var minute = timeArray[1];
    //         var ampm = timeArray[2].split(" ")[1];
    //         return hour + ":" + minute + ampm;
    //     }

    //     if (schedules !== undefined) {
    //         for (const schedule of schedules) {
    //             if (schedule.days === null) { continue; }
    //             const days = schedule.days.split(" ");

    //             const startTimestamp = schedule.startTimestamp / (60*60);
    //             const endTimestamp = schedule.endTimestamp / (60*60);

    //             // split startTimestamp into hours and minutes
    //             const startHour = Math.floor(startTimestamp);
    //             const startLeftover = (startTimestamp - startHour);

    //             const endHour = Math.floor(endTimestamp);
    //             const endLeftover = (endTimestamp - endHour);
    //             // join first character of each day
    //             scheduleDescription += days.map((day) => day[0]).join(",") + " " + convertTime(schedule.startTime) + "-" + convertTime(schedule.endTime) + ".";

    //             for (const day of days) {
    //                 // scheduleDescription += day;
    //                 const dayNum = DAY_MAP[day];
    //                 const courseTime = new CourseTime(dayNum, startHour, startLeftover, endHour, endLeftover, courseCode);
    //                 schedulesF.push(courseTime);
    //             }
    //         }
    //     }
    //     this.schedules = schedulesF;

    //     this.description = scheduleDescription;
    // }

    // function converts time like 1:30:00 PM to 1:30PM
    

    // getScheduleDescription() {
    //     var scheduleDescription = "";
    //     for (const schedule of this.schedules) {
    //         scheduleDescription += schedule.day + " " + schedule.startHour + ":" + schedule.startRemainder + "-" + schedule.endHour + ":" + schedule.endRemainder + "\n";
    //     }
    //     return scheduleDescription;
    // }
}

export default Section;
// export default CourseTime;