import { collection, getDocs, query, limit, where } from "firebase/firestore";
import {db} from "../config/firebase";
import {FILTERING_UNITS, GER_LIST, DAY_LIST, COMPONENT_LONG_LIST, COMPONENT_SHORT_LIST, CAREER_LIST, FILTER_TITLES} from '../catalog/CatalogConstants';
import { SEARCH_PATH, INSTRUCTOR_PATH } from "../constants/Constants";

export function processClassSearch(searchTerm) {
    var queries = [];

    if (searchTerm.length > 50) return queries; // if search term is too long, return empty array

    var titleTerm = searchTerm.toLowerCase();

    var codeSearch = codeSearchProcessText(searchTerm);

    const q1 = query(
        collection(db, SEARCH_PATH),
        where("code", ">=", codeSearch),
        where("code", "<", codeSearch + "\uf8ff"),
        limit(3)
    );
    queries.push(q1);
    
    if (!/\d/.test(titleTerm)) { // if does not contain digits
        const q2 = query(
            collection(db, SEARCH_PATH),
            where("titleSearch", ">=", titleTerm),
            where("titleSearch", "<", titleTerm + "\uf8ff"),
            limit(3)
        );
        queries.push(q2);
    }

    return queries;
}

export function processInstructorsSearch(searchTerm) {
    var queries = [];

    if (searchTerm.length > 50) return queries; // if search term is too long, return empty array
    var titleTerm = searchTerm.toLowerCase();

    if (!/\d/.test(titleTerm)) { // if does not contain digits
        const q2 = query(
            collection(db, INSTRUCTOR_PATH),
            where("fullNameLower", ">=", titleTerm),
            where("fullNameLower", "<", titleTerm + "\uf8ff"),
            limit(3)
        );
        queries.push(q2);
    }

    return queries;
}

export function codeSearchProcessText(searchTerm) {
    if (searchTerm === undefined) return "";

    var codeSearch = searchTerm.replaceAll(" ", "").toUpperCase();

    const indexOfSlash = codeSearch.indexOf('/');
    codeSearch = indexOfSlash !== -1 ? codeSearch.slice(0, indexOfSlash) : codeSearch;


    // check if prefix of codeSearch is MSE
    if (codeSearch.substring(0, 3) === "MSE") {
        codeSearch = "MS&E" + codeSearch.substring(3);
    }

    return codeSearch;
}

// TODO: finish writing this function
export function processSearchRedis(searchTerm, quarters, units, gers, days, components, ) {

}

export const getDocsFromQuery = async (q) => {
    const querySnapshot = await getDocs(q);
    let docs = [];
    querySnapshot.forEach(doc => {
        docs.push({ id: doc.id, ...doc.data() });
    });
    return docs;
}