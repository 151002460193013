// import { APP_VERSION } from "../constants/Constants";
import { METRICS_VERSION } from "../constants/Constants";

class Metrics {
    constructor(hours, outcomes, years, sentiment, difficulty) {
        if (hours !== undefined) {
            this.hours = hours;
        }
        if (outcomes !== undefined) {
            this.outcomes = outcomes;
        }
        if (years !== undefined) {
            this.years = years;
        }
        if (sentiment !== undefined) {
            this.sentiment = sentiment;
        }
        if (difficulty !== undefined) {
            this.difficulty = difficulty;
        }

        // APP VERSION
        this.version = METRICS_VERSION;
    }


    static fromJSON(json) {
        return new Metrics(
            json.hours,
            json.outcomes,
            json.years,
            json.sentiment,
            json.difficulty
        );
    }
}

export default Metrics;