import React, { useRef, useEffect, useState, useContext } from "react"
import Instructor from "../data/Instructor"
import InstructorCurrent from "./InstructorCurrent"
import { collection, getDoc } from "firebase/firestore"
import { db } from "../config/firebase"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip } from "react-tooltip"
import { QUARTER_SHORT_TO_LONG, QUARTER_SHORTENED_LIST } from "../constants/Constants.js"
import { CurrentFlow, PinnedClasses } from "../helpers/Contexts"
import { useAuthState } from "react-firebase-hooks/auth"
import { fireBaseAuth } from "../config/firebase"
import { doc, updateDoc } from "firebase/firestore"
import { descriptionofGER, styleForGER, processedGERs } from "../styles/GERStyling.js"
import { GER_LIST } from "../catalog/CatalogConstants.js"
import { generateStyles } from "../styles/GERStyling.js"

function CourseInfo(props) {
  // const {open, course, courseCode, allInstructors, isMobile} = props;
  const { open, course, courseCode, instructorNamesToID, isMobile } = props
  const { pinnedClasses, setPinnedClasses } = useContext(PinnedClasses)
  var { currentFlow, setCurrentFlow } = useContext(CurrentFlow)
  const [user, loading, error] = useAuthState(fireBaseAuth)

  var [instructorsRatingsDict, setInstructorsRatingsDict] = useState({})
  var [unitChoice, setUnitChoice] = useState(
    pinnedClasses[courseCode] === undefined || pinnedClasses[courseCode]["units"] === undefined
      ? course.units.length > 0
        ? course.units[course.units.length - 1]
        : 0
      : pinnedClasses[courseCode]["units"],
  )

  useEffect(() => {
    for (const ger of GER_LIST) {
      generateStyles(ger)
    }
  })
  useEffect(() => {
    setUnitChoice(
      pinnedClasses[courseCode] === undefined || pinnedClasses[courseCode]["units"] === undefined
        ? course.units.length > 0
          ? course.units[course.units.length - 1]
          : 0
        : pinnedClasses[courseCode]["units"],
    )
  }, [courseCode, pinnedClasses[courseCode]]) // put back by niall, think this is as the unit count updates for new classes

  useEffect(() => {
    const fetchData = async () => {
      if (instructorNamesToID.size === 0) {
        return setInstructorsRatingsDict({})
      }
      var instructorsRatings = {}

      const docSnapshot = await getDoc(
        doc(db, "Metrics", `${courseCode}`, "instructors2", "ratings"),
      )
      const data = docSnapshot.data()
      if (!data || !data.ratings) {
        return setInstructorsRatingsDict({})
      }
      // const ratings = data.ratings
      // for (const instructor in ratings) {
      for (const instructor of Object.keys(data.ratings)) {
        const nameSplit = instructor.split(" ")

        // YES: check if the instructor is teaching this year
        const reformattedName = nameSplit[1] + ", " + nameSplit[0][0]

        // check if reformatted Name in instructorNamesToID
        if (instructorNamesToID.hasOwnProperty(reformattedName)) {
          instructorsRatings[reformattedName] = new Instructor(
            instructor,
            data.ratings[instructor].ratings,
            data.ratings[instructor].terms,
          )
        }
      }

      setInstructorsRatingsDict(instructorsRatings)
    }

    fetchData()
  }, [courseCode, instructorNamesToID]) // Dependency array

  return (
    <div className="p-1">
      <div className="p-3 pb-1 pt-1">
        <div className="flex justify-between">
          <div className="w-1/2">
            <p>
              <b>Terms:</b>
            </p>
            <p>{course.qtrDesc !== "" ? course.qtrDesc : `Not offered in ${currentFlow[0]}`}</p>
          </div>
          <div className="w-1/2">
            <p>
              <b>Units:</b>
            </p>
            <p>
              {
                // course.unitsMin + ((course.unitsMin !== course.unitsMax) ? ("-" + course.unitsMax) : "")

                <div className="inline-flex rounded-md shadow-sm" role="group">
                  {course.units.map((unit, index) => {
                    const NONSELECTSTYLING = ` text-gray-900 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-700  
                                    dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:hover:border-gray-600`
                    const SELECTSTYLING = ` text-white bg-blue-600 border-blue-600 hover:bg-blue-700 hover:border-blue-700 hover:text-white
                                    dark:bg-blue-700 dark:border-blue-600 dark:text-white dark:hover:text-white dark:hover:bg-blue-600 
                                    dark:border-blue-700 dark:hover:border-blue-600`

                    let classes = `px-4 py-1 text-sm font-medium border-t border-b border-gray-200
                                        `

                    if (index === 0) {
                      classes += " rounded-l-md" // Add left rounded corners for the first button
                    }
                    if (index === course.units.length - 1) {
                      classes += " rounded-r-md" // Add right rounded corners for the last button
                    }
                    if (unit === unitChoice) {
                      classes += SELECTSTYLING
                    } else {
                      classes += NONSELECTSTYLING
                    }

                    return (
                      <button
                        type="button"
                        className={classes}
                        key={unit}
                        onClick={() => {
                          setUnitChoice(unit)

                          // check if that class is pinned or open
                          if (pinnedClasses[courseCode] !== undefined) {
                            let newPinnedClasses = {
                              ...pinnedClasses,
                            }
                            newPinnedClasses[courseCode]["units"] = unit
                            setPinnedClasses(newPinnedClasses)

                            // check if that class is pinned already
                            if (
                              newPinnedClasses[courseCode] === undefined ||
                              (newPinnedClasses[courseCode] !== undefined &&
                                !newPinnedClasses[courseCode]["disabled"])
                            ) {
                              // upload to firebase
                              const docRef = doc(
                                db,
                                "Planned",
                                user.uid,
                                `${currentFlow[0]}-${currentFlow[1]}`,
                                courseCode,
                              )
                              updateDoc(docRef, {
                                units: unit,
                              })
                            }
                          }

                          course.unitChoice = unit
                        }}
                      >
                        {unit}
                      </button>
                    )
                  })}
                </div>
              }
            </p>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="w-1/2">
            <p>
              <b>Grading:</b>
            </p>
            <p>{course.grading}</p>
          </div>
          <div className="w-1/2">
            <p>
              <b>UG REQS:</b>
            </p>
            <Tooltip id="tool-tip-id" className="z-10" />
            <div className="justify-left space-x-1 pb-1">
              {processedGERs(course.gers).map((ger) => (
                <button
                  className={`inline-flex items-center ml-0 px-3 py-1 text-sm font-medium text-center text-white  
                                    rounded-lg ${styleForGER(ger)}`}
                  key={ger}
                  data-tooltip-id="tool-tip-id"
                  data-tooltip-content={descriptionofGER(ger)}
                >
                  {ger}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="p-3 pt-0">
        <b className="text-neutral-700 dark:text-slate-300">Instructors</b>

        <div className="pb-2">
          {QUARTER_SHORTENED_LIST.map((quarter, index) => {
            if (course.instructors[quarter]) {
              return (
                <div key={index}>
                  <b>{QUARTER_SHORT_TO_LONG[quarter]}: </b>
                  {course.instructors[quarter]
                    .map((instructor) => {
                      let [lastName, firstInitial] = instructor.split(", ")
                      const id = instructorNamesToID[instructor]
                      return (
                        <a href={`./instructor?id=${id}`} key={instructor}>
                          {`${firstInitial[0]}. ${lastName}`}
                        </a>
                      )
                    })
                    .reduce((prev, curr) => [prev, ", ", curr])}
                </div>
              )
            } else {
              return null // or return some default JSX
            }
          })}
        </div>

        {
          // Array.from(allInstructors)
          Object.keys(instructorsRatingsDict)
            .filter((instructor) => instructorsRatingsDict[instructor] !== undefined)
            .map((instructor, index) => {
              const instructorDetails = instructorsRatingsDict[instructor]
              return (
                <InstructorCurrent
                  key={instructor} // the unique key
                  // quarter="test"
                  name={instructorDetails.name}
                  ratings={instructorDetails.ratings}
                  terms={instructorDetails.terms}
                  id={instructorNamesToID[instructor]}
                  isMobile={isMobile}
                />
              )
            })
        }
      </div>
    </div>
  )
}

export default CourseInfo
