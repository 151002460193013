import React, { useState, useEffect, createContext } from 'react';

export function useIsMobile() {
    // const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
    // return isMobile
    return window.matchMedia('(max-width: 800px)').matches
}

export function isMobileNotHook() {
    return window.matchMedia('(max-width: 800px)').matches
}