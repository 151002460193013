import React, { Component, useState } from "react";
import PlannerNavbar from "../components/Navbars/PlannerNavbar";
import { DarkModeProvider, useDarkMode } from "../helpers/DarkModeContext";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
	QUARTER_LONG_LIST,
	GER_LIST,
	GER_SLE_LIST,
	GER_REQUIREMENTS,
	gerColor,
	gerBasicColor,
	SEARCH_PATH,
	programColor,
	PROGRAM_NAME_TO_LONG,
	PROGRAMS_PATH,
} from "../constants/Constants";
import {
	collection,
	getDocs,
	query,
	limit,
	where,
	getDoc,
	doc,
	setDoc,
	deleteDoc,
	updateDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { fireBaseAuth } from "../config/firebase";
import { initialCourseFlow } from "../helpers/DateProcessing.js";
import { isMobileNotHook, useIsMobile } from "../layouts/Mobile";
import { colorToRgb } from "../components/Colors";
import { codeSearchProcessText } from "../helpers/SearchProcessing";
import { checkAll } from "../helpers/programReqs/programProcessing";
import {
	savePrograms,
	getPrograms,
	saveTracks,
	getTracks,
} from "../Cache/PlannerCaching";
import CryptoJS from "crypto-js";
import { Tooltip } from "react-tooltip";

const defaultYears = [2022, 2023, 2024, 2025];
const REQUIREMENT_MET_STYLE = "text-green-700 dark:text-green-300";
const REQUIREMENT_NOT_MET_STYLE = "text-red-700 dark:text-red-300";

// const BETA = true

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const compareTwoClasses = (a, b) => {
	if (a.id < b.id) {
		return -1;
	} else if (a.id > b.id) {
		return 1;
	} else {
		return 0;
	}
};

function isDictionary(value) {
	return typeof value === "object" && value !== null;
}

function decrypt(encryptedData, key) {
	// Decode the base64-encoded data
	const decodedData = CryptoJS.enc.Base64.parse(encryptedData);

	// Decrypt the data using AES
	const decryptedBytes = CryptoJS.AES.decrypt(
		{ ciphertext: decodedData },
		CryptoJS.enc.Utf8.parse(key),
		{ mode: CryptoJS.mode.ECB }
	);

	// Convert the decrypted bytes to a string
	const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

	// Parse the decrypted text as JSON
	const decryptedJson = JSON.parse(decryptedText);
	return decryptedJson;
}

/**
 * Moves an item from one list to another list.
 */
const move = (
	source,
	destination,
	droppableSource,
	droppableDestination,
	user
) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);
	deleteDoc(
		doc(
			db,
			"Planned",
			user.uid,
			droppableSource.droppableId === "unplanned"
				? "Unplanned"
				: droppableSource.droppableId,
			removed.code
		)
	);
	destClone.splice(droppableDestination.index, 0, removed);

	const meanHours =
		removed.meanHours !== undefined ? removed.meanHours : removed.units * 3;
	const medianHours =
		removed.meanHours !== undefined
			? removed.medianHours
			: removed.units * 3;

	const data = {
		gers: removed.gers,
		meanHours: meanHours,
		medianHours: medianHours,
		title: removed.title,
		units: removed.units,
	};

	setDoc(
		doc(
			db,
			"Planned",
			user.uid,
			droppableDestination.droppableId === "unplanned"
				? "Unplanned"
				: droppableDestination.droppableId,
			removed.code
		),
		data
	);

	//sourceClone.sort(compareTwoClasses);
	//destClone.sort(compareTwoClasses);
	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;
	return result;
};

const Collapsible = (props) => {
	const [open, setOpen] = useState(props.default || false);
	const toggle = () => {
		setOpen(!open);
	};

	return (
		<div
			className={`transition-all transition-duration-300 bg-slate-50 dark:bg-[#1c1c1c] shadow-md rounded-lg shadow-blue-500/40 
        dark:shadow-blue-800/20 w-full border dark:border-slate-700`}
			style={{
				display: "flex",
				"flex-direction": "column",
				flex: `${open ? "1 0 auto" : "0 1 auto"}`,
			}}
		>
			<div
				onClick={toggle}
				className={`${open ? "rounded-t-lg" : "rounded-lg"} bg-white dark:bg-zinc-900 
            px-2 border-b-[1px] dark:border-b-slate-700 relative py-1 cursor-pointer`}
			>
				<span className="relative left-0 text-sm text-left font-bold text-gray-900 dark:text-white">
					{props.custom_title === ""
						? (
								props.formattedQuarter +
								" " +
								props.formattedYear
							).toUpperCase()
						: props.custom_title.toUpperCase()}
				</span>
				{props.units > 0 && (
					<span className="absolute right-0 text-sm px-2 py-0.5">
						{props.units} units
					</span>
				)}
			</div>
			<div className="flex flex-col" style={{ flex: "1 1 auto" }}>
				{props.custom_title === "Unplanned Classes" &&
				props.renderSearchBar
					? props.renderSearchBar()
					: null}

				{open && props.children}
			</div>
		</div>
	);
};

class Planner extends Component {
	constructor(props) {
		super(props);

		// const INDEX_OF_SLE = GER_LIST.indexOf("Writing SLE");
		// var currentGERList = GER_LIST;
		// // currentGERList.splice(INDEX_OF_SLE, 1);

		const currentGERList = GER_LIST.filter(function (ger) {
			return ger !== "Writing SLE";
		});

		this.state = {
			years: defaultYears,
			user: null,
			loading: true,
			error: null,
			currentGERList: currentGERList,
			currentFlow: initialCourseFlow(new Date()),
			isMobile: isMobileNotHook(),
			reqMode: "Undergrad",
			// reqMode: 'CS-BS',
			reqPrograms: getPrograms(),
			trackLookup: getTracks(),
			programSearchIsOpen: false,
			programTrackIsOpen: false,
			programSearchText: "",
			programTrackSearchText: "",
			programData: null,
		};

		// default state
		this.state["unplanned"] = [];
		this.state["plannerSearchIsOpen"] = false;
		this.state["plannerSearchItems"] = [];
		this.state["showFutureClasses"] = true;
		this.state["isMobile"] = isMobileNotHook();
		this.state["manualLanguageSatisfied"] = false;

		// Bind the event handler
		this.handleClickOutside = this.handleClickOutside.bind(this);

		// program search text field reference
		this.programTextFieldRef = React.createRef();

		// showDetails (dictionary mapping to boolean)
		this.showDetails = {};
	}

	promisedSetState = (newState) =>
		new Promise((resolve) => this.setState(newState, resolve));

	downloadQuarter = async (quarter) => {
		if (this.state.hasOwnProperty(quarter)) {
			return;
		}

		const { user } = this.state;
		const plannedRef = collection(db, "Planned", user.uid, quarter);

		let data = await getDocs(plannedRef);
		try {
			let docs = [];
			data.forEach((doc) => {
				if (doc.id !== "RESTRICTED") {
					docs.push({
						id: doc.id + Date.now().toString(),
						code: doc.id,
						...doc.data(),
					});
				}
			});
			return this.promisedSetState({
				[quarter === "Unplanned" ? "unplanned" : quarter]: docs,
			});
		} catch (error) {
			return this.promisedSetState({
				[quarter === "Unplanned" ? "unplanned" : quarter]: [],
			});
		}
	};

	getPlannerYears = () => {
		const key = `PlannerYears`;
		const courseString = localStorage.getItem(key);
		return null;
		// return (courseString) ? JSON.parse(courseString): null;
	};

	savePlannerYears = (years) => {
		const key = `PlannerYears`;
		const value = JSON.stringify(years);
		// localStorage.setItem(key, value);
	};

	getShowFutureClasses = () => {
		const key = `ShowFutureClasses`;
		const courseString = localStorage.getItem(key);
		return null;
		// return (courseString) ? JSON.parse(courseString): null;
	};

	saveShowFutureClasses = () => {
		const key = `ShowFutureClasses`;
		const value = JSON.stringify(this.state.showFutureClasses);
		// localStorage.setItem(key, value);
	};

	fetchProgramJSON = async (program) => {
		const programRef = doc(db, PROGRAMS_PATH, program);
		const docSnapshot = await getDoc(programRef);
		if (docSnapshot.exists()) {
			const data = docSnapshot.data();

			// Decrypt the data
			const decryptedData = decrypt(data["0"], process.env.REACT_APP_PEK);

			// set the program data
			this.setState({
				programData: decryptedData,
				programSelected: program,
			});
		} else {
			console.log("No such document!");
		}
	};

	componentDidMount() {
		// read in years metadata from Firebase, with an appropriate default
		const getYears = async (user) => {
			const years = this.getPlannerYears();
			const showFutureClasses = this.getShowFutureClasses();
			const manualLanguageSatisfied = false;
			if (years) {
				await this.promisedSetState({ years: years });
			}
			if (showFutureClasses) {
				await this.promisedSetState({
					showFutureClasses: showFutureClasses,
				});
			}

			if (!years || !showFutureClasses || !manualLanguageSatisfied) {
				const userRef = doc(db, "Planned", user.uid);
				const docSnapshot = await getDoc(userRef);
				if (docSnapshot.exists()) {
					if (!years) {
						await this.promisedSetState({
							years: docSnapshot.data().years,
						});
						this.savePlannerYears(docSnapshot.data().years);
					}
					if (!showFutureClasses) {
						await this.promisedSetState({
							showFutureClasses:
								docSnapshot.data().showFutureClasses ===
								undefined
									? true
									: docSnapshot.data().showFutureClasses,
						});
						this.saveShowFutureClasses();
					}
					if (!manualLanguageSatisfied) {
						await this.promisedSetState({
							manualLanguageSatisfied:
								docSnapshot.data().manualLanguageSatisfied ===
								undefined
									? false
									: docSnapshot.data()
											.manualLanguageSatisfied,
						});
						// no cache
					}
				}
			}

			await allAsyncTasks();
		};

		// read in all planned classes from Firebase
		const getPlannedClasses = async () => {
			// get an array of all quarters to read from
			var all_flows = [];
			all_flows.push("Unplanned");

			if (this.state.years === undefined) {
				// this.state.years = defaultYears;
				await this.promisedSetState({ years: defaultYears });
			}
			if (this.state.showFutureClasses === undefined) {
				await this.promisedSetState({ showFutureClasses: true });
			}
			if (this.state.manualLanguageSatisfied === undefined) {
				await this.promisedSetState({ manualLanguageSatisfied: false });
			}

			for (var year of this.state.years) {
				for (const i in QUARTER_LONG_LIST) {
					var quarter_string = year.toString() + "-" + i.toString();
					all_flows.push(quarter_string);
				}
			}

			// read in all planned classes from Firebase, without waiting for each one to happen
			const promises = [];
			for (const quarter of all_flows) {
				promises.push(this.downloadQuarter(quarter));
			}

			return Promise.all(promises);
		};

		const setDefaults = async () => {
			// set default GER choices for each class
			const newState = this.state;
			for (const key of Object.keys(this.state)) {
				if (key.length !== 6 || key[4] !== "-") {
					continue;
				}

				const split = key.split("-");
				const year = parseInt(split[0]);
				const quarter = parseInt(split[1]);

				if (
					year > this.state.currentFlow[0] ||
					(year === this.state.currentFlow[0] &&
						quarter > this.state.currentFlow[1])
				) {
					if (this.state.showFutureClasses === false) {
						break; // break works here due to the way the quarters are ordered
					}
				}

				for (const i in this.state[key]) {
					// loop over class in each quarter
					if (
						this.state[key][i].gers !== undefined &&
						this.state[key][i].code.startsWith("SLE")
					) {
						newState["currentGERList"] = GER_SLE_LIST;
					}

					// if this class already has a stored "chosen_ger" dictionary, skip it
					if (
						this.state[key][i].hasOwnProperty("chosen_ger") &&
						this.state[key][i]["chosen_ger"].constructor === Object
					) {
						continue;
					}

					// otherwise, choose default GERs for this class
					newState[key][i]["chosen_ger"] = {};
					var way_chosen = false;
					if (this.state[key][i]["gers"] === undefined) continue;
					for (const ger of this.state[key][i]["gers"]) {
						if (!GER_LIST.includes(ger)) continue;
						if (ger.startsWith("WAY")) {
							newState[key][i]["chosen_ger"][ger] = !way_chosen;
							way_chosen = true;
						} else {
							newState[key][i]["chosen_ger"][ger] = true;
						}
					}
				}
			}
			this.setState(newState);
		};

		window.addEventListener("click", this.handleClickOutside);

		const allAsyncTasks = async () => {
			await getPlannedClasses();
			await setDefaults();
			// await this.fetchProgramJSON(this.state.reqPrograms[0])
			// await fetchProgramJSON();
		};

		const unsubscribe = fireBaseAuth.onAuthStateChanged(
			(user) => {
				this.setState({ user, loading: false }, () => {
					getYears(user);
				});
			},
			(error) => {
				this.setState({ error, loading: false });
			}
		);

		// Access URL parameters
		// const searchParams = new URLSearchParams(window.location.search)
		// var beta = searchParams.get("beta")
		// // console.log(beta)
		// if (beta === null || beta === undefined) beta = BETA
		// this.setState({ beta: beta })

		// Clean up the listener on component unmount
		return () => unsubscribe();
	}

	componentWillUnmount() {
		// Remove event listener when component unmounts
		window.removeEventListener("click", this.handleClickOutside);
	}

	handleClickOutside(event) {
		const dropdownSearch = document.getElementById("dropdownSearch");
		const dropdownSearchButton = document.getElementById(
			"dropdownSearchButton"
		);
		if (
			dropdownSearch &&
			!dropdownSearch.contains(event.target) &&
			!dropdownSearchButton.contains(event.target)
		) {
			// Click occurred outside of the dropdown and its button, close the dropdown
			this.setState({ programSearchIsOpen: false });
		}
	}

	addYearToState = (year) => {
		for (const i in QUARTER_LONG_LIST) {
			var quarter_string = year.toString() + "-" + i.toString();
			if (!this.state.hasOwnProperty(quarter_string)) {
				this.downloadQuarter(quarter_string);
			}
		}
	};

	onDragEnd = (result) => {
		const { source, destination } = result;
		// dropped outside the list
		if (!destination) {
			return;
		}
		const sInd = source.droppableId;
		const dInd = destination.droppableId;
		if (sInd === dInd) {
			const items = reorder(
				this.state[sInd],
				source.index,
				destination.index
			);
			const newState = this.state;
			newState[sInd] = items;
			this.setState(newState);
		} else {
			const result = move(
				this.state[sInd],
				this.state[dInd],
				source,
				destination,
				this.state.user
			);
			const newState = this.state;
			newState[sInd] = result[source.droppableId];
			newState[dInd] = result[destination.droppableId];
			this.setState(newState);
		}
	};

	singleQuarter = (droppableId, custom_title = "") => {
		var formattedYear = droppableId.split("-")[0];
		var formattedQuarter =
			QUARTER_LONG_LIST[parseInt(droppableId.split("-")[1])];
		if (formattedQuarter !== "Autumn") {
			formattedYear++;
		}
		let uncollapsible = () => (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					flex: "1 0 auto",
				}}
			>
				<Droppable
					key={droppableId}
					droppableId={droppableId}
					onDragOver={(e) => {
						// Prevent default browser scrolling behavior
						e.preventDefault();
					}}
				>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							className={`overflow-y-auto`}
							style={{
								flex: "1 0 auto",
								// uncomment the below line for debugging
								// backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'blue'
							}}
						>
							{(this.state[droppableId] !== undefined
								? this.state[droppableId]
								: []
							).map((item, index) => (
								<Draggable
									key={item.id}
									draggableId={item.id}
									index={index}
								>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="flex border-solid border-slate-300/40 dark:border-slate-400/40 border-2 rounded-lg justify-between bg-slate-50 m-1 p-0.5 dark:bg-zinc-900 z-40"
										>
											<p className="w-full px-1 text-sm line-clamp-1 truncate dark:text-white">
												<span className="font-bold">
													{item.code}
												</span>
												: {item.title}
											</p>
											<button
												type="button"
												onClick={() => {
													const newState = this.state;
													const [removed] = newState[
														droppableId
													].splice(index, 1);
													deleteDoc(
														doc(
															db,
															"Planned",
															this.state.user.uid,
															droppableId,
															removed.code
														)
													);
													this.setState(newState);
												}}
												className="px-1"
											>
												<svg
													className="w-4 h-4 text-slate-300 dark:text-white hover:text-red-500 dark:hover:text-red-500"
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 18 20"
												>
													<path
														stroke="currentColor"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
													/>
												</svg>
											</button>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
		);

		let getUnits = (droppableId) => {
			let units = 0;
			for (const item of this.state[droppableId] !== undefined
				? this.state[droppableId]
				: []) {
				units += item.units;
			}
			return units;
		};

		return (
			// <div className="my-2">
			<Collapsible
				default={formattedQuarter !== "Summer"}
				formattedQuarter={formattedQuarter}
				formattedYear={formattedYear}
				custom_title={custom_title}
				units={getUnits(droppableId)}
			>
				{uncollapsible()}
			</Collapsible>
			// </div>
		);
	};

	singleUGReq = (req) => {
		const reqClasses = () => {
			var classes = [];
			const newState = this.state;
			var changes_made = false;

			for (var key of Object.keys(this.state)) {
				// loop over quarters
				if (key.length === 6 && key[4] === "-") {
					// if key is a quarter

					const split = key.split("-");
					const year = parseInt(split[0]);
					const quarter = parseInt(split[1]);

					if (
						year > this.state.currentFlow[0] ||
						(year === this.state.currentFlow[0] &&
							quarter > this.state.currentFlow[1])
					) {
						if (this.state.showFutureClasses === false) {
							break; // break works here due to the way the quarters are ordered
						}
					}

					for (const i in this.state[key]) {
						// loop over classes in a quarter
						var classSatisfiesReq =
							this.state[key][i].hasOwnProperty("gers") &&
							this.state[key][i].gers.includes(req);
						// const regex = /LA?NG\d$/;
						const regex = /LA?NG\d[a-zA-Z]?$/;
						if (
							req === "Language" &&
							this.state[key][i]["code"].match(regex)
						) {
							classSatisfiesReq = true;
						} else if (
							req === "Writing SLE" &&
							this.state[key][i]["code"].startsWith("SLE")
						) {
							classSatisfiesReq = true;
							newState[key][i]["chosen_ger"]["Writing SLE"] =
								true;
						}

						if (classSatisfiesReq) {
							const single_class = this.state[key][i];

							// add some extra properties to the single_class object
							single_class["quarter"] = key;
							single_class["i"] = i;
							classes.push(single_class);

							if (!single_class.hasOwnProperty("chosen_ger")) {
								changes_made = true;
								var way_chosen = false;
								newState[key][i]["chosen_ger"] = {};

								if (newState[key][i]["gers"] === undefined)
									continue;
								for (const ger of newState[key][i]["gers"]) {
									if (!GER_LIST.includes(ger)) continue;
									newState[key][i]["chosen_ger"][ger] =
										!ger.startsWith("WAY");
									if (!way_chosen && ger.startsWith("WAY")) {
										way_chosen = true;
										newState[key][i]["chosen_ger"][ger] =
											true;
									}
								}
							}
						}
					}
				}
			}
			if (changes_made) {
				this.setState(newState);
			}
			return classes;
		};

		const ger_is_selected = (item, ger) => {
			var isSelected =
				item.hasOwnProperty("chosen_ger") &&
				item["chosen_ger"].constructor === Object &&
				item["chosen_ger"].hasOwnProperty(ger) &&
				item["chosen_ger"][ger];
			// const regex = /LA?NG\d$/;
			const regex = /LA?NG\d[a-zA-Z]?$/;
			if (ger === "Language" && item["code"].match(regex)) {
				isSelected = true;
			}
			return isSelected;
		};

		const classes = reqClasses();
		const required_units = GER_REQUIREMENTS[req]["units"];
		const required_courses = GER_REQUIREMENTS[req]["courses"];
		var fulfilled_units = 0;
		var fulfilled_courses = 0;
		var departments = {};
		var maxInSingleDepartment = 0;
		for (const item of reqClasses()) {
			if (ger_is_selected(item, req)) {
				fulfilled_units += item.units;
				fulfilled_courses++;
				if (!(item["subject"] in departments)) {
					departments[item["subject"]] = 0;
				}
				departments[item["subject"]] += 1;
				maxInSingleDepartment = Math.max(
					maxInSingleDepartment,
					departments[item["subject"]]
				);
			}
		}

		if (req === "Language") {
			fulfilled_courses = maxInSingleDepartment;
		}

		const units_string =
			required_units === 0
				? ""
				: `${fulfilled_units}/${required_units} units`;
		const in_between_string =
			required_units === 0 || required_courses === 0 ? "" : " • ";
		const courses_string =
			required_courses === 0
				? ""
				: `${fulfilled_courses}/${required_courses} courses`;

		return (
			//rounded-lg border-2
			<div className="m-1">
				<div className="flex flex-row relative ">
					<h1 className="pl-2 text-sm font-medium">{req}</h1>
					<h1 className="absolute right-0 pr-1 text-sm font-normal">
						{req === "Language" &&
						this.state.manualLanguageSatisfied ? (
							<span className={REQUIREMENT_MET_STYLE}>
								Satisfied.
							</span>
						) : (
							<>
								<span
									className={
										fulfilled_units >= required_units
											? REQUIREMENT_MET_STYLE
											: REQUIREMENT_NOT_MET_STYLE
									}
								>
									{units_string}
								</span>
								{in_between_string}
								<span
									className={
										fulfilled_courses >= required_courses
											? REQUIREMENT_MET_STYLE
											: REQUIREMENT_NOT_MET_STYLE
									}
								>
									{courses_string}
								</span>
							</>
						)}
					</h1>
				</div>
				<div className="flex flex-row flex-wrap">
					{classes.map((item) => (
						<div
							onMouseEnter={() => {
								this.setState({ hovered: item.code });
							}}
							onMouseLeave={() => {
								this.setState({ hovered: null });
							}}
							style={{
								backgroundColor: colorToRgb(
									ger_is_selected(item, req)
										? gerBasicColor(req)
										: "gray",
									400,
									0.2
								),
								borderColor: `1px solid ${colorToRgb(ger_is_selected(item, req) ? gerBasicColor(req) : "gray", 400, 0.1)}`,
							}}
							//border-solid border-slate-300/40 dark:border-slate-400/40 border-2
							// ${ger_is_selected(item, req) ? gerColor(req) : "bg-slate-200 dark:bg-zinc-900"}

							className={`flex 
                                        transition duration-300 ease-in-out 
                                        ${this.state.hovered === item.code ? "scale-110" : "scale-100"} 
                                        rounded-lg justify-between 
                                        m-1 z-40`}
						>
							<div
								className={`p-0.5 rounded-md ${this.state.hovered === item.code ? "bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,.5)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_100%] bg-[position:200%_0,0_0] bg-no-repeat transition-[background-position_0s_ease] duration-[1500ms]" : "bg-[position:-100%_0,0_0]"}`}
							>
								<button
									onClick={() => {
										const newState = this.state;

										// if chosen ger is not the right format yet
										if (
											newState[item.quarter][item.i][
												"chosen_ger"
											].constructor !== Object
										) {
											for (const ger of newState[
												item.quarter
											][item.i]["gers"]) {
												if (!GER_LIST.includes(ger))
													continue;
												newState[item.quarter][item.i][
													"chosen_ger"
												][ger] = !ger.startsWith("WAY");
											}
										}
										// if the current ger is a WAY, choose this current WAY and toggle off all other WAYS. otherwise, do nothing.
										if (req.startsWith("WAY")) {
											newState[item.quarter][item.i][
												"chosen_ger"
											][req] = true;
											for (const ger of newState[
												item.quarter
											][item.i]["gers"]) {
												if (
													ger.startsWith("WAY") &&
													ger !== req
												) {
													newState[item.quarter][
														item.i
													]["chosen_ger"][ger] =
														false;
												}
											}
										}

										this.setState(newState);
										setDoc(
											doc(
												db,
												"Planned",
												this.state.user.uid,
												item.quarter,
												item.code
											),
											newState[item.quarter][item.i]
										);
									}}
									className="w-full px-1 text-xs font-medium line-clamp-1 truncate dark:text-white"
								>
									<span
										style={{
											color: colorToRgb(
												`${ger_is_selected(item, req) ? gerBasicColor(req) : "gray"}`,
												600,
												1
											),
										}}
									>
										{item.code}
									</span>
								</button>
							</div>
						</div>
					))}
				</div>
				{req === "Language" && (
					<div className="pl-2 text-sm flex flex-row">
						<p>Already Satisfied?</p>
						<div className="pl-2">
							<input
								id={`vue-checkbox-manualLanguageSatisfied`}
								type="checkbox"
								value=""
								checked={this.state["manualLanguageSatisfied"]}
								onChange={() => {
									this.setState(
										{
											manualLanguageSatisfied:
												!this.state
													.manualLanguageSatisfied,
										},
										() => {
											this.updateManualLanguageSatisfiedInFirebase();
										}
									);
								}}
								className="self-center w-5 h-5 text-blue-500 dark:text-blue-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
							/>
						</div>
					</div>
				)}
			</div>
		);
	};

	toggleDropdown = () => {
		this.setState(
			(prevState) => ({
				programTrackIsOpen: false,
				programSearchIsOpen: !prevState.programSearchIsOpen,
			}),
			() => {
				if (
					this.state.programSearchIsOpen &&
					this.programTextFieldRef.current
				) {
					this.programTextFieldRef.current.focus();
				}
			}
		);
	};

	handleCheckboxChange = async (event) => {
		const { value } = event.target;
		await this.setState((prevState) => {
			const newState = prevState.reqPrograms.includes(value)
				? {
						reqPrograms: prevState.reqPrograms.filter(
							(program) => program !== value
						),
					}
				: { reqPrograms: [...prevState.reqPrograms, value] };

			if (!prevState.reqPrograms.includes(value)) {
				// adding it
				newState["reqMode"] = value;
			} else {
				newState["reqMode"] = "Undergrad";
			}

			return newState;
		});

		if (this.state.reqPrograms.includes(value)) {
			// After state is updated
			await this.fetchProgramJSON(value);
		}

		// Save past programs
		savePrograms(this.state.reqPrograms);
	};

	toggleTrackDropdown = () => {
		this.setState((prevState) => ({
			programTrackIsOpen: !prevState.programTrackIsOpen,
			programSearchIsOpen: false,
		}));
	};

	handleTrackRadioChange = (event) => {
		const { value } = event.target;
		this.setState(
			(prevState) => {
				const newTrackLookup = {
					...prevState.trackLookup,
					[this.state.reqMode]: value,
				};

				const newState = {
					...prevState,
					trackLookup: newTrackLookup,
					programTrackIsOpen: false,
				};

				// Perform any other actions with newState here

				return newState;
			},
			() => {
				// Save past programs
				saveTracks(this.state.trackLookup);
			}
		);
	};

	// Function to update searchValue state when input value changes
	programTextChange = (event) => {
		this.setState({ programSearchText: event.target.value });
	};

	// Function to update searchValue state when input value changes
	programTrackSearchTextChange = (event) => {
		this.setState({ programTrackSearchText: event.target.value });
	};

	requirementsTab = (isMobile) => {
		const { reqMode } = this.state;

		// only allow long name as the key is CS (BS) and CS matches everything
		const filteredPrograms = Object.fromEntries(
			Object.entries(PROGRAM_NAME_TO_LONG)
				.filter(([program, longName]) =>
					// program.toLowerCase().includes(this.state.programSearchText.toLowerCase()) ||
					longName
						.toLowerCase()
						.includes(this.state.programSearchText.toLowerCase())
				)
				.sort(([a], [b]) => a.localeCompare(b))
		);

		const filterTracks =
			this.state.programSubplans !== undefined
				? this.state.programSubplans.filter((track) =>
						track
							.toLowerCase()
							.includes(
								this.state.programTrackSearchText.toLowerCase()
							)
					)
				: [];

		return (
			<div>
				<div className="flex justify-between items-center">
					<ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 mb-1">
						<li>
							<a
								className={`inline-flex items-center justify-center px-2 pt-3 pb-2 text-blue-600  rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group ${
									reqMode === "Undergrad"
										? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500 border-b-2 border-blue-600"
										: "text-gray-600 border-gray-300 dark:text-gray-300"
								}`}
								onClick={() =>
									this.setState({ reqMode: "Undergrad" })
								}
							>
								Undergrad
							</a>
						</li>
						{this.state.reqPrograms.map((program) => (
							<li>
								<a
									className={`inline-flex items-center justify-center px-2 pt-3 pb-2 text-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group ${
										reqMode === program
											? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500 border-b-2 border-blue-600"
											: "text-gray-600 border-gray-300 dark:text-gray-300"
									}`}
									onClick={async () => {
										this.setState({ reqMode: program });
										await this.fetchProgramJSON(program);
									}}
								>
									{program}
								</a>
							</li>
						))}

						<li className="pt-2">
							<button
								id="dropdownSearchButton"
								onClick={this.toggleDropdown}
								className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none 
                                        focus:ring-blue-300 font-medium rounded-lg text-sm mx-1 px-3 py-1 text-center 
                                        inline-flex items-center dark:text-blue-500 dark:border-blue-500 dark:hover:bg-blue-500 dark:hover:text-white 
                                        dark:focus:ring-blue-800"
								type="button"
							>
								Edit
								<svg
									className={`w-2.5 h-2.5 ms-3 ${this.state.programSearchIsOpen ? "rotate-180" : ""}`}
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 10 6"
								>
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="m1 1 4 4 4-4"
									/>
								</svg>
							</button>
						</li>
					</ul>

					{this.state.programSearchIsOpen && (
						<div
							id="dropdownSearch"
							className="absolute z-50 bg-white rounded-lg shadow w-30 dark:bg-gray-700"
						>
							<div className="p-3">
								<label
									htmlFor="input-group-search"
									className="sr-only"
								>
									Search
								</label>
								<div className="relative">
									<div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
										<svg
											className="w-4 h-4 text-gray-500 dark:text-gray-400"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
											/>
										</svg>
									</div>
									<input
										type="text"
										id="input-group-search"
										className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										placeholder="Search programs"
										value={this.state.programSearchText} // Bind input value to searchValue state
										onChange={this.programTextChange}
										ref={this.programTextFieldRef} // Attach the ref to the input
									/>
								</div>
							</div>
							<ul
								className="h-48 z-50 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
								aria-labelledby="dropdownSearchButton"
							>
								{Object.entries(filteredPrograms).map(
									([program, longName], i) => {
										return (
											<li key={program}>
												<div className="flex ps-2 items-center rounded hover:bg-gray-100 dark:hover:bg-gray-600">
													<input
														id={`checkbox-item-${program}`}
														type="checkbox"
														value={program}
														checked={this.state.reqPrograms.includes(
															program
														)}
														onChange={
															this
																.handleCheckboxChange
														}
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
													/>
													<label
														htmlFor={`checkbox-item-${program}`}
														className="w-full py-2 ms-2 text-sm text-left font-medium text-gray-900 rounded dark:text-gray-300"
													>
														{longName}
													</label>
												</div>
											</li>
										);
									}
								)}
							</ul>
						</div>
					)}
				</div>

				{/* TRACK Selector */}
				{reqMode !== "Undergrad" &&
					this.state.programSubplans !== undefined &&
					this.state.programSubplans.length > 0 && (
						<div>
							<button
								id="dropdownSearchButton"
								onClick={this.toggleTrackDropdown}
								className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm mx-1 my-1 px-3 py-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
								type="button"
							>
								{/* Select Track */}
								{this.state.trackLookup[this.state.reqMode] ===
								undefined
									? "Select Track"
									: this.state.trackLookup[
											this.state.reqMode
										]}
								<svg
									className={`w-2.5 h-2.5 ms-3 ${this.state.programSearchIsOpen ? "rotate-180" : ""}`}
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 10 6"
								>
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="m1 1 4 4 4-4"
									/>
								</svg>
							</button>

							{this.state.programTrackIsOpen && (
								<div
									id="trackDropDown"
									className="absolute z-50 bg-white rounded-lg shadow w-30 dark:bg-gray-700"
								>
									<div className="p-3">
										<label
											htmlFor="input-group-search"
											className="sr-only"
										>
											Search
										</label>
										<div className="relative">
											<div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
												<svg
													className="w-4 h-4 text-gray-500 dark:text-gray-400"
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 20 20"
												>
													<path
														stroke="currentColor"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
													/>
												</svg>
											</div>
											<input
												type="text"
												id="input-group-search"
												className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
												placeholder="Search track"
												value={
													this.state
														.programTrackSearchText
												} // Bind input value to searchValue state
												onChange={
													this
														.programTrackSearchTextChange
												}
											/>
										</div>
									</div>
									<ul
										className="h-48 z-50 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
										aria-labelledby="dropdownSearchButton"
									>
										<li key="please-select">
											<div className="flex p-2 items-center rounded hover:bg-gray-100 dark:hover:bg-gray-600">
												<input
													id={`track-radio-item-please-select`}
													type="radio"
													value="Select Track"
													checked={
														this.state.trackLookup[
															this.state.reqMode
														] === "Select Track"
													}
													onChange={
														this
															.handleTrackRadioChange
													}
													className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
												/>
												<label
													htmlFor={`track-radio-item-please-select`}
													className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
												>
													Select Track
												</label>
											</div>
										</li>
										{filterTracks.map((track, i) => (
											<li key={i}>
												<div className="flex p-2 items-center rounded hover:bg-gray-100 dark:hover:bg-gray-600">
													<input
														id={`track-radio-item-${i}`}
														type="radio"
														value={track}
														checked={
															this.state
																.trackLookup[
																this.state
																	.reqMode
															] === track
														}
														onChange={
															this
																.handleTrackRadioChange
														}
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
													/>
													<label
														htmlFor={`track-radio-item-${i}`}
														className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
													>
														{track}
													</label>
												</div>
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
					)}
			</div>
		);
	};

	req_item = (reqKey, reqItem, countReached = false, color_index = 0) => {
		var units_string = "";
		var courses_string = "";

		var {
			satisfiedUnits,
			minUnits,
			satisfied,
			restriction,
			codesUsed,
			suffix,
			completed,
		} = reqItem;

		if (codesUsed === undefined) codesUsed = [];

		if (codesUsed === undefined || codesUsed.length === 0) {
			if (reqItem.hasOwnProperty("items")) {
				for (const item of reqItem["items"]) {
					// extend codesUsed by item
					codesUsed = codesUsed.concat(item["codesUsed"]);
				}
			}
		}

		if (satisfiedUnits !== undefined && minUnits !== undefined) {
			units_string = `${satisfiedUnits}/${minUnits} units`;
		}

		if (satisfied !== undefined && restriction !== undefined) {
			if (suffix !== undefined) {
				courses_string = `${satisfied}/${restriction} courses`;
			} else {
				courses_string = `${satisfied}/${restriction} items`;
			}
		}

		const in_between_string =
			units_string === "" || courses_string === "" ? "" : " • ";

		const showDetailsButton = () => {
			if (codesUsed.length === 0) return <></>;
			return (
				<div
					onClick={() => {
						if (this.state.showDetails === undefined) {
							this.setState({ showDetails: { [reqKey]: true } });
						} else {
							this.setState({
								showDetails: {
									...this.state.showDetails,
									[reqKey]: !this.state.showDetails[reqKey],
								},
							});
						}
					}}
				>
					<svg
						className={`w-2 h-2 dark:text-gray-200 text-gray-800 ${this.state.showDetails && this.state.showDetails.hasOwnProperty(reqKey) && this.state.showDetails[reqKey] ? "" : "rotate-180"}`}
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 10 6"
					>
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="m1 1 4 4 4-4"
						/>
					</svg>
				</div>
			);
		};

		// if countReached and this requirement is not hit, then make more transparent and light through

		// if codesUsed.length === 0 -> show the details and don't allow a close

		return (
			<div className="mt-1 ml-[0.5]">
				<div className="flex flex-row relative justify-between">
					<h1
						className={`pl-2 text-sm font-semibold
                        ${countReached && !completed ? "line-through opacity-75" : ""}
                    `}
					>
						{reqKey}
					</h1>
					<h1
						className={` pr-1 text-sm font-normal inline-block whitespace-nowrap
                        ${countReached && !completed ? "opacity-75" : ""}
                    `}
					>
						{
							<span>
								<span
									className={
										satisfiedUnits >= minUnits
											? REQUIREMENT_MET_STYLE
											: REQUIREMENT_NOT_MET_STYLE
									}
								>
									{units_string}
								</span>
								{in_between_string}
								<span
									className={
										satisfied >= restriction
											? REQUIREMENT_MET_STYLE
											: REQUIREMENT_NOT_MET_STYLE
									}
								>
									{courses_string}
								</span>
							</span>
						}
					</h1>
				</div>
				<div className="flex justify-between items-center pr-2">
					<div className="flex flex-row flex-wrap">
						{codesUsed &&
							codesUsed.map((item) => (
								<div
									onMouseEnter={() => {
										this.setState({ hovered: item });
									}}
									onMouseLeave={() => {
										this.setState({ hovered: null });
									}}
									style={{
										backgroundColor: colorToRgb(
											programColor(color_index),
											400,
											0.2
										),
										borderColor: `1px solid ${colorToRgb(programColor(color_index), 400, 0.1)}`,
									}}
									className={`flex transition duration-300 ease-in-out 
                            ${this.state.hovered === item ? "scale-110" : "scale-100"} 
                            rounded-lg justify-between 
                            m-1 z-40`}
								>
									<div
										className={`p-0.5 rounded-md ${this.state.hovered === item ? "bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,.5)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_100%] bg-[position:200%_0,0_0] bg-no-repeat transition-[background-position_0s_ease] duration-[1500ms]" : "bg-[position:-100%_0,0_0]"}`}
									>
										<button className="w-full px-1 text-xs font-medium line-clamp-1 truncate dark:text-white">
											<span
												style={{
													color: colorToRgb(
														programColor(
															color_index
														),
														600,
														1
													),
												}}
											>
												{item}
											</span>
										</button>
									</div>
								</div>
							))}
					</div>
					{/* {showDetailsButton()} */}
				</div>
			</div>
		);
	};

	present_req = (
		headingKey,
		headingReqs,
		completedCountMode = false,
		color_index = 0
	) => {
		if (headingReqs === undefined) return <></>;

		if (headingReqs.hasOwnProperty("codesUsed")) {
			// if this is a single rule
			return (
				<>
					{this.req_item(headingKey, headingReqs, false, color_index)}
				</>
			);
			// return units_req(headingKey, headingReqs);
		}

		var countReached = false;
		if (completedCountMode) {
			countReached =
				headingReqs["completedCount"] >= headingReqs["minCount"];
		}

		return (
			<div>
				{Object.keys(headingReqs).map((ruleKey) => {
					const rule = headingReqs[ruleKey];
					if (rule === undefined) return <></>;

					if (
						rule.hasOwnProperty("satisfied") ||
						rule.hasOwnProperty("satisfiedUnits") ||
						rule.hasOwnProperty("completedCount")
					) {
						return (
							<>
								{this.req_item(
									ruleKey,
									rule,
									countReached,
									false,
									color_index
								)}
							</>
						);
						// return <>{this.req_item(ruleKey, rule, rule["completed"], false, color_index)}</>;
						// return courses_req(ruleKey, rule);
					}
					// else {
					//     return units_req(ruleKey, rule);
					// }
				})}
			</div>
		);
	};

	program_reqs = () => {
		// const currentProgram = "CS-BS";
		var lastProgram = this.state.lastProgram;
		var programReqs = this.state.programReqs;

		const getClasses = () => {
			var codeToUnits = {};
			for (var key of Object.keys(this.state)) {
				// goes through every year
				if (key.length === 6 && key[4] === "-") {
					// if key is a quarter
					const split = key.split("-");
					const year = parseInt(split[0]);
					const quarter = parseInt(split[1]);

					if (
						year > this.state.currentFlow[0] ||
						(year === this.state.currentFlow[0] &&
							quarter > this.state.currentFlow[1])
					) {
						if (this.state.showFutureClasses === false) {
							break; // break works here due to the way the quarters are ordered
						}
					}

					for (const i in this.state[key]) {
						// for each class in the quarter
						const single_class = this.state[key][i];
						codeToUnits[single_class.code] = single_class.units;
					}
				}
			}
			return codeToUnits;
		};

		const codeToUnits = getClasses();
		const codes = Object.keys(codeToUnits);

		// stops cycling, but allows it to update when new classes are added
		if (
			this.state.programSelected !== lastProgram ||
			this.state.reqLastCodesLength !== codes.length
		) {
			const [program_reqs, subplans] = checkAll(
				this.state.programData,
				codeToUnits
			);
			programReqs = program_reqs;

			this.setState((prevState) => {
				if (prevState.programReqs !== programReqs) {
					return {
						programReqs: programReqs,
						// reqProgram: currentProgram,
						reqLastCodesLength: codes.length,
						lastProgram: this.state.programSelected,
						programSubplans: subplans,
					};
				} else {
					return null; // No state change, return null
				}
			});
		}

		// return the program reqs formatted

		// TODO: Handle count rule of number of subRules satisifed
		var color_index = -1;

		return (
			<div className="pl-3">
				{Object.keys(programReqs).map((sectionKey) => {
					// e.g. Core, TRACK: ...
					// if (sectionKey === "Subplans") return <></>;
					// check if sectionKey starts with TRACK
					if (
						sectionKey.startsWith("TRACK:") &&
						`TRACK: ${this.state.trackLookup[this.state.reqMode]}` !==
							sectionKey
					)
						return <></>;
					const sectionReqs = programReqs[sectionKey];

					return (
						<div className="pt-1">
							<span class="text-sm font-semibold">
								{sectionKey.toUpperCase()}
							</span>
							{sectionReqs.hasOwnProperty("minUnits") && ( // section unit requirement
								<>
									{(() => {
										const percentage = Math.min(
											Math.round(
												(sectionReqs["satisfiedUnits"] /
													sectionReqs["minUnits"]) *
													100
											),
											100
										);
										return (
											<div className="pr-2 py-1">
												<div className="flex justify-between mb-1">
													<span className="text-sm font-semibold">{`${sectionReqs["minUnits"]} unit minimum`}</span>
													<span className="text-sm font-medium text-blue-700 dark:text-white">
														{
															sectionReqs[
																"satisfiedUnits"
															]
														}{" "}
														units ({percentage}%)
													</span>
												</div>
												<div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
													<div
														className="bg-blue-500 h-2 rounded-full"
														style={{
															width: `${percentage}%`,
														}}
													></div>
												</div>
											</div>
										);
									})()}
								</>
							)}

							{Object.keys(sectionReqs).map((headingKey) => {
								const headingReqs = sectionReqs[headingKey];
								// check if headingReqs is a dictionary
								if (
									!isDictionary(headingReqs) ||
									Object.keys(headingReqs).length === 0
								)
									return <></>;

								var completedCountMode = false;

								if (
									headingReqs.hasOwnProperty(
										"completedCount"
									) &&
									headingReqs.hasOwnProperty("minCount")
								) {
									completedCountMode = true;
								}
								color_index += 1;

								return (
									<div>
										<div className="flex flex-row relative">
											<h1 className="text-sm font-medium">
												{headingKey}
											</h1>
											<h1 className="absolute right-0 pr-1 text-sm font-normal">
												{
													// check if is nested requirement and has units req
													// (!headingReqs.hasOwnProperty("codesUsed") && headingReqs.hasOwnProperty("minUnits") ) &&
													!headingReqs.hasOwnProperty(
														"codesUsed"
													) &&
														headingReqs.hasOwnProperty(
															"minUnits"
														) && (
															// <span className={headingReqs["satisfiedUnits"] >= headingReqs["minUnits"] ? REQUIREMENT_MET_STYLE : REQUIREMENT_NOT_MET_STYLE}>
															<span
																className={
																	headingReqs[
																		"completed"
																	]
																		? REQUIREMENT_MET_STYLE
																		: REQUIREMENT_NOT_MET_STYLE
																}
															>
																{`${headingReqs["satisfiedUnits"]}/${headingReqs["minUnits"]} units`}
															</span>
														)
												}
												{!headingReqs.hasOwnProperty(
													"codesUsed"
												) &&
													headingReqs.hasOwnProperty(
														"restriction"
													) && (
														// <span className={headingReqs["satisfied"] >= headingReqs["restriction"] ? REQUIREMENT_MET_STYLE : REQUIREMENT_NOT_MET_STYLE}>
														<span
															className={
																headingReqs[
																	"completed"
																]
																	? REQUIREMENT_MET_STYLE
																	: REQUIREMENT_NOT_MET_STYLE
															}
														>
															{`${headingReqs["satisfied"]}/${headingReqs["restriction"]} courses`}
														</span>
													)}
												{completedCountMode && (
													// <span className={headingReqs["completedCount"] >= headingReqs["minCount"] ? REQUIREMENT_MET_STYLE : REQUIREMENT_NOT_MET_STYLE}>
													<span
														className={
															headingReqs[
																"completed"
															]
																? REQUIREMENT_MET_STYLE
																: REQUIREMENT_NOT_MET_STYLE
														}
													>
														{`${headingReqs["completedCount"]}/${headingReqs["minCount"]} item${headingReqs["minCount"] > 1 ? "s" : ""}`}
													</span>
												)}
											</h1>
										</div>
										{/* <span class="text-sm font-medium">{headingKey}</span> */}

										{this.present_req(
											headingKey,
											headingReqs,
											completedCountMode,
											color_index
										)}
									</div>
								);
							})}

							<hr className="dark:border-slate-700 mt-2"></hr>
						</div>
					);
				})}
			</div>
		);
	};

	UGReqs = (isMobile) => {
		var totalUnits = 0;
    var totalClasses = 0;

		for (var key of Object.keys(this.state)) {
			// goes through every year
			if (key.length === 6 && key[4] === "-") {
				// if key is a quarter
				const split = key.split("-");
				const year = parseInt(split[0]);
				const quarter = parseInt(split[1]);

				if (
					year > this.state.currentFlow[0] ||
					(year === this.state.currentFlow[0] &&
						quarter > this.state.currentFlow[1])
				) {
					if (this.state.showFutureClasses === false) {
						break; // break works here due to the way the quarters are ordered
					}
				}

				for (const i in this.state[key]) {
					// for each class in the quarter
					totalUnits += this.state[key][i].units;
          totalClasses += 1;
				}
			}
		}

		const percentage = Math.min(Math.round((totalUnits / 180) * 100), 100);

		return (
			<div
				className={`transition-all transition-duration-300 dark:bg-[#1c1c1c] shadow-md rounded-lg shadow-blue-500/40 
            dark:shadow-blue-800/20 border ${isMobile ? "w-full" : "w-[20%]"} dark:border-slate-700`}
				style={{
					display: "flex",
					"flex-direction": "column",
				}}
			>
				<div
					className={`rounded-t-lg bg-white dark:bg-zinc-900
                px-2 border-b-[1px] dark:border-b-slate-700 relative py-1 cursor-pointer`}
				>
					{/* SWITCHER */}
					{/* {this.state.beta ? ( */}
					{this.requirementsTab(isMobile)}
					{/* ) : (
            <div>
              <Tooltip id="tool-tip-grade-info" />
              <p className="rounded-t-lg relative left-0 text-sm text-left font-bold text-gray-900 dark:text-white">
                {"UNDERGRAD REQUIREMENTS"}
              </p>

              <button
                className={`group absolute top-1 right-6 ${isMobile ? "mt-1" : ""}`}
                data-tooltip-id="tool-tip-grade-info"
                data-tooltip-content={
                  "We advise cross-referencing with “My Degree Progress Report” for updated details"
                }
              >
                <svg
                  className="w-5 h-5 text-gray-800 dark:text-white absolute top-0 left-0 opacity-100 group-hover:opacity-0 transition duration-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <svg
                  className="w-5 h-5 text-gray-800 dark:text-white absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition duration-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
              </button>
            </div>
          )} */}

					{/* <Tooltip id="tool-tip-grade-info"/>
                            <p className="rounded-t-lg relative left-0 text-sm text-left font-bold text-gray-900 dark:text-white">
                                {"UNDERGRAD REQUIREMENTS"}
                            </p> */}

					{/* <button className={`group absolute top-1 right-6 ${isMobile ? "mt-1" : ""}`} data-tooltip-id="tool-tip-grade-info" data-tooltip-content={"We advise cross-referencing with “My Degree Progress Report” for updated details"}>
                                <svg className="w-5 h-5 text-gray-800 dark:text-white absolute top-0 left-0 opacity-100 group-hover:opacity-0 transition duration-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <svg className="w-5 h-5 text-gray-800 dark:text-white absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition duration-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                </svg>
                            </button> */}
				</div>

				{this.state.reqMode === "Undergrad" ? (
					<div>
						{/* UNDERGRAD */}
						<div className="pt-2">
							<div className="pl-3 pr-2">
								<div class="flex justify-between mb-1">
									<span class="text-sm font-semibold">
										UNITS
									</span>
									<span
										class="text-sm font-medium text-blue-700 dark:text-white"
										data-tooltip-id="planner-tooltip"
										data-tooltip-content={`${totalUnits} units from ${totalClasses} courses`}
									>
										{totalUnits} units ({percentage}%)
									</span>
								</div>
								<div class="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
									<div
										class="bg-blue-500 h-2 rounded-full"
										style={{ width: `${percentage}%` }}
									></div>
								</div>
							</div>

							<hr className="dark:border-slate-700 mt-2"></hr>
						</div>

						<div className="flex flex-col flex-wrap w-full">
							{this.state.currentGERList.map((req, i) => {
								return (
									<>
										{this.singleUGReq(req)}
										{i <
											this.state.currentGERList.length -
												1 && (
											<hr className="dark:border-slate-700"></hr>
										)}
									</>
								);
							})}
						</div>
					</div>
				) : (
					this.program_reqs()
				)}
			</div>
		);
	};

	handlePlannerChange = (event) => {
		const searchTerm = event.target.value;
		// const db = firebase.firestore();

		const newState = this.state;
		if (searchTerm === "") {
			// setSearchItems([]);
			// setSearchIsOpen(false);

			newState["plannerSearchIsOpen"] = false;
			newState["plannerSearchItems"] = [];
			this.setState(newState);
			return;
		} else {
			if (!this.state["plannerSearchIsOpen"]) {
				// setSearchIsOpen(true);
				newState["plannerSearchIsOpen"] = true;
				this.setState(newState);
			}

			var queries = [];

			var codeSearch = codeSearchProcessText(searchTerm);
			// var codeSearch = searchTerm.replaceAll(" ", "").toUpperCase();
			// if (codeSearch.substring(0, 3) === "MSE") {
			//     codeSearch = "MS&E" + codeSearch.substring(3);
			// }

			var titleTerm = searchTerm.toLowerCase();

			const q1 = query(
				collection(db, SEARCH_PATH),
				where("code", ">=", codeSearch),
				where("code", "<", codeSearch + "\uf8ff"),
				limit(3)
			);
			queries.push(q1);
			// if does not contain digits
			if (!/\d/.test(codeSearch)) {
				const q2 = query(
					collection(db, SEARCH_PATH),
					where("titleSearch", ">=", titleTerm),
					where("titleSearch", "<", titleTerm + "\uf8ff"),
					limit(3)
				);
				queries.push(q2);
			}

			const getDocsFromQuery = async (q) => {
				const querySnapshot = await getDocs(q);
				let docs = [];
				querySnapshot.forEach((doc) => {
					docs.push({
						id: doc.id + Date.now().toString(),
						...doc.data(),
					});
				});
				return docs;
			};

			Promise.all(queries.map((q) => getDocsFromQuery(q)))
				.then((resultsArrays) => {
					const combinedResults = [].concat(...resultsArrays);
					// setSearchItems(combinedResults);
					newState["plannerSearchItems"] = combinedResults;
					this.setState(newState);
				})
				.catch((error) => {
					console.error("Error getting documents: ", error);
				});
		}
	};

	closeSearchView = () => {
		const newState = this.state;
		newState["plannerSearchIsOpen"] = false;
		this.setState(newState);
	};

	addToUnplanned = (item) => {
		// TODO: CHECK IF ALREADY IN PLANNER !!!
		const newState = this.state;
		item.units =
			item.units && item.units.length > 0
				? item.units[item.units.length - 1]
				: 0;

		const chosen_ger = {};
		var way_chosen = false;
		for (const ger of item.gers) {
			if (!GER_LIST.includes(ger)) continue;
			if (ger.startsWith("WAY")) {
				chosen_ger[ger] = !way_chosen;
				way_chosen = true;
			} else {
				chosen_ger[ger] = true;
			}
		}

		item.chosen_ger = chosen_ger;

		newState["unplanned"].push(item);
		this.setState(newState);

		this.closeSearchView();

		// upload to firebase
		const { user, loading, error } = this.state;

		const unplannedRef = doc(
			db,
			"Planned",
			user.uid,
			"Unplanned",
			item.code
		);
		// const unitCount = (item.units && item.units.length > 0) ? item.units[item.units.length-1] : 0;
		const unitCount = item.units ? item.units : 0;
		const medianHours = item.medianHours ? item.medianHours : unitCount * 3;
		const meanHours = item.meanHours ? item.meanHours : unitCount * 3;
		const title = item.title;
		const gers = item.gers;

		setDoc(unplannedRef, {
			// show: Array(sectionCount).fill(true), // do not initialise show as it is unknown due to unknown quarter
			units: unitCount,
			// hours: hours,
			medianHours: medianHours,
			meanHours: meanHours,
			title: title,
			gers: gers,
			chosen_ger: chosen_ger,
		});
	};

	updateYearsInFirebase = () => {
		this.savePlannerYears(this.state.years);

		const { user } = this.state;
		const metadataRef = doc(db, "Planned", user.uid);
		setDoc(metadataRef, { years: this.state.years }, { merge: true });
	};

	updateShowFutureClassesInFirebase = () => {
		this.saveShowFutureClasses();

		const { user } = this.state;
		const metadataRef = doc(db, "Planned", user.uid);
		setDoc(
			metadataRef,
			{
				showFutureClasses:
					this.state.showFutureClasses === undefined
						? true
						: this.state.showFutureClasses,
			},
			{ merge: true }
		);
	};

	updateManualLanguageSatisfiedInFirebase = () => {
		// no cache
		const { user } = this.state;
		const metadataRef = doc(db, "Planned", user.uid);
		setDoc(
			metadataRef,
			{
				manualLanguageSatisfied:
					this.state.manualLanguageSatisfied === undefined
						? false
						: this.state.manualLanguageSatisfied,
			},
			{ merge: true }
		);
	};

	handlePlannerKeyDown = (event) => {
		if (event.key === "Enter") {
			var inputCode = codeSearchProcessText(event.target.value);
			const docRef = doc(db, SEARCH_PATH, `${inputCode}`);

			getDoc(docRef).then((docSnap) => {
				if (docSnap.data() === undefined) return;
				const item = docSnap.data();

				// const newState = this.state;
				// newState["unplanned"].push(item);
				// this.setState(newState);
				item.id = item.code + Date.now().toString();
				this.addToUnplanned(item);
			});

			this.closeSearchView();
		}
	};

	// State to control the dropdown visibility
	// const [plannerSearchIsOpen, sePlannerSearchIsOpen] = useState(false);
	// var [searchItems, setSearchItems] = useState([]);

	renderSearchBar = () => {
		return (
			<div className="relative hidden md:block mt-1 px-1">
				<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
					<svg
						className="w-4 h-4 text-gray-500 dark:text-gray-400"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 20 20"
					>
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
						/>
					</svg>
					<span className="sr-only">Search icon</span>
				</div>
				<input
					type="text"
					id="search-planner"
					data-dropdown-toggle="dropdownPlannerSearch"
					className="block w-full px-2 py-1.5 pl-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white 
                focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					placeholder="Search..."
					onChange={this.handlePlannerChange}
					onKeyDown={this.handlePlannerKeyDown}
				/>

				<div
					id="dropdownPlannerSearch"
					className={`absolute w-full mt-0 ${this.state.plannerSearchIsOpen ? "" : "hidden"} 
                bg-white divide-gray-100 rounded-md shadow w-44 dark:bg-zinc-800`}
				>
					{this.state.plannerSearchItems.map((item) => (
						<div
							className="pl-2 py-3 text-sm text-left text-gray-900 dark:text-white cursor-pointer hover:dark:bg-zinc-600 rounded-md"
							onClick={() => {
								this.addToUnplanned(item);
							}}
						>
							<div className="truncate line-clamp-1 block">
								<span className="font-semibold">
									{item.code}:{" "}
								</span>
								{item.title}
							</div>
						</div>
					))}
				</div>
			</div>
			// </div>
		);
	};

	unplannedColumn = () => {
		let unPlannedUncollapsible = () => (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					flex: "1 0 auto",
				}}
			>
				<Droppable
					key={"unplanned"}
					droppableId={"unplanned"}
					onDragOver={(e) => {
						// Prevent default browser scrolling behavior
						e.preventDefault();
					}}
				>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							className={`overflow-y-auto`}
							style={{
								flex: "1 0 auto",
								// uncomment the below line for debugging
								// backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'blue'
							}}
						>
							{(this.state["unplanned"] !== undefined
								? this.state["unplanned"]
								: []
							).map((item, index) => (
								<Draggable
									key={item.id}
									draggableId={item.id}
									index={index}
								>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="flex border-solid border-slate-300/40 dark:border-slate-400/40 border-2 rounded-lg justify-between bg-slate-50 m-1 p-0.5 dark:bg-zinc-900"
										>
											<p className="w-full px-1 text-sm line-clamp-1 truncate dark:text-white">
												<span className="font-bold">
													{item.code}
												</span>
												: {item.title}
											</p>
											<button
												type="button"
												onClick={() => {
													const newState = this.state;
													const [removed] = newState[
														"unplanned"
													].splice(index, 1);
													deleteDoc(
														doc(
															db,
															"Planned",
															this.state.user.uid,
															"Unplanned",
															removed.code
														)
													);
													this.setState(newState);
												}}
												className="px-1"
											>
												<svg
													className="w-4 h-4 text-slate-300 dark:text-white hover:text-red-500  dark:hover:text-red-500"
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 18 20"
												>
													<path
														stroke="currentColor"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
													/>
												</svg>
											</button>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
		);

		return (
			<div className="flex flex-col w-[15%] ml-3">
				<Collapsible
					default={true}
					formattedQuarter={""}
					formattedYear={""}
					custom_title={"Unplanned Classes"}
					units={0}
					renderSearchBar={this.renderSearchBar}
				>
					{unPlannedUncollapsible()}
				</Collapsible>
			</div>
		);
	};

	showFutureClassesButton = () => {
		return (
			<label className="relative inline-flex items-center cursor-pointer mt-2">
				<input
					type="checkbox"
					value=""
					className="sr-only peer"
					checked={this.state.showFutureClasses}
					onChange={() => {
						this.setState(
							{
								showFutureClasses:
									!this.state.showFutureClasses,
							},
							() => {
								this.updateShowFutureClassesInFirebase();
							}
						);
					}}
				/>
				<div
					className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 
                            peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] 
                            after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-400 
                            peer-checked:bg-blue-500"
				></div>
				<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
					Include future quarters
				</span>
			</label>
		);
	};

	// Normally you would want to split things out into separate components.
	// But in this example everything is just done in one place for simplicity
	themeColor = "text-gray-800 dark:text-white";

	render() {
		const isMobile = window.matchMedia("(max-width: 1000px)").matches;
		return (
			<DarkModeProvider>
				{/* <PinnedClasses.Provider value={{pinnedClasses, setPinnedClasses}}>
                    <CourseCode.Provider value={{courseCode, setCourseCode}}> */}
				<div className="w-screen h-screen flex flex-col overflow-auto">
					<PlannerNavbar />
					<div className="w-screen dark:bg-zinc-900 dark:text-white px-10 pt-4 flex flex-col flex-1">
						<Tooltip
							id="planner-tooltip"
							multiline={true}
							className="z-50"
							opacity={1}
							style={{
								whiteSpace: "pre-wrap",
							}}
							key="courseTooltip"
						/>
						<div className="font-sans text-left space-y-1.5 flex flex-col flex-1 h-full w-full relative">
							<div
								className={
									isMobile
										? "flex flex-col"
										: "ml-3 flex flex-row w-[80%]"
								}
							>
								<h1
									className={
										isMobile
											? "w-full text-center text-2xl font-bold"
											: "sm:text-sm md:text-lg lg:text-2xl font-bold w-[18.8%] mr-[1rem]"
									}
								>
									4 Year Planner
								</h1>
								<div
									className={
										isMobile
											? "relative w-full"
											: "relative items-center w-[81%]"
									}
								>
									<button
										onClick={() => {
											this.setState(
												{
													years: (this.state.years
														? this.state.years
														: defaultYears
													).map((year) => year - 1),
												},
												() => {
													this.addYearToState(
														this.state.years[0]
													);
													this.updateYearsInFirebase();
												}
											);
										}}
										// className="dark:text-white">
										className="absolute left-2 dark:text-white top-1"
									>
										<svg
											className={
												"w-6 h-6" + this.themeColor
											}
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 14 10"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M13 5H1m0 0 4 4M1 5l4-4"
											/>
										</svg>
									</button>

									<button
										onClick={() => {
											this.setState(
												{
													years: (this.state.years
														? this.state.years
														: defaultYears
													).map((year) => year + 1),
												},
												() => {
													this.addYearToState(
														this.state.years[
															this.state.years
																.length - 1
														]
													);
													this.updateYearsInFirebase();
												}
											);
										}}
										className="absolute right-2 top-0"
									>
										<svg
											className={
												"w-6 h-6 " + this.themeColor
											}
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 14 10"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M1 5h12m0 0L9 1m4 4L9 9"
											/>
										</svg>
									</button>

									{/* <div className='flex flex-row space-x-4 ml-3'> */}
									<div className="flex flex-row text-center justify-center w-full gap-4">
										{isMobile ? (
											<label
												className={
													"font-bold text-xl text-center " +
													this.themeColor
												}
											>
												{/* // style={{width: `${((i == 0 || i == 3) ? 200 : 240)}px`}}> */}
												{
													(this.state.years
														? this.state.years
														: defaultYears)[0]
												}
												-
												{(this.state.years
													? this.state.years
													: defaultYears)[0] + 1}
											</label>
										) : (
											(this.state.years
												? this.state.years
												: defaultYears
											).map((year, i) => (
												<div className="w-[25%]">
													<label
														className={
															"font-bold text-xl text-center " +
															this.themeColor
														}
													>
														{/* // style={{width: `${((i == 0 || i == 3) ? 200 : 240)}px`}}> */}
														{year}-{year + 1}
													</label>
												</div>
											))
										)}
									</div>
								</div>
								<div
									className={
										isMobile
											? "text-center"
											: "absolute right-0"
									}
								>
									{this.showFutureClassesButton()}
								</div>
							</div>

							<DragDropContext
								onDragEnd={this.onDragEnd}
								// onDragOver={(e) => {
								//     // Prevent default browser scrolling behavior
								//         e.preventDefault();
								// }}
							>
								<div className="flex gap-4 h-full pb-10 w-full">
									{!isMobile && this.unplannedColumn()}
									<div
										className={
											isMobile
												? "flex gap-4 h-screen w-full"
												: "flex gap-4 h-full w-[64%]"
										}
									>
										{isMobile ? (
											<div
												key={(this.state.years
													? this.state.years
													: defaultYears)[0].toString()}
												className="flex flex-col space-y-2 w-full"
											>
												{/* h-screen"> fixes some part */}
												{QUARTER_LONG_LIST.map(
													(quarter, i) =>
														this.singleQuarter(
															(this.state.years
																? this.state
																		.years
																: defaultYears)[0].toString() +
																"-" +
																i
														)
												)}
											</div>
										) : (
											(this.state.years
												? this.state.years
												: defaultYears
											).map((year) => (
												<div
													key={year.toString()}
													className="flex flex-col space-y-2 w-[24%]"
												>
													{/* h-screen"> fixes some part */}
													{QUARTER_LONG_LIST.map(
														(quarter, i) =>
															this.singleQuarter(
																year.toString() +
																	"-" +
																	i
															)
													)}
												</div>
											))
										)}
									</div>
									{!isMobile && this.UGReqs(isMobile)}
								</div>
								{isMobile && this.UGReqs(isMobile)}
							</DragDropContext>
						</div>
					</div>
				</div>
			</DarkModeProvider>
		);
	}
}

export default Planner;
