import {React, useEffect} from 'react'
import PlannerNavbar from '../components/Navbars/PlannerNavbar';
import "../styles/404.css";
import astronaut from "../assets/astronaut.svg";
import planet from "../assets/planet.svg";

function NotFound() {
  useEffect(() => {
    loadScript("https://cdn.jsdelivr.net/npm/tsparticles@2.3.4/tsparticles.bundle.min.js", initParticles);
  }, []);
  function initParticles() {
    const particles = {
      // ... (your particles configuration here)
    };

    if (window.tsParticles) {
      window.tsParticles.load("tsparticles", particles);
    }
  }

  function loadScript(src, callback) {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    document.body.appendChild(script);
  }

  return (
    <div>

      <PlannerNavbar/>
      {/* <div className="flex h-screen dark:bg-zinc-900 dark:text-white">
      <div className="w-1/3"> */}

      <div className="permission_denied">
        <div id="tsparticles"></div>
        <div className="denied__wrapper">
            <h1>404</h1>
            <h3>LOST IN <span>SPACE</span> App-Name? Hmm, looks like that page doesn't exist.</h3>
            <img id="astronaut" src={astronaut} alt="Astronaut"/>
            <img id="planet" src={planet} alt="Planet"/>
            <a href="./"><button className="denied__link">Go Home</button></a>
        </div>

        {/* If you want to include the tsparticles library within this component: */}
        <script type="text/javascript"
          src="https://cdn.jsdelivr.net/npm/tsparticles@2.3.4/tsparticles.bundle.min.js"></script>
        {/* If you have any other scripts specific to this component, you can include them here as well. But it's better to integrate that logic directly into the component using React hooks and methods. */}
      </div>

  </div>);
}

export default NotFound;