import { Navigate, useOutlet } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { fireBaseAuth } from "../config/firebase";
// import Navbar from "../components/Navbars/Navbar";
import { onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../hooks/useAuth";
import SigningIn from "../components/SigningIn";

const ProtectedLayout = () => {
  // const {loggedIn, login, logout} = useAuth();
  const [user, loading, error] = useAuthState(fireBaseAuth);

  const outlet = useOutlet();
  if (loading) {
    return <SigningIn/>;
  }
  else if (user) {
    return (
      <div>
        {outlet}
      </div>
    );
  } else {
    return <Navigate to="/login" replace/>;
  }
};

export default ProtectedLayout;