import React from 'react'
import ReactEcharts from "echarts-for-react"; 
import { useDarkMode } from '../../helpers/DarkModeContext';

// color based on the number of units
// some sort of color score but maybe not in the chart
// TODO
const categoryAverages = [2.5, 7.5, 12.5, 17.5, 22.5, 27.5, 32.5, 37.5];
const columns = ['< 5', '5-10', '10-15', '15-20', '20-25', '25-30', '30-35', "35+"];

// function colorSpectrum(startHue, endHue, min, max, value) {
//   // Calculate the hue value based on the score (0 to 120 for green to red)
//   let hue = ((value - min) / (max - min)) * (endHue - startHue) + startHue;

//   // Convert to HSL color string
//   return `hsl(${hue}, 80%, 50%)`;
// }

// function hoursToColor(noUnits) {
//   var result = [];
//   for (let i = 0; i < categoryAverages.length; i++) {
//     let score = categoryAverages[i] / noUnits;
//     result.push( colorSpectrum(100, 0, 0, 9, score) );
//   }
//   return result;
// }


function calculateMedian(data, total) {
  var medianPos = total / 2;
  var runningTotal = 0;
  for (var i = 0; i < data.length; i++) {
      runningTotal += data[i];
      if (runningTotal >= medianPos) {
          // return columns[i];
          return i;
      }
  }
  // return columns[columns.length - 1];
  return columns.length - 1;
}


//maybe don't use percentage, but do rendered the percentage in the tooltip
function HoursChart(props) {
  const {data, unitCount, medianHours, isMobile} = props;
  const isDarkMode = useDarkMode();

  if (data === undefined) {
    return (<div></div>);
  }

  // var colors = hoursToColor(unitCount);
  var colors = [
    '#D8E9FD', '#C3DCFC', '#B1D1FA', '#9DC5FA', '#8ABAF8', '#7AAEF7', '#68A3F8', '#5899F7',
    '#5899F7',  '#5899F7',  '#5899F7' // reserved for overflow
  ].slice(5 - (unitCount !== undefined ? unitCount : 5));

  const total = data.reduce((partialSum, a) => partialSum + a, 0);
  // const dataNormalised = data.map((x) => x / total * 100);
  const dataNormalised = data.map(function(x) { return Math.round(x / total * 100); })
  var medianIndex = (medianHours-2.5)/5; 

  const option = {
    xAxis: {
      type: 'category',
      data: columns,
      axisLabel: {
        textStyle:{
          color: isDarkMode ? 'white' : 'black'
       },
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}%', // Format Y-axis labels as percentages
        textStyle:{
          color: isDarkMode ? 'white' : 'black'
       },
      }
    },
    series: [
      {
        type: 'bar',
        // barWidth: '40%', // Adjust the width of the bars as needed
        barCategoryGap: '0%', // Set the gap between categories to 0%
        barGap: '0%', // Set the gap between bars within the same category to 0%
        data: dataNormalised,
        itemStyle: {
          normal: {
            color: (params) => colors[params.dataIndex], // Use different colors based on the index of the bar
          },
        },
        markLine: {
            symbol: 'none', // Remove the arrow head
            lineStyle: {
              color: '#FF5733', // Set the color of the line
              width: 4, // Set the width of the line
              type: 'solid' // Make the line solid
            },
            data: [
             { xAxis: (medianHours !== undefined && medianHours !== 0) ? columns[medianIndex] : columns[calculateMedian(data, total)] } // Replace 'specific-x-value' with the actual x-axis value where you want the line to appear.
            ],
            label: {
                position: 'end',
                color: '#FF5733', // Make the label color red
                fontSize: 20, // Increase the font size of the label
                fontWeight: 'bold' // Make the label bold
              }
        }
      }
    ],
    tooltip: {
      formatter: (params) => {
        const dataIndex = params.dataIndex;
        if (params.componentType === "markLine") {
          return 'Median (' + (medianHours !== undefined ? medianHours: columns[calculateMedian(data, total)]) + ')';
        } else {
          // return `${Math.round(data[dataIndex] / total * 100, 2)}%`;
          // return `${data[dataIndex]} people`;
          return `${dataNormalised[dataIndex]}% (${data[dataIndex]} people)`;

        }
      },
    },
  };

  if (total === 0) return null;
  return (
    <div className={`h-96 py-0 ${isMobile ? "text-center": ""}`}>
      <h1 className="font-extrabold text-xl">Weekly Hours <span className='text-[#5899F7] font-semibold'>({total} reviews)</span></h1>
      <ReactEcharts option={option} style={{
                  height: '100%',
                  width: '100%',
                }}/>
      
    </div>
    // option && myChart.setOption(option)
  )
}

export default HoursChart