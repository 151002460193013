import React, {useContext, useRef } from 'react';
import { colorToRgb } from './Colors';
import { CourseCode, CourseSections, PinnedClasses, CurrentFlow } from '../helpers/Contexts';
import { LATEST_YEAR_IN_DB } from '../constants/Constants';
import {useEffect, useState} from 'react';
import { Tooltip } from 'react-tooltip'
import { current } from 'immer';

const startrow = () => {
    const DAYS_SHORT = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    return (
        <React.Fragment>
            {DAYS_SHORT.map((day, index) => {
                return (
                    <div className={`row-start-[1] sticky col-start-[${index+2}] z-10 bg-white border-slate-100 dark:bg-slate-800 dark:border-slate-200/5 bg-clip-padding text-slate-400 dark:text-slate-300 border-b text-sm font-medium flex items-center justify-center`}
                    key={`topBar-${index}`}>
                        {day}
                    </div>
                );
            })}
        </React.Fragment>
    );
}

const hour_to_string = (hour) => {
    let PM = false;
    if (hour >= 12) {
        PM = true;
    } if (hour > 12) {
        hour -= 12;
    } else if (hour === 0) {
        hour = 12;
    }
    return hour.toString() + (PM ? " PM" : " AM");
}

const row = (row, start) => {
    let time = row - 2 + start;
    row = row.toString();

    return (
        <React.Fragment>
            <div style={{"gridRowStart": row}} className={"col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"} key={`row-${time}-0`}>
                {hour_to_string(time)}
            </div>
            <div style={{"gridRowStart": row}} className={"col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"} key={`row-${time}-1`}></div>
            <div style={{"gridRowStart": row}} className={"col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"} key={`row-${time}-2`}></div>
            <div style={{"gridRowStart": row}} className={"col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"} key={`row-${time}-3`}></div>
            <div style={{"gridRowStart": row}} className={"col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"} key={`row-${time}-4`}></div>
            <div style={{"gridRowStart": row}} className={"col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"} key={`row-${time}-5`}></div>
        </React.Fragment>
    );
}

const allrows = (noRows, start) => {
    return (
        <>
          {startrow()}
          {Array.from({ length: noRows - 1 }, (_, i) => (
            <React.Fragment key={i + 2}>
                {row(i + 2, start)}
                </React.Fragment>
          ))}
        </>
      );
}

// chat GPT Optomized
function calculateCollisions(courseSectionsDict, pinnedClasses, currentFlow, showingUserSchedule=false) {
    if (currentFlow[0] > LATEST_YEAR_IN_DB) return [[], 19]; // No classes to show, 17 + 2 latest end time
    const events = [];
    const days = Array(5).fill().map(() => []);
    const colors = ["blue", "purple", "green", "orange", "lime", "rose", "yellow"];
    
    let colorIndex = 0;
    for (const code in courseSectionsDict) {
        // if (pinnedClasses[code] === undefined) { continue; }

        const color = colors[colorIndex % colors.length];
        const show = pinnedClasses[code] === undefined ? undefined : pinnedClasses[code]["show"];
        const sections = courseSectionsDict[code];
        // const selected = false;

        // sections.forEach((section, idx) => {
        var added = false;

        for (let idx = 0; idx < sections.length; idx++) {
            const section = sections[idx];
            if (section === undefined || section.type === "NOT_AVAILABLE") continue;

            if (!showingUserSchedule && section.currentUserSection === true) {
                continue; // break as all events in this class are for the current user
            }

            if (show === undefined || show[idx]) {
                added = true;
                section.schedules.forEach(event => {
                    if (event.day && event.day > 0 && event.day <= 5) {
                        // if (event === "RESTRICTED") {
                        if (event.restricted) {
                            // event.color = "red";
                            event.color = color;
                        } else if (section.currentUserSection === true || (code in pinnedClasses && pinnedClasses[code]["disabled"] === true)) {
                            event.color = "gray";
                        } else {
                            event.color = color;
                        }
                        days[event.day - 1].push(event);
                    }
                }
                );
            }
        }
        // );
        if (added) {
            colorIndex++;
        }
    }

    let latestEndTime = 17;
    days.forEach(day => {
        // Sort day's events by start time
        const sortedEventsforDay = day.sort((a, b) => a.startTime - b.startTime);
        
        for (let i = 0; i < sortedEventsforDay.length; i++) {
            const collisions = [sortedEventsforDay[i]];
            sortedEventsforDay[i].index = 0;

            for (let j = i + 1; j < sortedEventsforDay.length && sortedEventsforDay[j].startTime < (sortedEventsforDay[i].startTime + 1); j++) {
                sortedEventsforDay[j].index = j - i;
                collisions.push(sortedEventsforDay[j]);
            }

            collisions.forEach(collision => {
                collision.divisor = collisions.length;
                events.push(collision);
            });

            i += collisions.length - 1;
        }

        if (sortedEventsforDay.length) {
            const sortedEventsByEndTimeforDay = day.sort((a, b) => a.endTime - b.endTime);
            latestEndTime = Math.max(latestEndTime, sortedEventsByEndTimeforDay[sortedEventsByEndTimeforDay.length - 1].endHour);
        }
    });

    return [events, latestEndTime + 2];
}


const Calendar = (props) => {
    const { showingUserSchedule } = props;
    // change to be inhereited if necessary
    const {courseSections, setCourseSections} = useContext(CourseSections);
    const {pinnedClasses, setPinnedClasses} = useContext(PinnedClasses);
    const {courseCode, setCourseCode} = useContext(CourseCode);
    const {currentFlow, setCurrentFlow} = useContext(CurrentFlow);

    // This code stops showing classes not currently being viewed
    useEffect(() => {
        const newPinnedClasses = Object.fromEntries(
            Object.entries(pinnedClasses).filter(
                ([courseCode, details]) => !details.disabled
            )

        );
        
        // For newCourseSections
        const newCourseSections = Object.fromEntries(
            Object.entries(courseSections).filter(
                ([courseCode, details]) => !pinnedClasses[courseCode]?.disabled
            )
        );

        setCourseSections(newCourseSections);
        setPinnedClasses(newPinnedClasses);
      }, [courseCode]);

    const [events, latestEndTime] = calculateCollisions(courseSections, pinnedClasses, currentFlow, showingUserSchedule);
    
    let start = 8; // 8 AM
    // latestEndTime = 20; // 8 PM
    // let noRows = latestEndTime - start;// + 2;
    const [noRows, setNoRows] = useState(latestEndTime - start); // Initial value
    useEffect(() => {
        setNoRows(latestEndTime - start); // Update noRows whenever latestEndTime changes
    }, [latestEndTime, start]);

    let gridRows = new Array(noRows).fill(`${94 / (noRows-1)}%`);
    gridRows[0] = "6%";
    const rowStyles = gridRows.join(" ");

    let gridCols = new Array(6).fill(`${90 / 5}%`);
    gridCols[0] = "10%";
    const colStyles = gridCols.join(" ");

    // Shine animation
    const [shouldAnimate, setShouldAnimate] = useState(true);
    const animatedRef = useRef(null);
    useEffect(() => {
        // Start the animation immediately upon courseCode change
        if (animatedRef.current) {
            // Force a reflow
            void animatedRef.current.offsetHeight;
        }
    
        setShouldAnimate(true);
    
        const timeout = setTimeout(() => {
            setShouldAnimate(false);
        }, 2500); // Duration of your animation
    
        // After the initial animation, set up the repeating interval
        const interval = setInterval(() => {
            setShouldAnimate(prev => !prev);
        }, 3000); // This repeats the toggle every 3 seconds
    
        // Clean-up logic: Whenever courseCode changes OR the component is unmounted, 
        // we want to clear out the ongoing timers.
        return () => {
            clearTimeout(timeout);
            clearInterval(interval);
        };
    }, [courseCode]);

    return (
        <div className="pt-2" style={{
            "display": "grid",
            gridTemplateRows: rowStyles,
            gridTemplateColumns: colStyles,
            "width": "100%",
            "height": "58%"
        }}>
            <Tooltip id="calendar-tooltip" multiline={true} className='z-50'
                style={{"whiteSpace": "pre-wrap"}} key="calendarTooltip"/>
            {allrows(noRows, start)}
            {
                events.map((event, i) => {
                    // function single_class(start, day, startHour, startMins, endHour, endMins, description, color, index, divisor) {
                        // day is 1-5, meaning Mon-Fri
                        // hour is 0-23, meaning 12AM-11PM
                        // endhour is 0-23, meaning 12AM-11PM
                        // color is a string, like "blue"
                        // description is a string, like "Math 51"
                        let duration = (event.endHour - event.startHour) + (event.endRemainder - event.startRemainder);
                        // const isRestricted = event.title === "RESTRICTED";
                        const isRestricted = event.restricted;

                        return (
                            <div 
                            key={`${event.title}-${event.startHour}-${event.startRemainder}-${event.endHour}-${event.endRemainder}-${i}`}
                            style = {{
                                "gridRowStart": (event.startHour - start + 2).toString(),
                                "gridColumnStart": (event.day + 1).toString(),
                                "backgroundColor": 
                                (!isRestricted ? colorToRgb(event.color, 400, 0.2) : colorToRgb(event.color, 500, 0.5)),
                                "border": `1px solid ${
                                    !isRestricted ? colorToRgb(event.color, 400, 0.1) : colorToRgb(event.color, 400, 0.8)
                                }`,
                                "width": `calc(100% / ${event.divisor})`, // divide the column space equally between the overlapping events
                                "position": "relative",
                                "left": `${event.index * 100 / event.divisor}%`,
                                "top": event.startRemainder * 100 + "%",
                                "height": duration * 100 + "%",
                            }}
                            ref={animatedRef}
                            className={`p-1 flex flex-col w-0 truncate cursor-pointer rounded-lg ${!isRestricted ? "border z-10": 
                            "border-2 restricted"}`}
                            data-tooltip-id="calendar-tooltip" data-tooltip-html={`<b>${event.title}</b><br/>${event.description}`}
                            onClick={ () => {
                                if (!isRestricted) {
                                    setCourseCode(event.title);
                                }
                            }}>

                                {/* Shimmer Effect Div */}
                                <div
                                    className={`absolute inset-0 ${
                                        event.title === courseCode && shouldAnimate
                                        ? `bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,.5)_50%,transparent_75%,transparent_100%)] dark:bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,.25)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_100%] bg-[position:200%_0,0_0] bg-no-repeat transition-[background-position_0s_ease] duration-[2500ms]`
                                        : "bg-[position:-100%_0,0_0]"
                                    }`}
                                    ></div>
                                <span style={{"color": isRestricted ? colorToRgb(event.color, 600, 1) : colorToRgb(event.color, 600, 1)}} 
                                    className={`text-xs font-medium`}>{event.title}</span>
                            </div>
                        );
                })
            }
            {/* Label for No Times Currently Available */}
            { currentFlow[0] > LATEST_YEAR_IN_DB &&
                <div className="flex justify-center items-center text-gray-500 text-lg"
                style = {{
                    "gridRowStart": (6).toString(),
                    "gridColumnStart": (4).toString(),
                }}>
                    No times currently available
                </div>
            }
        </div>
    );
};

export default Calendar;