import {useContext, useEffect} from 'react';
import {QUARTER_LONG_LIST} from '../constants/Constants.js';
import { CurrentFlow, CourseCode, PinnedClasses } from '../helpers/Contexts';

function CourseFlow(props) {
    const {disabled, name} = props;

    var {currentFlow, setCurrentFlow} = useContext(CurrentFlow);

    const {courseCode, setCourseCode} = useContext(CourseCode);
    const {pinnedClasses, setPinnedClasses} = useContext(PinnedClasses);

    const moveLeft = () => {
        setCurrentFlow(prevFlow => {
            let newFlow = [...prevFlow];
            if (prevFlow[1] === 0) {
                newFlow[1] = 3;
                newFlow[0] -= 1;
            } else {
                newFlow[1] -= 1;
            }
            return newFlow;
        });
    };

    const moveRight = () => {
        setCurrentFlow(prevFlow => {
          let newFlow = [...prevFlow];
          if (prevFlow[1] === 3) {
            newFlow[1] = 0;
            newFlow[0] += 1;
          } else {
            newFlow[1] += 1;
          }
          return newFlow;
        });
    };

    // Key Listeners
    useEffect(() => {
        // Handler to update the state for keyup
        const handleKeyDown = (e) => {

            function moveClass(delta) {
                const keys = Object.keys(pinnedClasses);
                var currentIndex = keys.indexOf(courseCode);

                if (delta === 1) {
                    if (currentIndex === keys.length - 1) {
                        currentIndex = -1;
                    }
                } else { // going up
                    if (currentIndex === 0) {
                        currentIndex = keys.length;
                    }
                }

                const nextIndex = currentIndex + delta;
                const nextItem = keys[nextIndex];
                setCourseCode(nextItem);
            }

            // Check if the target is the text box
            if (e.target.tagName === 'INPUT' && e.target.type === 'text') {
                return; // Do nothing, let the input handle the keypress
            }

            if (e.keyCode === 37 || e.keyCode === 65) { // left arrow key press or a key press
                moveLeft();
            } else if (e.keyCode === 39 || e.keyCode === 68) { // right arrow key press or d key press
                moveRight();
            } else if (e.keyCode === 87 || e.keyCode === 83) { // w key, move one class up; s key, move one class down
                if (courseCode in pinnedClasses) {
                    moveClass(e.keyCode === 87 ? -1 : 1);
                }
            } else if (e.keyCode === 9) {
                e.preventDefault();  // Stop default behavior
                
                const inputElement = document.getElementById("search-navbar");
                if (inputElement) {
                    inputElement.focus();
                }
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }, [courseCode]);

    return (
        <div className='flex justify-center items-center'>
            <div className='flex justify-between py-1 px-2 items-center w-full bg-slate-100 dark:bg-zinc-800 rounded-xl'>
                
                {disabled === undefined || !disabled ?
                    <svg className="w-3 h-3 text-black dark:text-white cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14"
                        onClick={moveLeft}>
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"/>
                    </svg>
                : <div></div>}

                <h2 className='font-semibold text-lg text-black dark:text-white'>
                    {/* {QUARTER_SHORTENED_LIST[currentFlow[1]]} {currentFlow[0]}-{currentFlow[0]+1} */}
                    {(disabled && name !== undefined) ? 
                        (<span className="text-blue-500">{name}'s </span>)
                        : null
                    }
                    {QUARTER_LONG_LIST[currentFlow[1]]} {(currentFlow[0] === 0 ? 2023: currentFlow[0])}-{(currentFlow[0] === 0 ? 2023: currentFlow[0])+1}
                </h2>

                {disabled === undefined || !disabled ?
                    <svg className="w-3 h-3 dark:text-white text-gray-800 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14"
                        onClick={moveRight}>
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
                    </svg>
                : <div></div>}
            </div>
            <div className="py-5"></div>
        </div>
    )
}

export default CourseFlow