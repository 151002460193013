import "../styles/CourseStyles.css";
import HoursChart from "./metrics/HoursChart";
import YearChart from "./metrics/YearChart";
// import Reviews from "./Reviews"
import GradeDistribution from "./metrics/GradeDistribution";
import React, { useRef, useEffect, useState, useContext } from "react";
import CourseInfo from "./CourseInfo";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import CourseMeta from "../data/CourseMeta";
import Metrics from "../data/Metrics";
// import Review from "../data/Review";
import {
	query,
	orderBy,
	limit,
	onSnapshot,
	startAfter,
	deleteDoc,
} from "firebase/firestore";
import { QUARTER_SHORTENED_LIST, REVIEW_PATH } from "../constants/Constants.js";
import {
	CurrentFlow,
	CourseCode,
	CourseSections,
	PinnedClasses,
} from "../helpers/Contexts";
import { sectionConverter } from "../adapters/SectionAdapters";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireBaseAuth } from "../config/firebase";
import Welcome from "./Welcome";
import { courseConverter, metricsConverter, reviewsConverter } from "../adapters/CourseAdapters";
import { saveCourse, getCourse, saveReviews, getReviews, saveMetrics, getMetrics } from "../Cache/CourseCaching";
import { COURSES_PATH, DEFAULT_COURSE, LATEST_YEAR_IN_DB } from "../constants/Constants.js";
import ReviewsSentimentBar from "./metrics/ReviewsSentimentBar"
import CourseDifficulty from "./metrics/CourseDifficulty"
import { Tooltip } from 'react-tooltip'
import ReviewColumn from "./ReviewItems";
import { convertFlowToHumanReadable } from "../helpers/StringHelpers";

function CourseMobile() {
	const { courseSections, setCourseSections } = useContext(CourseSections);
	const { pinnedClasses, setPinnedClasses } = useContext(PinnedClasses);
	const { courseCode, setCourseCode } = useContext(CourseCode);
	var { currentFlow, setCurrentFlow } = useContext(CurrentFlow);

	var [course, setCourse] = useState(DEFAULT_COURSE);

	var [instructorNamesToID, setInstructorNamesToID] = useState({});
	var [metrics, setMetrics] = useState(
		new Metrics(undefined, undefined, undefined)
	);
	var [qtrToReviews, setQtrToReviews] = useState(undefined);
	var [selectedReviewQtr, setSelectedReviewQtr] = useState(undefined);
	var [reviewFilterText, setReviewFilterText] = useState("");
	var [reviewsFiltered, setReviewsFiltered] = useState(undefined);

	const [user, loading, error] = useAuthState(fireBaseAuth);

	// var sectionTimes = [];
	//   var [sectionTimes, setSectionTimes] = useState(false);

	useEffect(() => {
		if (courseCode === undefined) {
			return;
		}

		// check for cached course data
		const cachedCourse = getCourse(currentFlow, courseCode);

    if (cachedCourse !== null) {
        setCourse(cachedCourse);
        processInstructors(cachedCourse);
    } else {
      const restrictedYear = Math.min(currentFlow[0], LATEST_YEAR_IN_DB);
      const docRef = doc(db, COURSES_PATH, `${restrictedYear}`, `${courseCode}`, "meta").withConverter(courseConverter);
      
      getDoc(docRef)
        .then(docSnap => {
            const courseData = docSnap.data(); // save fetched course data to a variable
            setCourse(courseData);
          
            processInstructors(courseData);
        })
    }
  }, [currentFlow[0], courseCode]); // Dependency array

    const flowWhenWeStarted = `${currentFlow[0]}-${currentFlow[1]}`;
    const restrictedYear = Math.min(currentFlow[0], LATEST_YEAR_IN_DB);
    const docRef = doc(db, COURSES_PATH, `${restrictedYear}`, `${courseCode}`, QUARTER_SHORTENED_LIST[currentFlow[1]])
                    .withConverter(sectionConverter(courseCode, flowWhenWeStarted));

	// TODO ADD NEW REVIEW CODE

	useEffect(() => {
		if (courseCode === undefined) {
			return;
		}

		const flowWhenWeStarted = `${currentFlow[0]}-${currentFlow[1]}`;
		const docRef = doc(
			db,
			COURSES_PATH,
			`${currentFlow[0]}`,
			`${courseCode}`,
			QUARTER_SHORTENED_LIST[currentFlow[1]]
		).withConverter(sectionConverter(courseCode, flowWhenWeStarted));

		getDoc(docRef)
			.then((doc) => {
				if (doc.exists()) {
					const sections = doc.data();

					setCourseSections((prevState) => ({
						...prevState,
						[courseCode]: sections,
					}));

					var pinChange = {
						...pinnedClasses[courseCode], // spread the existing properties
						show: Array(sections.length).fill(true),
						flow:
							pinnedClasses[courseCode] !== undefined &&
							pinnedClasses[courseCode].disabled
								? flowWhenWeStarted
								: undefined, //prevState[courseCode]["flow"], currentFlow,
						sectionCount: sections.length,
						disabled: true,
					};

					setPinnedClasses((prevState) => ({
						...prevState,
						[courseCode]: pinChange,
					}));
				} else {
					setCourseSections((prevState) => ({
						...prevState,
						[courseCode]: [],
					}));
				}
			})
			.catch((error) => {
				setCourseSections((prevState) => ({
					...prevState,
					[courseCode]: [],
				}));
				console.error("Error getting document:", error);
			});
	}, [currentFlow]);

	useEffect(() => {
		if (courseCode === undefined) {
			return;
		}

		const flowWhenWeStarted = `${currentFlow[0]}-${currentFlow[1]}`;
		const docRef = doc(
			db,
			COURSES_PATH,
			`${currentFlow[0]}`,
			`${courseCode}`,
			QUARTER_SHORTENED_LIST[currentFlow[1]]
		).withConverter(sectionConverter(courseCode, flowWhenWeStarted));

		getDoc(docRef)
			.then((doc) => {
				if (doc.exists()) {
					const sections = doc.data();

					setCourseSections((prevState) => ({
						...prevState,
						[courseCode]: sections,
					}));

					setPinnedClasses((prevState) => ({
						...prevState,
						[courseCode]: {
							...prevState[courseCode], // spread the existing properties
							show:
								prevState[courseCode] !== undefined &&
								prevState[courseCode]["show"] !== undefined
									? prevState[courseCode]["show"]
									: Array(sections.length).fill(true),
							flow:
								pinnedClasses[courseCode] !== undefined &&
								pinnedClasses[courseCode].disabled
									? flowWhenWeStarted
									: undefined, //prevState[courseCode]["flow"], currentFlow,
							sectionCount: sections.length,
							disabled:
								pinnedClasses[courseCode] === undefined ||
								pinnedClasses[courseCode].disabled,
						},
					}));
				} else {
					setCourseSections((prevState) => ({
						...prevState,
						[courseCode]: [],
					}));
				}
			})
			.catch((error) => {
				setCourseSections((prevState) => ({
					...prevState,
					[courseCode]: [],
				}));
				console.error("Error getting document:", error);
			});
	}, [courseCode]);

	const addClass = (courseCode) => {
		var units =
			course.units && course.units.length > 0
				? course.units[course.units.length - 1]
				: 0;
		if (course.unitChoice !== undefined) {
			units = course.unitChoice;
		}
		const flowWhenWeStarted = currentFlow;

		setPinnedClasses((prevState) => ({
			...prevState,
			[courseCode]: {
				show: Array(prevState[courseCode]["show"].length).fill(true), // prevState[courseCode]["show"],
				units: units,
				hours: course.medianHours ? course.medianHours : units * 3,
				title: course.title,
				flow: flowWhenWeStarted,
				disabled: false,
			},
		}));

		var sectionCount = 0;
		if (courseSections[courseCode] !== undefined) {
			sectionCount = courseSections[courseCode].length;
		}


		saveCourse(
			currentFlow,
			courseCode,
			course,
			sectionCount,
			user.uid,
			units
		);
	};

	function processInstructors(courseData) {
		var instructorMap = {};
		if (courseData === undefined) {
			return;
		}
		for (let short in courseData.instructors) {
			// loop over qtr
			const ids = courseData.instructorIDs[short];
			const names = courseData.instructors[short];
			for (let i = 0; i < ids.length; i++) {
				instructorMap[names[i]] = ids[i];
			}
		}

		setInstructorNamesToID(instructorMap);
	}

	useEffect(() => {
		if (courseCode === undefined) {
			return;
		}

		// reset reviews
		setQtrToReviews({});
		setSelectedReviewQtr(undefined);
		setReviewsFiltered(undefined);

		// check for cached course data
		const cachedMetrics = getMetrics(courseCode);

		if (cachedMetrics !== null) {
			setMetrics(cachedMetrics);
		} else {
			const docRef = doc(db, "Metrics", `${courseCode}`).withConverter(
				metricsConverter
			);

			getDoc(docRef).then((docSnap) => {
				const metricsData = docSnap.data();
				setMetrics(metricsData);

				saveMetrics(courseCode, metricsData);
			});
		}
	}, [courseCode]);

	useEffect(() => {
		if (!course || !course.pastReviews || course.pastReviews.length === 0) {
			setSelectedReviewQtr(undefined);
			return; // Exit early if course or pastReviews is undefined or empty
		}

		// Set selected most recent quarter
		setSelectedReviewQtr(course.pastReviews[0].flow);
	}, [course]);

	useEffect(() => {
		if (selectedReviewQtr === null || selectedReviewQtr === undefined) {
			return;
		}
		if (courseCode === undefined) {
			return;
		}

		// check if it is already fetched
		if (qtrToReviews[selectedReviewQtr] !== undefined) {
			// setReviews(qtrToReviews[selectedReviewQtr]);
			return;
		}

		// if not already fetched
		const cachedReviews = getReviews(courseCode);

		if (cachedReviews !== null) {
			setQtrToReviews(cachedReviews);

			// if the quarter is already contained in the cached reviews
			if (cachedReviews.reviews[selectedReviewQtr] !== undefined) {
				setReviewsFiltered(cachedReviews.reviews[selectedReviewQtr]);
				return;
			}
		}

		// if not in cache, fetch from firestore
		const reviewsRef = doc(
			db,
			REVIEW_PATH,
			courseCode,
			selectedReviewQtr,
			"reviews"
		);
		getDoc(reviewsRef).then((docSnap) => {
			const reviewsData = docSnap.data();
			if (reviewsData === undefined || reviewsData.reviews === undefined)
				return;

			const newReviews = {
				...qtrToReviews,
				reviews: {
					...qtrToReviews.reviews,
					[selectedReviewQtr]: reviewsData.reviews,
				},
			};
			setQtrToReviews(newReviews);
			setReviewsFiltered(reviewsData.reviews);
			saveReviews(courseCode, newReviews);
		});

		// fetch reviews based on past Reviews
	}, [selectedReviewQtr]);

	const [open, setOpen] = useState(false);

	const toggleOpen = () => {
		setOpen(!open);

		if (open) {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}
	};

	const removeClass = () => {
		setPinnedClasses((prevState) => ({
			...prevState,
			[courseCode]: {
				show: prevState[courseCode]["show"],
				units: prevState[courseCode]["units"],
				hours: prevState[courseCode]["medianHours"],
				title: prevState[courseCode]["title"],
				flow: prevState[courseCode]["flow"],
				disabled: true, // Adjust this logic as needed
			},
		}));
		deleteDoc(
			doc(
				db,
				"Planned",
				user.uid,
				`${currentFlow[0]}-${currentFlow[1]}`,
				courseCode
			)
		);
	};


  const [descFormatted, setDescFormatted] = useState(<span></span>);
  useEffect(() => {
    if (course == undefined || course.description === undefined) { return; }
    const regex = /\[\[([^[\]]+)\]\]/g;
    // const parts = val.split(regex);
    const parts = course.description.split(regex)
            .filter(part => part !== "");

		var offset = 1;
		if (course.description.startsWith("[[")) offset = 0;

		setDescFormatted(
			<span>
				{parts.map((part, index) => {
					if (index % 2 === offset) {
						const strippedCode = part.replace(/ /g, "");
						return (
							<span
								key={index}
								className="font-semibold text-blue-500 cursor-pointer"
								onClick={() => setCourseCode(strippedCode)}
							>
								{part}
							</span>
						);
					} else {
						const regexLINK = /{{(.*?)}}/g;
						var replacedString = part.replace(
							regexLINK,
							function (match, id) {
								var linkF = id;
								if (
									!id.startsWith("http://") &&
									!id.startsWith("https://")
								) {
									linkF = `http://${id}`;
								}
								return (
									`<a className="font-semibold text-green-500 cursor-pointer" target="_blank" rel="noopener noreferrer" href="${linkF}">` +
									id +
									"</a>"
								);
							}
						);

						return (
							<span
								dangerouslySetInnerHTML={{
									__html: replacedString,
								}}
							/>
						);
					}
				})}
			</span>
		);

		// setDescFormatted(<div
		//   dangerouslySetInnerHTML={{
		//     __html: replacedString,
		//   }}
		// />);
	}, [course]);

	const reviewSearchHandleChange = (event) => {
		const searchTerm = event.target.value;
		setReviewFilterText(searchTerm);
	};

	useEffect(() => {
		if (
			qtrToReviews === undefined ||
			qtrToReviews.reviews === undefined ||
			qtrToReviews.reviews[selectedReviewQtr] === undefined
		)
			return;

		if (reviewFilterText === "") {
			setReviewsFiltered(qtrToReviews.reviews[selectedReviewQtr]);
		} else {
			const filteredReviews = qtrToReviews.reviews[
				selectedReviewQtr
			].filter((review) => {
				return (
					review
						.toLowerCase()
						.includes(reviewFilterText.toLowerCase()) ||
					review
						.toLowerCase()
						.includes(reviewFilterText.toLowerCase())
				);
			});
			setReviewsFiltered(filteredReviews);
		}
	}, [reviewFilterText, selectedReviewQtr]);

	const reviewHandleKeyDown = (event) => {
		// if (event.key === 'Enter') {
		//     var inputCode = event.target.value;
		// }
	};

	if (courseCode === undefined) {
		return <Welcome />;
	}
	return course !== undefined ? (
		<div className="font-sans text-left space-y-1.5">
			{course.title !== "" ? (
				<div className="p-4 shadow-xl shadow-blue-500/40 rounded-md bg-white dark:bg-zinc-800">
					<div className="flex flex-row justify-between">
						<div>
							<h1 className="text-3xl font-extrabold">
								{course.courseCode}
							</h1>
							<h1 className="text-2xl font-medium">
								{course.title}
							</h1>
						</div>

						{pinnedClasses[courseCode] === undefined ||
						pinnedClasses[courseCode]["currentUserHidden"] ===
							false ||
						pinnedClasses[courseCode].disabled === true ? ( // check if class is not pinned
							course.classOfferedThisYear &&
							course.qtrs[currentFlow[1]] ? (
								<button
									type="button"
									className="pr-2 group"
									onClick={() => addClass(courseCode)}
									onMouseOver={(event) =>
										(event.currentTarget.style.transform =
											"scale(1.25)")
									}
									onMouseOut={(event) =>
										(event.currentTarget.style.transform =
											"scale(1)")
									}
								>
									<svg
										className="w-[40px] h-[40px] text-green-500 group-hover:hidden"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										fill="none"
										viewBox="0 0 20 20"
									>
										<path
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
										/>
									</svg>

									<svg
										className="w-[40px] h-[40px] text-green-400 hidden group-hover:block"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										viewBox="0 0 20 20"
									>
										<path d="M9.546.5a9.5 9.5 0 1 0 9.5 9.5 9.51 9.51 0 0 0-9.5-9.5ZM13.788 11h-3.242v3.242a1 1 0 1 1-2 0V11H5.304a1 1 0 0 1 0-2h3.242V5.758a1 1 0 0 1 2 0V9h3.242a1 1 0 1 1 0 2Z" />
									</svg>
								</button>
							) : (
								// greyed out button
								<button
									disabled
									type="button"
									className="h-12 leading-tight py-0 px-2 text-sm font-medium text-gray-900 focus:outline-none hover:bg-white rounded-lg
                     border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-1 focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-700 
                     dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 dark:text-white dark:hover:bg-gray-800"
								>
									Not offered this quarter
								</button>
							)
						) : (
							// remove button
							<button
								type="button"
								className="pr-2 group"
								onClick={() => removeClass()}
								onMouseOver={(event) =>
									(event.currentTarget.style.transform =
										"scale(1.15)")
								}
								onMouseOut={(event) =>
									(event.currentTarget.style.transform =
										"scale(1)")
								}
							>
								<svg
									className="w-[40px] h-[40px] text-red-500 group-hover:hidden"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 20 20"
								>
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
									/>
								</svg>

								<svg
									className="w-[40px] h-[40px] text-red-500 hidden group-hover:block"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
								</svg>
							</button>
						)}
					</div>

					<div className="pb-4 font-light">
						<p
							className={
								open
									? "text-justify overflow-y-auto"
									: "text-justify overflow-y-auto line-clamp-12"
							}
						>
							{descFormatted}
						</p>
						<button
							onClick={toggleOpen}
							className="font-bold text-blue-500"
						>
							Show {open ? "Less" : "More"}
						</button>
					</div>

					<div className="bg-gradient-to-b from-[#1499f7] via-[#8A2BE2] to-[#FF69B4] rounded-xl p-0.5">
						<div className="p-0.5 dark:bg-gray-900 bg-white rounded-xl h-full">
							{/* <CourseInfo open={open} course={course} courseCode={courseCode} allInstructors={allInstructorsSet} isMobile={true}/> */}
							<CourseInfo
								open={open}
								course={course}
								courseCode={courseCode}
								instructorNamesToID={instructorNamesToID}
							/>
						</div>
					</div>
				</div>
			) : null}

			{/* { metrics !== undefined && (metrics.hours !== undefined || metrics.years !== undefined) ? */}
			{metrics !== undefined ? (
				<div className="pt-4 shadow-xl shadow-blue-500/40 rounded-md bg-white dark:bg-zinc-800 items-center">
					{metrics.hours !== undefined &&
						metrics.hours.reduce(
							(partialSum, a) => partialSum + a,
							0
						) !== 0 && (
							<HoursChart
								data={metrics.hours}
								unitCount={course.unitsMax}
								medianHours={course.medianHours}
								isMobile={true}
							/>
						)}

					{metrics.years && (
						<YearChart data={metrics.years} isMobile={true} />
					)}

					<GradeDistribution
						data={metrics.outcomes}
						course={course}
						isMobile={true}
					/>

					{selectedReviewQtr !== undefined ? (
						// <div className="p-4 shadow-xl shadow-blue-500/40 rounded-md flex justify-between bg-white dark:bg-zinc-800">

						<div className="flex-1 space-y-2">
							<div className="flex space-x-3 items-center">
								<h1 className="font-bold text-2xl pb-2">
									Reviews
								</h1>
								{metrics !== undefined &&
									metrics.difficulty !== undefined && (
										<CourseDifficulty
											difficulty={metrics.difficulty}
											isMobile={true}
										/>
									)}
								{metrics !== undefined && (
									<ReviewsSentimentBar
										sentiment={metrics.sentiment}
									/>
								)}
							</div>

							<div className="overflow-x-auto whitespace-nowrap">
								<ul className="flex flex-nowrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
									{course !== undefined &&
										course.pastReviews !== undefined &&
										course.pastReviews.length > 0 &&
										course.pastReviews.map(
											(item, index) => (
												<li
													key={index}
													className="me-2 cursor-pointer"
												>
													<a
														className={`inline-block px-4 py-3 rounded-lg ${selectedReviewQtr === item.flow ? "bg-blue-600 text-white" : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"}`}
														aria-current={
															selectedReviewQtr ===
															item.flow
																? "page"
																: undefined
														}
														onClick={() => {
															setSelectedReviewQtr(
																item.flow
															);
														}}
													>
														<div className="text-xs">
															{convertFlowToHumanReadable(
																item.flow
															)}
														</div>
														<div className="text-base">
															{item.name}
														</div>
													</a>
												</li>
											)
										)}
								</ul>
							</div>

							{/* Search for reviews */}
							<div className="relative hidden md:flex items-center">
								<div className="relative flex-1">
									<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
										<svg
											className="w-4 h-4 text-gray-500 dark:text-gray-400"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
											/>
										</svg>
										<span className="sr-only">
											Search icon
										</span>
									</div>
									<div>
										<input
											type="text"
											id="review-search-navbar"
											data-dropdown-toggle="reviewDropdownSearch"
											className="block w-full p-2 pl-10 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 
                                          dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
											placeholder={`Search reviews in ${convertFlowToHumanReadable(selectedReviewQtr)}`}
											onChange={reviewSearchHandleChange}
											onKeyDown={reviewHandleKeyDown}
										/>
									</div>
								</div>
								<span className="ml-2 text-gray-500 text-sm">
									{reviewsFiltered &&
										` ${reviewsFiltered.length} reviews`}
								</span>
							</div>

							<ReviewColumn
								qtrToReviews={qtrToReviews}
								selectedReviewQtr={selectedReviewQtr}
								isMobile={true}
								reviewFilterText={reviewFilterText}
								reviewsFiltered={reviewsFiltered}
							/>
						</div>
					) : // </div>
					null}
				</div>
			) : null}
			{/* : null } */}
		</div>
	) : (
		<div className="font-sans text-left space-y-1.5">
			<div className="p-4 shadow-xl shadow-blue-500/40 rounded-md dark:bg-zinc-800">
				<div className="flex flex-row justify-center">
					<h1 className="text-xl font-bold">No results were found</h1>
				</div>
			</div>
		</div>
	);
}
export default CourseMobile;
