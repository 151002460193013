import CourseMeta from '../data/CourseMeta';
import Metrics from '../data/Metrics';
import Review from '../data/Review';

const courseConverter = {
    toFirestore: (course) => {
        return {};
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        
        return new CourseMeta(
            data.career,
            data.code,
            data.courseCode,
            data.department,
            data.description,
            data.finalExam,
            data.gers,
            data.grading,
            data.id,
            data.instructors,
            data.instructorIDs,
            data.maxTimesRepeated,
            data.medianGrade,
            data.medianHours,
            data.meanHours,
            data.percentAs,
            data.qtrs,
            data.subject,
            data.title,
            data.unitsMin,
            data.unitsMax,
            data.pastReviews,
            data.syllabus,
            data.prereqs
        )
    }
};

const metricsConverter = {
    toFirestore: (metrics) => {
        return {};
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Metrics(data.hours, data.outcomes, data.years, data.sentiment, data.difficulty);
    }
};

const reviewsConverter = {
    toFirestore: (reviews) => {
        return {};
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Review(data.val, data.termID);
    }
};

export { courseConverter, metricsConverter, reviewsConverter };