import CourseMeta from "../data/CourseMeta";
import Metrics from "../data/Metrics";
// import Review from "../data/Review";
import Reviews from "../data/Reviews";
import {
	APP_VERSION,
	METRICS_VERSION,
	REVIEW_VERSION,
	LATEST_YEAR_IN_DB,
} from "../constants/Constants";

import { doc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase";

function saveCourse(
	currentFlow,
	courseCode,
	course,
	sectionCount,
	userID,
	units
) {
	if (course === null || course === undefined) return;
	const restrictedYear = Math.min(currentFlow[0], LATEST_YEAR_IN_DB);
	const key = `CourseMeta_${restrictedYear}_${courseCode}`;
	const value = JSON.stringify(course);
	localStorage.setItem(key, value);

	// upload to firebase
	const planRef = doc(
		db,
		"Planned",
		userID,
		`${currentFlow[0]}-${currentFlow[1]}`,
		courseCode
	);

	// const units = (course.units && course.units.length > 0) ? course.units[course.units.length-1] : 0;
	const medianHours = course.medianHours ? course.medianHours : units * 3;
	const meanHours = course.meanHours ? course.meanHours : units * 3;
	const title = course.title;
	const gers = course.gers;

	setDoc(planRef, {
		show: Array(sectionCount).fill(true),
		units: units,
		medianHours: medianHours,
		meanHours: meanHours,
		title: title,
		gers: gers,
	});
}

function getCourse(currentFlow, courseCode) {
	// const key = `CourseMeta_${currentFlow[0]}_${currentFlow[1]}_${courseCode}`;
	const restrictedYear = Math.min(currentFlow[0], LATEST_YEAR_IN_DB);
	const key = `CourseMeta_${restrictedYear}_${courseCode}`;
	const dataString = localStorage.getItem(key);

	// if (courseString) {
	if (
		dataString !== null &&
		dataString !== undefined &&
		dataString !== "" &&
		dataString !== "undefined"
	) {
		let course;

		try {
			course = JSON.parse(dataString);
		} catch (error) {
			return null;
		}

		if (course.version === APP_VERSION) {
			return CourseMeta.fromJSON(course);
		}
	}
	return null;
}

function saveReviews(courseCode, reviews) {
	if (reviews === null || reviews === undefined) return;
	const key = `CourseReviews_${courseCode}`;
	const value = JSON.stringify(reviews);

	const jsonToSave = {
		version: REVIEW_VERSION,
		reviews: reviews,
	};
	const jsonToSaveString = JSON.stringify(jsonToSave);

	localStorage.setItem(key, jsonToSaveString);
}

function getReviews(courseCode) {
	const key = `CourseReviews_${courseCode}`;
	const dataString = localStorage.getItem(key);

	if (
		dataString !== null &&
		dataString !== undefined &&
		dataString !== "" &&
		dataString !== "undefined"
	) {
		let reviews;

		try {
			reviews = JSON.parse(dataString);
		} catch (error) {
			return null;
		}

		if (reviews.version === REVIEW_VERSION) {
			// return reviews.reviews.map((review) => Review.fromJSON(review));
			return Reviews.fromJSON(reviews);
		}
	}
	return null;
}

function getMetrics(courseCode) {
	const key = `CourseMetrics_${courseCode}`;
	const dataString = localStorage.getItem(key);

	if (
		dataString !== null &&
		dataString !== undefined &&
		dataString !== "" &&
		dataString !== "undefined"
	) {
		let metrics;

		try {
			metrics = JSON.parse(dataString);
		} catch (error) {
			return null;
		}

		if (metrics.version === METRICS_VERSION) {
			return Metrics.fromJSON(metrics);
		}
	}
	return null;
}

function saveMetrics(courseCode, metrics) {
	if (metrics === null || metrics === undefined) return;

	const key = `CourseMetrics_${courseCode}`;
	const value = JSON.stringify(metrics);
	localStorage.setItem(key, value);
}

export {
	saveCourse,
	getCourse,
	saveReviews,
	getReviews,
	getMetrics,
	saveMetrics,
};
