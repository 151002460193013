import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import CatalogNavbar from "../components/Navbars/CatalogNavbar"
import { DarkModeProvider, useDarkMode } from "../helpers/DarkModeContext"
import { useIsMobile } from "../layouts/Mobile"
import CatalogDepartments from "../catalog/CatalogDepartments"
import CatalogSubjectList from "../catalog/CatalogSubjectList"

import { IsMobile } from "../helpers/Contexts"

function Catalog() {
  // Use useSearchParams to get the query parameters
  const [searchParams] = useSearchParams()
  const subject = searchParams.get("subject")
  const query = searchParams.get("query")

  var [isMobile, setIsMobile] = useState(useIsMobile())
  const mobileClass = isMobile ? "mobile" : "desktop"
  const isDarkMode = useState(false) //useDarkMode();

  return (
    <DarkModeProvider value={{ isDarkMode }}>
      <IsMobile.Provider value={{ isMobile, setIsMobile }}>
        <div className="flex flex-col overflow-hidden">
          <CatalogNavbar />
          <div
            className={
              "flex dark:bg-zinc-900 dark:text-white items-center justify-center pt-14 pb-5 " +
              mobileClass
            }
          >
            {subject === null && query === null ? <CatalogDepartments /> : <CatalogSubjectList />}
          </div>
        </div>
      </IsMobile.Provider>
    </DarkModeProvider>
  )
}

export default Catalog
