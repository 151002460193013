import React from 'react'

function ReviewColumn(props) {
    const { qtrToReviews, selectedReviewQtr, firstHalf, reviewFilterText, reviewsFiltered, isMobile } = props;


    // filtered
    if (reviewFilterText !== "") {
        return (
            <div>
                <div className="flex flex-wrap">
                    {
                        reviewsFiltered !== undefined &&
                        reviewsFiltered
                        .slice(
                            isMobile || firstHalf ? 0 : Math.floor(reviewsFiltered.length / 2) + 1,
                            !isMobile && firstHalf ? Math.floor(reviewsFiltered.length / 2) + 1 : undefined
                        )
                        .map((review, index) => (
                            <ReviewBlock review={review} key={index}/>
                        ))
                    }  
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="flex flex-wrap">
                {
                    selectedReviewQtr !== undefined && 
                    qtrToReviews !== undefined &&
                    qtrToReviews.reviews !== undefined && 
                    qtrToReviews.reviews[selectedReviewQtr] !== undefined ? 
                    (
                    qtrToReviews.reviews[selectedReviewQtr]
                    .slice(
                        isMobile || firstHalf ? 0 : Math.floor(qtrToReviews.reviews[selectedReviewQtr].length / 2) + 1,
                        !isMobile && firstHalf ? Math.floor(qtrToReviews.reviews[selectedReviewQtr].length / 2) + 1 : undefined
                    )
                    .map((review, index) => (
                        <ReviewBlock review={review} key={index}/>
                    ))
                )
                : null
                }
            </div>
        </div>
    )
}

function ReviewBlock(props) {
    const { review } = props;

    return (
        <p className="w-full m-1 p-2 border border-1 shadow-md rounded-lg max-h-56 overflow-auto">
            {review}
        </p>
    )
}

export default ReviewColumn;