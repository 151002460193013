import React from 'react'

function Welcome() {
  return (
    <div className="font-sans text-left space-y-1.5">
        <div className="p-4 rounded-md">
            <h1 className="text-center mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">Welcome to <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#1499f7] via-[#8A2BE2] to-[#FF69B4]">OnCourse</span></h1>
            <p className="pb-6 text-center mb-4 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">OnCourse is a Stanford course planning application</p>
            <div className="mx-20 bg-gradient-to-b from-[#1499f7] via-[#8A2BE2] to-[#FF69B4] rounded-xl p-0.5">
              <div className="dark:bg-gray-900 bg-white p-5 rounded-xl space-y-10">
              <div className="pl-4 flex flex-row flex-wrap">
                  <h1 className="w-1/2 pt-4 text-left text-3xl font-bold text-gray-900 dark:text-white">
                      <span className="text-[#1499f7]">{"1) "}</span> Explore
                  </h1>
                  <p className="text-left pt-2 pl-9 text-xl font-normal text-gray-500 dark:text-gray-400">Dive into OnCourse's extensive Stanford course database. With reviews, crucial course data, and other insights at your fingertips, pin courses of interest and watch your schedule take shape.</p>
                </div>
                

                <div className="pl-4 flex flex-row flex-wrap">
                  <h1 className="w-1/2 text-left text-3xl font-bold text-gray-900 dark:text-white">
                    <span className="text-[#8A2BE2]">{"2) "}</span> 
                    Collaborate</h1>
                    <p className="text-left pt-2 pl-9 text-xl font-normal text-gray-500 dark:text-gray-400">Share your course calendar effortlessly with our "Share with Friends" feature. When you merge calendars with peers, it's easy to spot overlaps and find available times.</p>
                </div>
                

                <div className="pl-4 flex flex-row flex-wrap">
                <h1 className="w-1/2 text-left text-3xl font-bold text-gray-900 dark:text-white">
                  <span className="text-[#FF69B4]">{"3) "}</span> 
                  Plan Long-Term</h1>
                  <p className="text-left pt-2 pb-4 pl-9 text-xl font-normal text-gray-500 dark:text-gray-400">
                    With our 4-year planner tool, map out your entire academic journey at Stanford, confidently meeting major requirements and graduation goals.
                  </p>
                </div>
              </div>
            </div>
          
            {/* <a href="https://storagesch.com/17122673509986" target="_blank" rel="noopener noreferrer">
              <div className="group w-7/12 mx-auto">
              <p className="text-center text-lg font-normal text-gray-500 group-hover:text-black lg:text-2xl dark:text-gray-400 dark:group-hover:text-white">
                Get <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#1499f7] via-[#8A2BE2] to-[#FF69B4]">
                  $25</span> off your summer storage with our new partner,
              </p>
              <p className="pb-6 text-center mb-4 text-lg font-normal text-gray-500 group-hover:text-black lg:text-2xl dark:text-gray-400 dark:group-hover:text-white">
              Storage Scholars – student storage made easy!
              </p>
              </div>
            </a> */}

            {/* <div className="">
              <iframe className="mx-auto" src="https://player.vimeo.com/video/728573268?h=526d8856a8&autoplay=1&loop=1" width="900" height="474" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
            <a href="https://storagesch.com/17122673509986" target="_blank" rel="noopener noreferrer">
              <p className="pt-10 mx-auto text-center text-3xl text-gray-500 dark:text-gray-400 hover:text-black dark:hover:text-white w-7/12">
                Click <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#1499f7] via-[#8A2BE2] to-[#FF69B4]">here</span> to save $25 on summer storage!
              </p>
            </a> */}
          
        
            <a 
                className="rounded-t-lg font-semibold z-10 fixed px-4 py-2 text-white rounded-none bg-gradient-to-b from-[#1499f7] via-[#8A2BE2] to-[#FF69B4] feedback"
                target="blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe6xxs_kKPu1Iej5ahUY0DmL1p6i-yWqVsVCUrs8tv7zVJ9dg/viewform?usp=sf_link">
                Feedback
            </a>
        </div>
        
    </div>
  )
}

export default Welcome