import React from 'react'
import ReactEcharts from "echarts-for-react"; 
import { useDarkMode } from '../../helpers/DarkModeContext';
import { colorToRgb } from '../Colors';

const yearMappings = ["Freshman", "Sophomore", "Junior", "Senior", "5+ Year Undergrad", "Coterm", "Professional Degree", "Master's", "PhD/Doctoral", "SCPD/Nonmatriculated", "Other"]
// assumes data is percentage
function createPieData(data) {
    var elems = [];
    var totalAdded = 0;

    for (let i = 0; i < data.length; i++) {
        if (data[i] < 0.5) { continue; }
        totalAdded += data[i];
        elems.push(
            {
                value: data[i],
                name: yearMappings[i]
            }
        );
    }

    elems.push(
        {
            value: totalAdded,
            itemStyle: {
              // stop the chart from rendering this piece
              color: 'none',
              decal: {
                symbol: 'none'
              }
            },
            label: {
              show: false
            }
          }
    );


    return elems;
}

function YearChart(props) {
    const {data, isMobile} = props;
    const isDarkMode = useDarkMode();

    if (data === undefined) {
      return (<div></div>);
    }

    const total = data.reduce((partialSum, a) => partialSum + a, 0);
    const dataNormalised = data.map((x) => x / total * 100);

    // const colors = ["#4A90E2","#32e65c","#4ECDC4","#9B59B6","#F39C12","#F06292","#8D8B6B","#F1C40F","#95A5A6"]
    // const colors = ["#6DA9F3","#B272D1","#FF8C8C","#70E0D6","#F5AD42","#F784B3","#44D02A","#FF3C3C","#F4D641","#BA55D3"]
    // const colors = ["#3498DB", "#E74C3C", "#2ECC71", "#F39C12", "#9B59B6", "#1ABC9C","#E84393", "#F1C40F", "#A0A0A0", "#71E1FF"];
    // const colors = ["blue", "purple", "green", "orange", "lime", "violet", "rose", "yellow"];
    // ["blue", "purple", "green", "orange", "lime", "rose", "yellow"];


    // const colors = ["#60a5fa", "#c084fc", "#4ade80", "#fb923c", "#a3e635", "#fb7185", "#facc15", "#6b7280", "#9d174d", "#9ca3af"]

    // const colors = ['#7fb7fb', '#cc9cfc', '#6ee499', '#fba762', '#b5eb5d', '#fb8d9d', '#fbd643', '#888e99', '#b04570', '#8de7ff']


    const inputColors = ["blue", "purple", "green", "orange", "lime", "rose", "yellow", "gray", "cyan"];
    const colors = inputColors.map((color) => {
      return colorToRgb(color, 400, 0.8);
    });
    
    const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '5%',
          left: 'center',
          // doesn't perfectly work with our tricks, disable it
          selectedMode: false,
          textStyle:{
            color: isDarkMode ? 'white' : 'black'
         },
        },
        color: colors,   // Set the color attribute for the pie chart
        series: [
          {
            name: 'Student Breakdown',
            type: 'pie',
            radius: ['68%', '100%'],
            center: ['50%', '80%'],
            startAngle: 180, // adjust the start angle
            data: createPieData(dataNormalised),
            label: {
              color: isDarkMode ? 'white' : 'black',  // change the color of labels to red
              show: true,
              formatter(param) {
                // correct the percentage
                return param.name + ' (' + (Math.round(param.percent * 2, 2)) + '%)';
              }
            }
          }
        ],
        tooltip: {
          trigger: 'item',
          formatter: function(param) {
            // format your tooltip here
            return param.name + ' (' + (Math.round(param.percent * 2, 2)) + '%)';
          },
        },    
      };
    
    return (
        <div className={`h-96 ${isMobile ? "text-center": ""}`}>
        <h1 className="font-extrabold text-xl">Student Breakdown <span className='text-[#5899F7] font-semibold'>({total} students)</span></h1>
            <ReactEcharts option={option} style={{
                  height: '100%',
                  width: '100%',
                }}/>
        </div>
    )
}

export default YearChart