import React, { useState, useRef, useEffect, createContext } from "react";
import PlannerNavbar from "../components/Navbars/PlannerNavbar";
import { useParams } from "react-router-dom";
import { useIsMobile } from "../layouts/Mobile";
import { db } from "../config/firebase";
import { getDoc, doc, getDocs } from "firebase/firestore";
import { query, orderBy, limit, onSnapshot } from "firebase/firestore";
import { collection, setDoc } from "firebase/firestore";
import { Section, CourseTime } from "../data/Section";
import { DarkModeProvider, useDarkMode } from "../helpers/DarkModeContext";
import SectionEditing from "../components/SectionEditing";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireBaseAuth } from "../config/firebase";
import { QUARTER_SHORTENED_LIST } from "../constants/Constants.js";
// import { sectionTimesConverter } from "../adapters/SectionAdapters";
import Calendar from "../components/Calendar.js";
import CourseFlow from "../components/CourseFlow.js";
import Course from "../components/Course.js";
import Welcome from "../components/Welcome.js";
import {
	PinnedClasses,
	CurrentFlow,
	CourseSections,
	CourseCode,
	IsMobile,
} from "../helpers/Contexts";
import { sectionConverter } from "../adapters/SectionAdapters";
import { COURSES_PATH } from "../constants/Constants.js";

function Share() {
	let { ID } = useParams();

	var [pinnedClasses, setPinnedClasses] = useState({});
	var [courseSections, setCourseSections] = useState({});
	var [currentFlow, setCurrentFlow] = useState([0, 0]);
	var [courseCode, setCourseCode] = useState(undefined);
	var [name, setName] = useState(undefined);

	var [isMobile, setIsMobile] = useState(useIsMobile());
	const mobileClass = isMobile ? "mobile" : "desktop";
	var [mobileCourseOpen, setMobileCourseOpen] = useState(false);
	const [user, loading, error] = useAuthState(fireBaseAuth);
	var [showingUserSchedule, setShowingUserSchedule] = useState(false);

	// Ref to track the initial mount
	const initialMount = useRef(true);

	useEffect(() => {
		// Skip effect during initial mount
		if (initialMount.current) {
			initialMount.current = false;
			return;
		}

		if (isMobile && !mobileCourseOpen) {
			setMobileCourseOpen(true);
		}
	}, [courseCode, isMobile]);

	useEffect(() => {
		const fetchData = async () => {
			// fetch pinned Clases
			setPinnedClasses({});
			setCourseSections({});

			const docRef = doc(db, "Share", ID);
			const plannedSnapshot = await getDoc(docRef);

			if (plannedSnapshot.exists()) {
				const data = plannedSnapshot.data();
				const name = data.name;
				const flow = data.flow;

				var pinnedClassesDict = {};
				var courseSectionsDict = {};

				var sectionTimesDict = {};
				let classes = data.classes;
				if (classes) {
					Object.keys(classes).forEach((courseCode) => {
						const data = classes[courseCode];

						const title = data.title;
						const show = data.show;

						const sections = data.sections;
						var courseSec = [];

						sections.forEach((section) => {
							const item = new Section(
								courseCode,
								section.sectionNumber,
								section.classID,
								section.component,
								0,
								"",
								undefined
							);
							item.description = section.description;

							for (const schedule of section.schedules) {
								const adjusted = new CourseTime(
									schedule.day,
									schedule.startHour,
									schedule.startRemainder,
									schedule.endHour,
									schedule.endRemainder,
									courseCode
								);
								item.schedules.push(adjusted);
							}

							courseSec.push(item);
						});

						sectionTimesDict[courseCode] = courseSec;

						pinnedClassesDict[courseCode] = {
							title: title,
							show: show,
							units: 0,
							hours: 0,
							currentUserHidden: false,
						};

						courseSectionsDict[courseCode] = courseSec;
					});

					setPinnedClasses(pinnedClassesDict);
					setCurrentFlow(flow);
					setCourseSections(courseSectionsDict);
					setName(name);
				}
			}
		};

		fetchData();
	}, []); // Run this effect only once, when the component mounts.

	// Fetch Current user

	const addClass = (courseCode, data, flowWhenWeStarted) => {
		// Check if pinnedClasses contains
		// Checks whether the person pinning the class and the shared user have the same class
		if (courseCode in pinnedClasses) {
			// maybe show an over lap here??
			// pinnedClasses[courseCode]["duplicateF"] = true;
			return;
		}

		const docRef = doc(
			db,
			COURSES_PATH,
			`${currentFlow[0]}`,
			`${courseCode}`,
			QUARTER_SHORTENED_LIST[currentFlow[1]]
		).withConverter(sectionConverter(courseCode, flowWhenWeStarted));

		getDoc(docRef)
			.then((doc) => {
				if (doc.exists()) {
					const sections = doc.data();
					for (var i = 0; i < sections.length; i++) {
						sections[i].currentUserSection = true;
					}

					setCourseSections((prevState) => {
						// 1. Filter prevState to keep items with the correct flow.
						// const filteredState = Object.entries(prevState)
						//     .reduce((acc, [key, value]) => {
						//         if (value[0] !== undefined && value[0].flow === flowWhenWeStarted) {
						//             acc[key] = value;
						//         }
						//         return acc;
						//     }, {});

						// 2. Return the updated state with the specific course.
						return {
							...prevState,
							[courseCode]: sections,
						};
					});

					setPinnedClasses((prevState) => {
						// 1. Filter prevState to keep items with the correct flow.
						// const filteredState = Object.entries(prevState)
						//     .reduce((acc, [key, value]) => {
						//         if (value.flow === flowWhenWeStarted) {
						//             acc[key] = value;
						//         }
						//         return acc;
						//     }, {});

						// 2. Return the updated state with the specific course.
						return {
							...prevState,
							[courseCode]: {
								show: data.show
									? data.show
									: Array(sections.length).fill(true),
								hours: data.medianHours ? data.medianHours : 0,
								units: data.units ? data.units : 0,
								title: data.title,
								gers: data.gers,
								flow: flowWhenWeStarted,
								currentUserHidden: true,
							},
						};
					});
				} else {
					console.log("No such document!");
				}
			})
			.catch((error) => {
				console.error("Error getting document:", error);
			});

		// const docRef = collection(db, "Courses", `${currentFlow[0]}`, `${courseCode}`, "sections", QUARTER_SHORTENED_LIST[currentFlow[1]])
		//                 .withConverter(sectionTimesConverter(courseCode)); //, shows));
		// const q = query(docRef, limit(5));

		// onSnapshot(q, (querySnapshot) => {
		//         var sections = [];
		//         var lectureFound = false;
		//         querySnapshot.forEach((doc) => {
		//             const section = doc.data();
		//             // section.flow = flowWhenWeStarted;
		//             section.currentUserSection = true;
		//             if (section.component === "LEC") {
		//                 lectureFound = true;
		//                 sections.push(section);
		//             } else if (!lectureFound) {
		//                 sections.push(section);
		//             }
		//         });

		//         setCourseSections(prevState => {
		//             // 1. Filter prevState to keep items with the correct flow.
		//             // const filteredState = Object.entries(prevState)
		//             //     .reduce((acc, [key, value]) => {
		//             //         if (value[0] !== undefined && value[0].flow === flowWhenWeStarted) {
		//             //             acc[key] = value;
		//             //         }
		//             //         return acc;
		//             //     }, {});

		//             // 2. Return the updated state with the specific course.
		//             return {
		//                 ...prevState,
		//                 [courseCode]: sections
		//             };
		//         });

		//         setPinnedClasses(prevState => {
		//             // 1. Filter prevState to keep items with the correct flow.
		//             // const filteredState = Object.entries(prevState)
		//             //     .reduce((acc, [key, value]) => {
		//             //         if (value.flow === flowWhenWeStarted) {
		//             //             acc[key] = value;
		//             //         }
		//             //         return acc;
		//             //     }, {});

		//             // 2. Return the updated state with the specific course.
		//             return {
		//                 ...prevState,
		//                 [courseCode]: {
		//                     "show": (data.show) ? data.show : Array(sections.length).fill(true),
		//                     "hours": (data.medianHours) ? data.medianHours : 0,
		//                     "units": (data.units) ? data.units : 0,
		//                     "title": data.title,
		//                     "gers": data.gers,
		//                     "flow": flowWhenWeStarted,
		//                     "currentUserHidden": true
		//                 }
		//             };
		//         });
		// });
	};

	useEffect(() => {
		const fetchData = async () => {
			const flowWhenWeStarted = `${currentFlow[0]}-${currentFlow[1]}`;
			const plannedSnapshot = await getDocs(
				collection(db, "Planned", user.uid, flowWhenWeStarted)
			);
			plannedSnapshot.forEach((doc) => {
				const data = doc.data();
				addClass(doc.id, data, flowWhenWeStarted);
			});
		};

		if (currentFlow[0] !== 0) {
			fetchData();
		}
	}, [currentFlow]);

	const ShowUserScheduleButton = (
		<label className="relative inline-flex items-center cursor-pointer mt-2">
			<input
				type="checkbox"
				value=""
				className="sr-only peer"
				checked={showingUserSchedule}
				onChange={() => {
					setShowingUserSchedule(!showingUserSchedule);
				}}
			/>
			<div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
			<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
				Show my classes
			</span>
		</label>
	);

	return (
		<DarkModeProvider>
			<PinnedClasses.Provider value={{ pinnedClasses, setPinnedClasses }}>
				<CurrentFlow.Provider value={{ currentFlow, setCurrentFlow }}>
					<CourseSections.Provider
						value={{ courseSections, setCourseSections }}
					>
						<CourseCode.Provider
							value={{ courseCode, setCourseCode }}
						>
							<IsMobile.Provider
								value={{ isMobile, setIsMobile }}
							>
								<PlannerNavbar />
								<div
									className={
										"flex h-screen dark:bg-zinc-900 dark:text-white " +
										mobileClass
									}
								>
									{/* key={isDarkMode ? 'dark' : 'light'}> */}
									<div
										className={
											isMobile ? "w-screen" : "w-1/3"
										}
									>
										<Calendar
											showingUserSchedule={
												showingUserSchedule
											}
										/>
										<div
											style={
												!isMobile
													? { paddingLeft: "10%" }
													: null
											}
										>
											{/* <p className="my-2"></p> */}
											<CourseFlow
												disabled={true}
												name={name}
											/>
											{ShowUserScheduleButton}
											{/* <PinnedCourses/> */}
											<SectionEditing shareMode={true} />
										</div>
									</div>

									{!isMobile ? (
										<div className="px-4 py-2 w-2/3">
											{courseCode !== undefined ? (
												<Course />
											) : (
												<Welcome />
											)}
										</div>
									) : mobileCourseOpen &&
									  courseCode !== undefined ? (
										<div
											className={`fixed top-[5%] left-[0%] h-[95%] w-[100%] z-20`}
										>
											<button
												type="button"
												className="w-2/3 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br 
                                                focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 
                                                dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg px-5 py-2.5 text-center mr-2 mb-2
                                                font-extrabold text-xl"
												onClick={() => {
													setMobileCourseOpen(false);
												}}
											>
												Close Course
											</button>

											{/* Scrollable content */}
											<div className="overflow-y-auto h-full">
												<Course />
											</div>
										</div>
									) : null}
								</div>
							</IsMobile.Provider>
						</CourseCode.Provider>
					</CourseSections.Provider>
				</CurrentFlow.Provider>
			</PinnedClasses.Provider>
		</DarkModeProvider>
	);
}

export default Share;
